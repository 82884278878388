// Helper Functions
import ColorsHelper from "../../../shared/ColorHelper";
import FontsHelper from "../../../shared/FontsHelper";
import CommanStyles from "../../../shared/CommonStyle";

const RWMStyles = {
  container: {
    width: "100%",
    margin: "auto",
    maxWidth: "30rem",
    position: "relative",
    height: "100dvh",
  },
  cpContainer: {
    overflow: "auto",
    height: "calc(100% - 6rem)",
    backgroundColor: ColorsHelper.grey30,
  },
  spinCon: {
    height: "100%",
    width: "100%",
    ...CommanStyles.centerIt,
  },
  innerSpinCon: {
    height: "calc(100% - 13rem)",
    width: "100%",
    ...CommanStyles.centerIt,
  },
  menuCon: {
    borderRadius: "0rem",
    ...CommanStyles.flexEndIt,
    right: "0",
    position: "sticky",
    zIndex: 1,
    bottom: 0
  },
  menuBox: {
    height: "3rem",
    overflowX: "auto",
    ...CommanStyles.dflex,
    alignItems: "center",
    width: "calc(100% - 5rem)",
  },
  catTextBox: {
    p: "0.2rem 0.5rem",
    mx: "0.5rem",
    borderRadius: "0.5rem",
    whiteSpace: "nowrap",
  },
  catText: {
    ...FontsHelper.font400,
    fontSize: "0.8rem",
    color: "#403f3f",
  },
  catTextActive: {
    ...FontsHelper.font400,
    fontSize: "0.8rem",
    color: ColorsHelper.blackClr,
  },
  catTextBoxActive: {
    backgroundColor: "#e2dede !important",
  },
  listMainBox: {
    backgroundColor: ColorsHelper.grey30,
    pt: "0.2rem",
  },
  collapseBox: {
    boxShadow: "none",
    margin: "0",
    // mb: "0.8rem !important",
  },
  collapseIcon: {
    width: "1rem",
    height: "1rem",
  },
  catTitleBx: {
    px: "0.5rem",
    ".MuiAccordionSummary-content": {
      width: "90%",
    },
    "& .Mui-expanded": {
      margin: "12px 0px !important",
    },
    minHeight: "52px !important",
  },
  catTitle: {
    ...FontsHelper.font600,
    fontSize: "1.1rem",
    color: ColorsHelper.blackClr,
    wordBreak: "break-word",
  },
  productListBox: {
    // pr: "0.3rem",
    ...CommanStyles.flexColunmContainer,
    width: "100%",
  },
  prodGridCon: {
    borderBottom: "1px dashed " + ColorsHelper.grey1,
    py: "1rem !important",
    pl: '0.3rem'

  },
  ratingCon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    mt: "0.5rem",
  },
  ratingBox: {
    borderRadius: "0.25rem",
    border: "1px solid #faaf0096",
    backgroundColor: "#faaf001f",
    fontSize: "0.8rem",
    p: "0.1rem",
  },
  prodImg: {
    borderRadius: "1.5rem",
    aspectRatio: "1/1",
    width: "100%",
    maxWidth: "10rem",    
  },
  viewProdImg: {
    borderRadius: "1.5rem",
    aspectRatio: "13/9",
    width: "100%",
  },
  smViewProdImg: {
    borderRadius: "0.6rem",
    aspectRatio: "1/1",
    width: "3.5rem",
  },
  prodPrice: {
    ...FontsHelper.font500,
    fontSize: "0.77rem",
    py: "0.2rem",
    color: ColorsHelper.blackClr,
  },
  prodTitle: {
    ...FontsHelper.font500,
    fontSize: "0.8125rem",
    color: ColorsHelper.blackClr,
  },
  prodDesc: {
    ...FontsHelper.font400,
    fontSize: "0.75rem",
    color: ColorsHelper.grey31,
    lineHeight: 1.1,
  },
  ratingText: {
    ...FontsHelper.font400,
    color: "black",
    fontSize: "0.7rem",
    lineHeight: 1.1,
    ml: "0.3rem",
  },
  prodDetailsBox: {
    width: "calc(100% - 11rem)",
  },
  imgBox: {
    ...CommanStyles.flexColunmContainer,
    alignItems: "center",
    width: "11rem",
    // pl: "1rem",
    position: "relative",
    pb: "2rem",
  },
  addBtnCon: {
    position: "absolute",
    top: "8.5rem",
  },
  addBtn: {
    ...FontsHelper.font600,
    fontSize: "1rem",
    minWidth: { xs: "7.5rem", md: "7.5rem" },
    border: `1px solid ${ColorsHelper.mainColor} !important`,
    borderRadius: "0.625rem",
    ...CommanStyles.centerIt,
    color: ColorsHelper.mainColor,
    backgroundColor: "#faeded",
    "&:hover": {
      backgroundColor: "#faeded",
    },
    position: "relative",
    height: "2.5rem",
    width: "100%",
    svg: {
      fontSize: "1rem",
      marginLeft: "0.4rem",
      position: "absolute",
      top: "0.25rem",
      right: "0.25rem",
      color: ColorsHelper.mainColor,
    },
  },
  addBtnWCount: {
    ...FontsHelper.font500,
    padding: "0.25rem",
    fontSize: "1rem",
    minWidth: "7.5rem",
    height: "2.5rem",
    borderRadius: "0.625rem",
    ...CommanStyles.centerIt,
    color: ColorsHelper.whiteClr,
    backgroundColor: ColorsHelper.mainColor,
    "&:hover": {
      backgroundColor: ColorsHelper.mainColor,
    },
    position: "relative",
    width: "100%",
    ...CommanStyles.flexRowsBetween,
    svg: {
      fontSize: "1rem",
      color: "white",
    },
  },
  custmText: {
    ...FontsHelper.font400,
    fontSize: "0.7rem",
    color: ColorsHelper.grey32,
    lineHeight: 1.1,
    textAlign: "center",
    mt: "0.50rem",
  },
  selItemCon: {
    mt:1,
    ...CommanStyles.centerItColumn,
    backgroundColor: ColorsHelper.mainColor,
    "&:hover": {
      backgroundColor: ColorsHelper.mainColor,
    },
    height: "3.5rem",
    ...FontsHelper.font500,
    color: ColorsHelper.whiteClr,
    textTransform: "none",
    width: "100%",
    borderRadius: "0rem",
    svg: {
      fontSize: "0.938rem",
      marginLeft: "0.25rem",
    },
    div: {
      ...CommanStyles.centerIt,
    },
    "& .itemsTxt": {
      fontSize: "0.938rem",
    },
    "& .itemCountTxt": {
      fontSize: "0.938rem",
      marginRight: "0.25rem",
      width: "2rem",
      display: "inline-block",
      textAlign: "end",
    },
    "& .offerTxtC": {
      marginTop: "-0.5rem",
    },
    "& .congTxt": {
      ...FontsHelper.font300,
      fontSize: "0.8rem",
      color: ColorsHelper.whiteClr,
      display: "inline-block",
    },
    position: "sticky",
    bottom: 0,
    zIndex: 1,
  },
  menuBtn: {
    ...CommanStyles.centerIt,
    backgroundColor: ColorsHelper.blackClr,
    "&:hover": {
      backgroundColor: ColorsHelper.blackClr,
    },
    height: "2.1rem",
    ...FontsHelper.font400,
    fontSize: "0.9rem",
    color: ColorsHelper.whiteClr,
    textTransform: "none",
    borderRadius: "0.5rem",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    minWidth: "5rem",
    width: "5rem",
    svg: {
      fontSize: "1rem",
      marginRight: "0.25rem",
    },
  },
  menuPopup: {
    mt: 5,
    div: {
      borderRadius: "1.5rem",
    },
    width: "18rem",
    maxHeight: "30rem",
    "& .MuiBackdrop-root": {
      background: "rgba(0,0,0,0.8)",
      borderRadius: "0",
    },
    "& .MuiPaper-root": {
      minWidth: "20rem",
    },
  },
  menuItem: {
    ...FontsHelper.font400,
    fontSize: "1rem",
    color: "#403f3f",
    py: "0rem",
    height: "2.5rem",
    minHeight: "2.5rem",
    ...CommanStyles.flexRowsBetween,
  },
  menuNameT: {
    maxWidth: "12rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "1rem",
  },
  btnCon: {
    width: "100%",
    background: ColorsHelper.whiteClr,
    height: "4rem",
  },
  btnBox: {
    ...CommanStyles.centerIt,
    p: "0.5rem",
    width: "100%",
  },
  clearBtn: {
    ...FontsHelper.font500,
    fontSize: "1rem",
    borderRadius: "0.625rem",
    ...CommanStyles.centerIt,
    color: ColorsHelper.mainColor,
    width: "100%",
    textTransform: "capitalize",
    maxWidth: "9rem",
  },
  applyBtn: {
    ...FontsHelper.font500,
    fontSize: "1rem",
    borderRadius: "0.5rem",
    ...CommanStyles.centerIt,
    color: ColorsHelper.whiteClr,
    backgroundColor: ColorsHelper.mainColor,
    "&:hover": {
      backgroundColor: ColorsHelper.mainColor,
    },
    height: "3rem",
    width: "100%",
    textTransform: "capitalize",
  },
  contentCon: {
    // overflow: "auto",
    p: "0.625rem",
    pb: "0rem",
    // mb: "9rem",
    // border: "solid 1px red",
    // height: "calc(100% - 4rem)",
  },
  contentBox: {
    background: ColorsHelper.whiteClr,
    borderRadius: "1rem",
    mb: "0.625rem",
  },
  addBtnTCount: {
    ...FontsHelper.font500,
    fontSize: "1rem",
    border: `1px solid ${ColorsHelper.mainColor} !important`,
    borderRadius: "0.625rem",
    ...CommanStyles.centerIt,
    color: ColorsHelper.mainColor,
    backgroundColor: `${ColorsHelper.mainColor}17`,
    "&:hover": {
      backgroundColor: `${ColorsHelper.mainColor}17`,
    },
    height: "3rem",
    width: "100%",
    ...CommanStyles.flexRowsBetween,
    svg: {
      fontSize: "1rem",
      color: ColorsHelper.mainColor,
    },
  },
  viewPLeft: {
    width: "calc(100% - 5rem)",
    ...CommanStyles.flexColunmContainer,
  },
  viewPCustLeft: {
    width: "calc(100% - 9rem)",
    ...CommanStyles.flexColunmContainer,
  },
  viewCustLeft: {
    width: "calc(100% - 5rem)",
    ...CommanStyles.dflex,
    alignItems: "center",
  },
  viewPRight: {
    width: "5rem",
    ...CommanStyles.flexEndIt,
  },
  iconBtn: {
    border: `1px solid ${ColorsHelper.grey12}`,
    mx: "0.25rem",
    height: "2rem",
    width: "2rem",
    p: '0.1rem',
  },
  icon: {
    color: ColorsHelper.mainColor,
    fontSize: "1.2rem",
  },
  descText: {
    ...FontsHelper.font400,
    fontSize: "0.75rem",
    color: ColorsHelper.grey7,
    lineHeight: 1.3,
  },
  typeCon: {
    ...CommanStyles.flexStartIt,
    my: "0.2rem",
    img: {
      width: "1rem",
    },
    div: {
      height: "1rem",
      backgroundColor: "#d99b1e",
      color: ColorsHelper.whiteClr,
      ...FontsHelper.font500,
      ml: "0.25rem",
      fontSize: "0.6rem",
      ...CommanStyles.centerIt,
      p: "0.125rem",
      borderRadius: "0.2rem",
    },
  },
  subTitle: {
    ...FontsHelper.font500,
    fontSize: "0.85rem",
  },
  radioBtns: {
    p: "0.5rem",
    "& .Mui-checked": {
      color: `${ColorsHelper.mainColor} !important`,
    },
  },
  speInstTextF: {
    mt: "1rem",
    fieldset: {
      borderRadius: "0.625rem",
      borderColor: "#0000001f !important",
    },
    "& .MuiInputBase-root": {
      p: "0.5rem",
    },
    textarea: {
      ...FontsHelper.font400,
      fontSize: "0.8rem",
      color: ColorsHelper.grey7,
    },
  },
  showMoreBtn: {
    ...CommanStyles.flexStartIt,
    marginLeft: "-0.5rem",
    "& .MuiTypography-root": {
      ...FontsHelper.font400,
      fontSize: "0.7rem",
      color: "#DD9D08",
    },
    svg: {
      ml: "0.1rem",
      fontSize: "1rem",
      color: "#DD9D08",
    },
  },
  toppingsCon: {
    border: "1px solid #0000001f",
    borderRadius: "0.25rem",
  },
  toppingLeft: {
    width: "calc(100% - 5rem)",
    ...CommanStyles.dflex,
    alignItems: "center",
    pl: "0.5rem",
  },
  toppingRight: {
    width: "5rem",
    ...CommanStyles.flexEndIt,
    pr: "0.5rem",
  },
  topText: {
    ...FontsHelper.font300,
    fontSize: "0.85rem",
    color: ColorsHelper.blackClr,
  },
  topLightText: {
    ...FontsHelper.font400,
    fontSize: "0.85rem",
    my: "0.5rem",
    color: ColorsHelper.grey29,
  },
  dudIcon: {
    color: ColorsHelper.grey29,
  },
  checkIcon: {
    fontSize: "1.2rem",
    fontWeight: 500,
    color: ColorsHelper.mainColor,
  },
  toppingBtn: {
    borderRadius: "0rem",
    ...CommanStyles.flexRowsBetween,
    p: "0.5rem",
    pr: "1.2rem",
  },
  toppingCon: {
    ...CommanStyles.flexColunmContainer,
    width: "100%",
  },
  custmProdViewHead: {
    backgroundColor: ColorsHelper.whiteClr,
    p: "0.75rem",
    height: "5rem",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },
  orderDataCon: {
    borderRadius: "0.625rem",
    background: ColorsHelper.whiteClr,
    padding: "0.5rem 0.75rem",
    mb: "0.9rem",
  },
  pdBox: {
    width: "calc(100% - 3rem)",
    ...CommanStyles.flexColunmContainer,
    alignItems: "start",
  },
  qpBox: {
    ...CommanStyles.flexColunmContainer,
    alignItems: "end",
    width: "6rem",
    pl: "0.5rem",
  },
  proName: {
    ...FontsHelper.font500,
    fontSize: "0.938rem",
    mt: "0.3rem",
  },
  priceTxt: {
    ...FontsHelper.font500,
    fontSize: "0.85rem",
    mt: "0.25rem",
  },
  prodNameT: {
    ...FontsHelper.font500,
    fontSize: "0.85rem",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  smLigText: {
    ...FontsHelper.font400,
    fontSize: "0.8rem",
    color: ColorsHelper.blackClr,
  },
  addBtnWCountT: {
    ...FontsHelper.font500,
    fontSize: "1rem",
    border: `1px solid ${ColorsHelper.mainColor} !important`,
    borderRadius: "0.625rem",
    ...CommanStyles.centerIt,
    color: ColorsHelper.mainColor,
    backgroundColor: `${ColorsHelper.mainColor}17`,
    "&:hover": {
      backgroundColor: `${ColorsHelper.mainColor}17`,
    },
    height: "2rem",
    width: "100%",
    ...CommanStyles.flexRowsBetween,
    svg: {
      fontSize: "1rem",
      color: ColorsHelper.mainColor,
    },
  },
  saveBtn: {
    ...CommanStyles.centerIt,
    backgroundColor: ColorsHelper.mainColor,
    "&:hover": {
      backgroundColor: ColorsHelper.mainColor,
    },
    height: "3rem",
    ...FontsHelper.font500,
    color: ColorsHelper.whiteClr,
    textTransform: "none",
    width: "100%",
    padding: "0rem 2rem",
  },
  saveBtnCon: {
    p: "0.75rem 0.5rem",
    pt: "0rem",
    background: ColorsHelper.whiteClr,
    // width: "100%",
    ...CommanStyles.flexCenterItColumn,
  },
  disbledBtn: {
    backgroundColor: `${ColorsHelper.grey30} !important`,
    "&:hover": {
      backgroundColor: `${ColorsHelper.grey30} !important`,
    },
  },
  custmPCon: {
    overflow: "auto",
    px: "0.5rem",
    height: "calc(100% - 11rem)",
  },
  addNewCustBtn: {
    ...CommanStyles.centerIt,
    width: "100%",
    py: "0.75rem",
    borderRadius: "0.25rem",
    svg: {
      marginRight: "0.25rem",
      color: ColorsHelper.mainColor,
    },
  },
  addNewCustTxt: {
    ...FontsHelper.font400,
    color: ColorsHelper.mainColor,
  },
  requiredBx: {
    fontSize: "0.7rem",
    p: "0.1rem 0.3rem",
    borderRadius: "0.25rem",
    border: "0.0625rem solid #faaf0096",
    backgroundColor: "#faaf001f",
    color: "#F7921E",
    ...FontsHelper.font400,
  },
  headCon: {
    width: "100%",
    background: ColorsHelper.whiteClr,
    padding: "0.75rem 0.5rem 0.75rem 0.5rem",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },
  fsTitle: {
    ...FontsHelper.font500,
    fontSize: "1.2rem",
    wordBreak: "break-word",
  },
  fsDesc: {
    ...FontsHelper.font400,
    fontSize: "0.8rem",
    color: ColorsHelper.grey7,
    lineHeight: 1.3,
  },
  pairedTitle: {
    ...FontsHelper.font500,
    fontSize: "0.8rem",
    color: ColorsHelper.blackClr,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "2",
    overflow: "hidden",
    mt: 1,
  },
};
export default RWMStyles;
