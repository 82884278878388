import AllRoutes from "./routes";
import AppMapProvider from './UI/CsMap/MapLoader';
import DisablePullToRefresh from "./UI/DisablePullToRefresh/DisablePullToRefresh";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./Configuration/apolloClient";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from '@react-oauth/google';  // Import GoogleOAuthProvider
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const GOOGLE_CLIENT_ID = '710913804646-nlquev95musfbb133q6tn3io3trpc8tb.apps.googleusercontent.com'; // Replace with your actual Google Client ID

const App = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <AppMapProvider>
            <DisablePullToRefresh />
            <AllRoutes />
            <ToastContainer />
          </AppMapProvider>
        </Provider>
      </ApolloProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
