//React Imports
import { useNavigate } from 'react-router-dom';

//Material UI Imports
import { LoadingButton, Box, Typography, AddIcon, RemoveIcon } from '../../shared/MuiImports'


//Helper Imports
import ColorsHelper from '../../shared/ColorHelper'
import CIcon from '../../UI/CIcon/CIcon'

const Cart = () => {

    const navigate = useNavigate()

    return (
        <Box sx={{ bgcolor: ColorsHelper.backColor }}>
            <Box sx={{
                position: 'relative',
                height: '100dvh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, bgcolor: ColorsHelper.whiteClr, p: 2 }}>
                    <Box onClick={() => navigate(-1)} component={CIcon} icon={"formkit:left"} />
                    <Typography sx={{ fontSize: "1.1rem", fontWeight: 600 }}>Your Cart</Typography>
                </Box>
                <Box sx={{ overflow: 'auto', mb: 'auto', mx: 2, mt: 2, }}>
                    <Box sx={{ border: '1px solid #5C616A1A', borderRadius: '10px', bgcolor: ColorsHelper.whiteClr, p: 1.5, mb:2 }}>

                        <Box sx={{mb:4}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: ColorsHelper.green }} />
                                    <Typography sx={{ fontSize: '1rem' }}>Domino’s Pizza</Typography>
                                </Box>
                                <Box sx={{ width: '3.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: ColorsHelper.mainColorLighter, borderRadius: '10px', border: `1px solid ${ColorsHelper.mainColor}`, px: 0.8, py:0.6 }}>
                                    <RemoveIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                    <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }}>1</Typography>
                                    <AddIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                </Box>
                            </Box>
                            <Typography sx={{ ml: 3, mb: 2, fontSize:'0.8rem'}}>$39.00</Typography>

                            <Box sx={{ width: '70%', ml: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Type</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Regular (Serves1, 17 CM)</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Toppings</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Medium dipping</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Crust Options</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Crust Options</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Add-On</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Mushrooms, Tomato</Typography>
                                </Box>
                            </Box>
                        </Box>

                        {/* <Box sx={{mb:4}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: ColorsHelper.green }} />
                                    <Typography sx={{ fontSize: '1rem' }}>Domino’s Pizza</Typography>
                                </Box>
                                <Box sx={{ width: '3.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: ColorsHelper.mainColorLighter, borderRadius: '10px', border: `1px solid ${ColorsHelper.mainColor}`, px: 0.8, py:0.6 }}>
                                    <RemoveIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                    <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }}>0</Typography>
                                    <AddIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                </Box>
                            </Box>
                            <Typography sx={{ ml: 3, mb: 2, fontSize:'0.8rem' }}>$39.00</Typography>

                            <Box sx={{ width: '70%', ml: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Type</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Regular (Serves1, 17 CM)</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Toppings</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Medium dipping</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Crust Options</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Crust Options</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Add-On</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Mushrooms, Tomato</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{mb:4}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: ColorsHelper.green }} />
                                    <Typography sx={{ fontSize: '1rem' }}>Domino’s Pizza</Typography>
                                </Box>
                                <Box sx={{ width: '3.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: ColorsHelper.mainColorLighter, borderRadius: '10px', border: `1px solid ${ColorsHelper.mainColor}`, px: 0.8, py:0.6 }}>
                                    <RemoveIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                    <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }}>0</Typography>
                                    <AddIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                </Box>
                            </Box>
                            <Typography sx={{ ml: 3, mb: 2, fontSize:'0.8rem' }}>$39.00</Typography>

                            <Box sx={{ width: '70%', ml: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Type</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Regular (Serves1, 17 CM)</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Toppings</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Medium dipping</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Crust Options</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Crust Options</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Add-On</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Mushrooms, Tomato</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{mb:4}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: ColorsHelper.green }} />
                                    <Typography sx={{ fontSize: '1rem' }}>Domino’s Pizza</Typography>
                                </Box>
                                <Box sx={{ width: '3.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: ColorsHelper.mainColorLighter, borderRadius: '10px', border: `1px solid ${ColorsHelper.mainColor}`, px: 0.8, py:0.6 }}>
                                    <RemoveIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                    <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }}>0</Typography>
                                    <AddIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                </Box>
                            </Box>
                            <Typography sx={{ ml: 3, mb: 2, fontSize:'0.8rem' }}>$39.00</Typography>

                            <Box sx={{ width: '70%', ml: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Type</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Regular (Serves1, 17 CM)</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Toppings</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Medium dipping</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Crust Options</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Crust Options</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Add-On</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Mushrooms, Tomato</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{mb:4}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: ColorsHelper.green }} />
                                    <Typography sx={{ fontSize: '1rem' }}>Domino’s Pizza</Typography>
                                </Box>
                                <Box sx={{ width: '3.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: ColorsHelper.mainColorLighter, borderRadius: '10px', border: `1px solid ${ColorsHelper.mainColor}`, px: 0.8, py:0.6 }}>
                                    <RemoveIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                    <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }}>0</Typography>
                                    <AddIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                </Box>
                            </Box>
                            <Typography sx={{ ml: 3, mb: 2, fontSize:'0.8rem' }}>$39.00</Typography>

                            <Box sx={{ width: '70%', ml: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Type</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Regular (Serves1, 17 CM)</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Toppings</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Medium dipping</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Crust Options</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Crust Options</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Add-On</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Mushrooms, Tomato</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{mb:4}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: ColorsHelper.green }} />
                                    <Typography sx={{ fontSize: '1rem' }}>Domino’s Pizza</Typography>
                                </Box>
                                <Box sx={{ width: '3.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: ColorsHelper.mainColorLighter, borderRadius: '10px', border: `1px solid ${ColorsHelper.mainColor}`, px: 0.8, py:0.6 }}>
                                    <RemoveIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                    <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }}>0</Typography>
                                    <AddIcon sx={{ fontSize: '0.8rem', color: ColorsHelper.mainColor }} />
                                </Box>
                            </Box>
                            <Typography sx={{ ml: 3, mb: 2, fontSize:'0.8rem' }}>$39.00</Typography>

                            <Box sx={{ width: '70%', ml: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Type</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Regular (Serves1, 17 CM)</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Toppings</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Medium dipping</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Crust Options</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Crust Options</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLight, fontWeight: 500 }}>Add-On</Typography>
                                    <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.textColorLighter, fontWeight: 500 }}>Mushrooms, Tomato</Typography>
                                </Box>
                            </Box>
                        </Box> */}

                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap:1,
                        bgcolor: ColorsHelper.whiteClr,
                        p: 2,
                        boxShadow: '0px -1px 8px 0px #00000026',
                    }}>
                    <LoadingButton variant='outlined' fullWidth startIcon={<Box component={CIcon} icon={"basil:cross-outline"} />} sx={{width:'40%',  color: ColorsHelper.mainColor, borderRadius: '6px', borderColor: ColorsHelper.mainColor, textTransform:'none' }}>Clear Cart</LoadingButton>
                    <LoadingButton variant='contained' fullWidth startIcon={<Box component={CIcon} icon={"gg:add"} />} sx={{width:'60%', bgcolor: ColorsHelper.mainColor, borderRadius: '6px', textTransform:'none' }}>Add more items</LoadingButton>
                </Box>

            </Box>

        </Box>
    )
}

export default Cart
