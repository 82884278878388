//React Imports
import { useNavigate } from 'react-router-dom'

//Material UI Imports
import { Avatar, Box, Chip, Typography } from '../../shared/MuiImports'

//Custom Components
import CIcon from '../../UI/CIcon/CIcon'

//Helper Imports
import ColorsHelper from '../../shared/ColorHelper'

//Constant Imports
import { RouteName } from '../../constants/routeNameConstant'
import { useSelector } from 'react-redux'


const ProfileView = () => {

    const navigate = useNavigate()
    const {userDetails} = useSelector((state:any) => state.auth)

    const handleBack = () => {
        navigate(RouteName.HOME)
    }

    const handleProfileClick = () => {
        navigate(RouteName.PROFILE_SETTINGS)
    }
    return (
        <Box sx={{ bgcolor: ColorsHelper.backColor }}>
            <Box sx={{
                height: 'calc(100dvh - 1rem)',
                display: 'flex',
                flexDirection: 'column',
                px: 2, py: 1,
                gap: 2
            }}>
                <Box component={CIcon} icon={"eva:arrow-back-fill"} onClick={handleBack} />

                <Box sx={{ bgcolor: ColorsHelper.whiteClr, display: 'flex', alignItems: 'center', borderRadius: '10px', p: 1, gap: 2, cursor:'pointer' }}>
                    {
                        userDetails.file 
                            ? <Box component={"img"} src={URL.createObjectURL(userDetails.file)} sx={{borderRadius:'50%', width:'4rem', height:'4rem'}} /> 
                            : <Avatar sx={{ bgcolor: ColorsHelper.mainColorLighter, color: ColorsHelper.mainColor, cursor: 'pointer', fontSize: '2.5rem', p: 1 }}>?</Avatar> 
                    }
                    
                    <Box>
                        <Typography sx={{ fontSize: '1.2rem' }}>{userDetails.firstName}</Typography>
                        <Typography sx={{ fontSize: '0.8rem' }}>{userDetails.emailId}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', color: ColorsHelper.mainColor }}>
                            <Typography sx={{ fontSize: '0.8rem' }}>View activity</Typography>
                            <Box component={CIcon} icon={"iconamoon:arrow-right-2-fill"} />
                        </Box>
                    </Box>
                </Box>

                <Box 
                    sx={{ bgcolor: ColorsHelper.whiteClr, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '15px', p: 1.5, gap: 2, userSelect:'none', cursor:'pointer' }} 
                    onClick={handleProfileClick}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                        <Box sx={{ display: 'flex', bgcolor: ColorsHelper.whiteCyan, color: ColorsHelper.blackClr80, borderRadius: '50%', p: 0.8, cursor: 'pointer' }}>
                            <Box component={CIcon} icon={"meteor-icons:user"} sx={{ fontSize: '1.1rem' }} />
                        </Box>
                        <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>Your profile</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.2 }}>
                        <Chip
                            label="100% Completed"
                            variant="filled"
                            size='small'
                            sx={{ borderRadius: '6px', bgcolor: ColorsHelper.lightGreen, color: ColorsHelper.green1, fontSize: '0.7rem', p: 0.5 }}
                        />
                        <Box component={CIcon} icon={"iconamoon:arrow-right-2-light"} sx={{ fontSize: '1.5rem', color: ColorsHelper.grey18 }} />
                    </Box>
                </Box>

                <Box sx={{ bgcolor: ColorsHelper.whiteClr, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', borderRadius: '15px', py: 1.5, gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1 }}>
                        <Box sx={{ width: '0.2rem', height: '1.5rem', bgcolor: ColorsHelper.mainColor, borderTopRightRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }} />
                        <Typography sx={{ color: ColorsHelper.textColor3 }}>Money</Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 1.5 }}>
                            <Box sx={{ display: 'flex', bgcolor: ColorsHelper.whiteCyan, color: ColorsHelper.blackClr80, borderRadius: '100%', p: 1, cursor: 'pointer' }}>
                                <Box component={CIcon} icon={"solar:card-outline"} sx={{ fontSize: '1.1rem' }} />
                            </Box>
                            <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>Payment settings</Typography>
                        </Box>
                        <Box component={CIcon} icon={"iconamoon:arrow-right-2-light"} sx={{ fontSize: '1.5rem', color: ColorsHelper.grey18, mr: 1.5 }} />
                    </Box>
                </Box>

                <Box sx={{ bgcolor: ColorsHelper.whiteClr, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', borderRadius: '15px', py: 1.5, gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1 }}>
                        <Box sx={{ width: '0.2rem', height: '1.5rem', bgcolor: ColorsHelper.mainColor, borderTopRightRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }} />
                        <Typography sx={{ color: ColorsHelper.textColor3 }}>Coupons</Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 1.5 }}>
                            <Box sx={{ display: 'flex', bgcolor: ColorsHelper.whiteCyan, color: ColorsHelper.blackClr80, borderRadius: '100%', p: 1, cursor: 'pointer' }}>
                                <Box component={CIcon} icon={"circum:discount-1"} sx={{ fontSize: '1.1rem' }} />
                            </Box>
                            <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>Collected coupons</Typography>
                        </Box>
                        <Box component={CIcon} icon={"iconamoon:arrow-right-2-light"} sx={{ fontSize: '1.5rem', color: ColorsHelper.grey18, mr: 1.5 }} />
                    </Box>
                </Box>

                <Box sx={{ bgcolor: ColorsHelper.whiteClr, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', borderRadius: '15px', py: 1.5, gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1 }}>
                        <Box sx={{ width: '0.2rem', height: '1.5rem', bgcolor: ColorsHelper.mainColor, borderTopRightRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }} />
                        <Typography sx={{ color: ColorsHelper.textColor3 }}>Table Bookings</Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 1.5 }}>
                            <Box sx={{ display: 'flex', bgcolor: ColorsHelper.whiteCyan, color: ColorsHelper.blackClr80, borderRadius: '100%', p: 1, cursor: 'pointer' }}>
                                <Box component={CIcon} icon={"material-symbols:table-bar-outline"} sx={{ fontSize: '1.1rem' }} />
                            </Box>
                            <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>Your table bookings</Typography>
                        </Box>
                        <Box component={CIcon} icon={"iconamoon:arrow-right-2-light"} sx={{ fontSize: '1.5rem', color: ColorsHelper.grey18, mr: 1.5 }} />
                    </Box>
                </Box>

                <Box sx={{ bgcolor: ColorsHelper.whiteClr, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '15px', p: 1.5, gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ display: 'flex', bgcolor: ColorsHelper.whiteCyan, color: ColorsHelper.blackClr80, borderRadius: '50%', p: 0.8, cursor: 'pointer' }}>
                            <Box component={CIcon} icon={"prime:power-off"} sx={{ fontSize: '1.1rem' }} />
                        </Box>
                        <Typography sx={{ fontSize: '0.9rem', fontWeight: 600 }}>Log out</Typography>
                    </Box>

                    <Box component={CIcon} icon={"iconamoon:arrow-right-2-light"} sx={{ fontSize: '1.5rem', color: ColorsHelper.grey18 }} />
                </Box>
            </Box>
        </Box>
    )
}

export default ProfileView
