//React Imports
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Material UI Imports
import { Box, Button, Collapse, Divider, Grid2, IconButton, Typography } from '../../../shared/MuiImports'

//Custom Component
import CIcon from '../../../UI/CIcon/CIcon'

//Helper Imports
import ColorsHelper from '../../../shared/ColorHelper'
import { LoadingButton } from '@mui/lab';
import { RouteName } from '../../../constants/routeNameConstant';

//Icon Imports
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";

//Sample Imports
import restaurant1 from '../../../Assets/images/restaurant1.png'
import restaurant2 from '../../../Assets/images/restaurant2.png'

const RestaurantView = () => {

  const navigate = useNavigate()

  const [openTiming, setOpenTiming] = useState<boolean>(false)

  const handleTimingToggle = () => {
    setOpenTiming(!openTiming)
  }

  const handleBack = () => {
    navigate(RouteName.HOME);
  }

  const timing = [
    {
      day: "Monday",
      time: '10:30 am - 10:45 pm'
    },
    {
      day: "Tuesday",
      time: '10:30 am - 10:45 pm'
    },
    {
      day: "Wednesday",
      time: '10:30 am - 10:45 pm'
    },
    {
      day: "Thursday",
      time: '10:30 am - 10:45 pm'
    },
    {
      day: "Friday",
      time: '10:30 am - 10:45 pm'
    },
    {
      day: "Saturday",
      time: '10:30 am - 10:45 pm'
    },
    {
      day: "Sunday",
      time: '10:30 am - 10:45 pm'
    }
  ]


  const restaurantImages = [restaurant1, restaurant2]

  return (
    <Box sx={{ bgcolor: ColorsHelper.backColor }}>
      <Box sx={{
        position: 'relative',
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
          <Box component={CIcon} icon={"eva:arrow-back-fill"} onClick={handleBack} sx={{ m: 2 }} />
        <Box sx={{ overflow: 'auto',mb:'auto' }}>
          <Box sx={{
            bgcolor: ColorsHelper.whiteClr,
            borderRadius: '10px',
            p: 2, mx: 2,
          }}>
            <Typography sx={{
              fontSize: '1.2rem',
              fontWeight: 600
            }}>
              The Blue Plaza
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.gray37, }}>
                Pizza
              </Typography>
              <FiberManualRecordRoundedIcon sx={{ fontSize: "0.3rem", color: ColorsHelper.gray37, }} />
              <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.gray37, }}>
                Fast Food
              </Typography>
              <FiberManualRecordRoundedIcon sx={{ fontSize: "0.3rem", color: ColorsHelper.gray37, }} />
              <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.gray37, }}>
                $4.18 for one
              </Typography>
            </Box>
            <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.textColor, }}>
              176 Newberry Commons, Etters, PA 17319
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1.5 }}>
              <IconButton sx={{
                border: `1px solid ${ColorsHelper.gray36}`,
                borderRadius: '50%',
                padding: '5px',
                fontSize: '1.3rem',
                color: ColorsHelper.mainColor
              }}>
                <Box component={CIcon} icon={"prime:directions"} />
              </IconButton>
              <IconButton sx={{
                border: `1px solid ${ColorsHelper.gray36}`,
                borderRadius: '50%',
                padding: '5px',
                fontSize: '1.3rem',
                color: ColorsHelper.mainColor
              }}>
                <Box component={CIcon} icon={"icon-park-outline:phone-call"} />
              </IconButton>
              <Button variant='outlined' sx={{
                textTransform: 'none',
                color: ColorsHelper.textColor,
                borderColor: ColorsHelper.gray36,
                borderRadius: '100px'
              }}
                startIcon={<Box component={CIcon} icon={"ion:restaurant-outline"} sx={{ color: ColorsHelper.mainColor }} />}>
                View menu
              </Button>
            </Box>

            <Divider sx={{ width: '100%', height: '0.9rem', color: ColorsHelper.gray36 }} />

            <Box
              onClick={handleTimingToggle}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5, mt: 1
              }}>
              <Box component={CIcon} icon={"basil:clock-outline"} sx={{ fontSize: '0.9rem' }} />
              <Typography sx={{ color: ColorsHelper.green1, fontSize: '0.8rem' }}>
                Open Now
              </Typography>
              <FiberManualRecordRoundedIcon sx={{ fontSize: "0.3rem", color: ColorsHelper.gray37, }} />
              <Typography sx={{ fontSize: '0.8rem' }}>
                Close 10:45 pm
              </Typography>
              <Box component={CIcon} icon={"mingcute:down-line"} sx={{ fontSize: '0.9rem' }} />
            </Box>
            {/* Collapsible Menu */}
            <Collapse in={openTiming}>
              <Box
                sx={{
                  mt: 1,
                  p: 1,
                }}
              >
                {
                  Array.isArray(timing) && timing?.length > 0 && timing?.map((item: any, index:number) => (
                    <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography sx={{ fontSize: "0.8rem", color: ColorsHelper.textColor2 }}>
                        {item?.day}
                      </Typography>
                      <Typography sx={{ fontSize: "0.8rem", color: ColorsHelper.textColor4 }}>
                        {item?.time}
                      </Typography>
                    </Box>
                  ))
                }

              </Box>
            </Collapse>

            <Divider sx={{ width: '100%', height: '0.9rem', color: ColorsHelper.gray36, borderStyle: 'dashed' }} />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 1 }}>
              <Box component={CIcon} icon={"uil:store"} sx={{ fontSize: '0.9rem' }} />
              <Typography sx={{ fontSize: '0.8rem' }}>Provides dining</Typography>
            </Box>

            <Divider sx={{ width: '100%', height: '0.9rem', color: ColorsHelper.gray36, borderStyle: 'dashed' }} />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, my: 1 }}>
              <Box component={CIcon} icon={"mynaui:image"} sx={{ fontSize: '0.9rem' }} />
              <Typography sx={{ fontSize: '0.8rem' }}>Photos</Typography>
            </Box>

            <Grid2 container spacing={2}>
              {
                restaurantImages?.map((image: any, index:number) => (
                  <Grid2 key={index} size={6}>
                    <Box component={'img'} src={image} sx={{ borderRadius: '10px' }} />
                  </Grid2>
                ))
              }
            </Grid2>
          </Box>
        </Box>

        <Box
          sx={{
            bgcolor: ColorsHelper.whiteClr,
            p: 2,
            boxShadow: '0px -1px 8px 0px #00000026',
          }}>
          <LoadingButton variant='contained' fullWidth sx={{ bgcolor: ColorsHelper.mainColor, borderRadius: '6px' }}>Go back to menu</LoadingButton>
        </Box>

      </Box>
    </Box>
  )
}

export default RestaurantView
