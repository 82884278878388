//React Imports
import { useState, useEffect, useRef, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

//Materia UI Imports
import { Box, Button, Collapse, IconButton, Menu, MenuItem, Typography } from '../../shared/MuiImports'

//Third Party Imports
import * as THREE from 'three';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { a, useSpring } from '@react-spring/three';
import { Swiper, SwiperSlide } from 'swiper/react';

//Icons Imports
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

//Custom Imports
import CIcon from '../../UI/CIcon/CIcon';
import RWMStyles from '../Home/RestaurantWithMenu/RestaurantWithMenu.style';
import ColorsHelper from '../../shared/ColorHelper';
import { RouteName } from '../../constants/routeNameConstant';

//Styles Imprts
import 'swiper/css';

//Sample Images
import pizza from "../../Assets/3d/pizza-2.glb";
import clubSandwich from "../../Assets/3d/club_sandwich_pile.glb";
import blackForestCake from "../../Assets/3d/black_forest_gateau_cake.glb";
import fishTaco from "../../Assets/3d/loaded_fish_taco.glb";
import taco from "../../Assets/3d/taco.glb";
import pizza2 from "../../Assets/3d/pizza2.glb";
import ChickenPizza from "../../Assets/3d/CHicken_pizza.glb"


import PizzaRectangle from '../../Assets/images/PizzaRectangle.png'


const AnimatedModel = ({ modelPath }) => {
  const { scene } = useGLTF(modelPath);
  const ref = useRef();
  const { camera } = useThree();

  useEffect(() => {
    if (ref.current) {
      const box = new THREE.Box3().setFromObject(ref.current);
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      const cameraDistance = maxDim / (2 * Math.tan(fov / 2)) + 1;

      camera.position.set(center.x, center.y, cameraDistance);
      camera.lookAt(center);

      const controls = camera.controls;
      if (controls) {
        controls.target.copy(center);
        controls.update();
      }
    }
  }, [scene, camera]);

  const spring = useSpring({
    scale: [1, 1, 1],
    opacity: 1,
    from: { scale: [0, 0, 0], opacity: 0 },
    config: { tension: 120, friction: 20 },
    reset: true,
  });

  return (
    <a.group scale={spring.scale} ref={ref}>
      <a.primitive object={scene} />
    </a.group>
  );
};


const ThreeDViewer = () => {
  const defaultProdDet = {
    customisation: null,
    toppings: [],
    addOnProduct: [],
    crustOption: null,
    instruction: "",
  };

  const navigate = useNavigate()

  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [noOfProduct, setNoOfProduct] = useState(0);
  const [openProductView, setOpenProductView] = useState({ open: false, isDetailedView: false, data: null });
  const [inView, setInView] = useState(true);
  const [otherProdDetails, setOtherProductDetails] = useState(defaultProdDet);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openCustmPro, setOpenCustmPro] = useState({ open: false, data: null });

  const [category, setCategory] = useState([
    // {
    //   id: "1",
    //   name: "Your Previous Picks",
    //   menu_id: 1,
    //   position: 0,
    //   is_active: true,
    //   is_previous_pick: true,
    //   items: [
    //     {
    //       name: "Italian Pizza [ 8 Inches ] ",
    //       id: "2",
    //       position: 1,
    //       image: PizzaRectangle,
    //       description: "You orderd 10 days ago",
    //       formatted_price: 10,
    //       currency: "USD",
    //       has_customization: false,
    //       has_addon_or_upsell_addon: false,
    //       has_nutritional_and_allergen_info: false,
    //       has_special_instruction: false,
    //       has_promotion: false,
    //       has_visible: true,
    //       visibility_status: 1,
    //       is_out_of_stock: false,
    //       hasSpecialInstructions: true,
    //       __typename: "categoryItemsResponse",
    //     },

    //     {
    //       name: "Margherita Pizza [ 8 Inches ]",
    //       id: "5",
    //       position: 4,
    //       image: PizzaRectangle,
    //       description: "You order 1 month ago",
    //       formatted_price: 13.99,
    //       currency: "USD",
    //       has_customization: false,
    //       has_addon_or_upsell_addon: false,
    //       has_nutritional_and_allergen_info: false,
    //       has_special_instruction: false,
    //       has_promotion: false,
    //       has_visible: true,
    //       visibility_status: 1,
    //       is_out_of_stock: false,
    //       __typename: "categoryItemsResponse",
    //     },
    //   ],
    //   __typename: "Personalized",
    // },
    {
      id: "2",
      name: "Specialty Pizzas",
      menu_id: 1,
      position: 1,
      is_active: true,
      items: [
        {
          name: "Philly Cheese Steak Pizza",
          id: "2",
          position: 1,
          image: PizzaRectangle,
          description:
            "Tender slices of steak, fresh onions, fresh green peppers and fresh mushrooms with provolone and American cheese on a cheesy provolone crust. ",
          formatted_price: 13.99,
          currency: "USD",
          has_customization: true,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          customisation: [
            {
              value: 13.99,
              label: "Regular (Serves 2.Inch CM)",
              id: 1,
            },
            {
              value: 19.99,
              label: "Medium (Serves 4.Inch CM)",
              id: 2,
            },
            {
              value: 24.99,
              label: "Large (Serves 7.Inch CM)",
              id: 3,
            },
          ],
          toppings: [
            {
              value: 5,
              label: "Marinara Sauce Dipping",
              id: 1,
              options: [
                {
                  label: "Light Dipping",
                  id: 1,
                },
                {
                  label: "Medium Dipping",
                  id: 2,
                },
                {
                  label: "Extra Dipping",
                  id: 3,
                },
              ],
            },
          ],
          addOnProduct: [
            {
              value: 5,
              label: "Finger Foods",
              id: 1,
            },
            {
              value: 10,
              label: "Dips and Spreads",
              id: 2,
            },
            {
              value: 15,
              label: "Burger",
              id: 3,
            },
            {
              value: 20,
              label: "Coca cola",
              id: 4,
            },
          ],
          crustOption: [
            {
              value: 5,
              label: "Thick Metro Crust",
              id: 1,
            },
            {
              value: 5,
              label: "Choclate Syrup",
              id: 2,
            },
          ],
          hasSpecialInstructions: true,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Cali Chicken Bacon Ranch Pizza",
          id: "3",
          position: 2,
          image: PizzaRectangle,
          description:
            "Grilled chicken breast, white sauce, smoked bacon, tomatoes, cheese made with mozzarella and provolone on a cheese crust. ",
          formatted_price: 13.99,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Pacific Veggie Pizza",
          id: "5",
          position: 4,
          image: PizzaRectangle,
          description:
            "Roasted red peppers, fresh baby spinach, fresh onions, fresh mushrooms, tomatoes, black olives, cheeses made with mozzarella, feta and provolone, sprinkled with garlic herb seasoned on a cheesy Parmesan-Asiago crust. Vegetarian.",
          formatted_price: 13.99,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
    {
      id: "3",
      name: "Sides",
      menu_id: 1,
      position: 0,
      is_active: true,
      items: [
        {
          name: "Stuffed Cheesy Bread",
          id: "16",
          position: 0,
          image: PizzaRectangle,
          description:
            "Our oven-baked breadsticks are generously stuffed and covered with a blend of 100% real mozzarella and cheddar cheeses then seasoned with a touch of garlic. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Stuffed Cheesy Bread with Spinach and Feta",
          id: "17",
          position: 1,
          image: PizzaRectangle,
          description:
            "Our oven-baked breadsticks are stuffed with cheese, fresh spinach and Feta cheese - covered in a blend of cheese made with 100% real mozzarella and cheddar. Seasoned with a touch of garlic and Parmesan. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Stuffed Cheesy Bread with Bacon and Jalepano",
          id: "18",
          position: 2,
          image: PizzaRectangle,
          description:
            "Our oven-baked breadsticks are stuffed with cheese, smoked bacon & jalapeno peppers - covered in a blend of cheeses; made with 100% real mozzarella and cheddar. Seasoned with a touch of garlic and Parmesan. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Stuffed Cheesy Bread with Pepperoni",
          id: "19",
          position: 3,
          image: PizzaRectangle,
          description:
            "Our oven-baked breadsticks are stuffed with cheese, fresh spinach and Feta cheese - covered in a blend of cheese made with 100% real mozzarella and cheddar. Seasoned with a touch of garlic and Parmesan. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
    {
      id: "4",
      name: "Pasta",
      menu_id: 1,
      position: 0,
      is_active: true,
      items: [
        {
          name: "Chicken Alfredo",
          id: "46",
          position: 0,
          image: PizzaRectangle,
          description:
            "Try our savory Chicken Alfredo Pasta. Grilled chicken breast and creamy Alfredo sauce is mixed with penne pasta and baked to creamy perfection.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Chicken Alfredo Breadbowl",
          id: "47",
          position: 1,
          image: PizzaRectangle,
          description:
            "Try our savory Chicken Alfredo Pasta baked into a Breadbowl. Grilled chicken breast and creamy Alfredo sauce is mixed with penne pasta and baked to creamy perfection.",
          formatted_price: 9.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
    {
      id: "5",
      name: "Oven-Baked Sandwiches",
      menu_id: 1,
      position: 0,
      is_active: true,
      items: [
        {
          name: "Chicken Bacon Ranch Sandwich",
          id: "56",
          position: 0,
          image: PizzaRectangle,
          description:
            "Grilled chicken breast, smoked bacon, creamy ranch and provolone cheese, on artisan bread and baked to a golden brown. ",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Chicken Parm Sandwich",
          id: "57",
          position: 1,
          image: PizzaRectangle,
          description:
            "Grilled chicken breast, tomatoes basil marinara, Parmesan asiago and provolone cheese,on artisan bread and baked to a golden brown. ",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
  ]);

  const menuItems = [
    { id: 1, name: '1', title: 'Philly Cheese Steak Pizza', description: 'Best in Pizza', dietry: 'veg', price: '13.99 USD', modelPath: pizza },
    { id: 2, name: '2', title: 'Veg Taco', description: 'Best in Taco', dietry: 'veg', price: '11.99 USD', modelPath: taco },
    { id: 3, name: '3', title: 'Stuffed Cheesy Sandwich', description: 'Best in Sandwich', dietry: 'nonveg', price: '14.99 USD', modelPath: clubSandwich },
    { id: 4, name: '4', title: 'Black Forest Cake', description: 'Best in Cake', dietry: 'veg', price: '11.99 USD', modelPath: blackForestCake },
    { id: 5, name: '5', title: 'Fish Taco', description: 'Best in Taco', dietry: 'nonveg', price: '11.99 USD', modelPath: fishTaco },
    { id: 6, name: '6', title: 'Loaded Pizza', description: 'Best in Pizza', dietry: 'veg', price: '13.99 USD', modelPath: pizza2 },
    { id: 7, name: '7', title: 'Chicken Pizza', description: 'Best in Pizza', dietry: 'nonveg', price: '14.99 USD', modelPath: ChickenPizza },
  ];

  const [selectedModel, setSelectedModel] = useState(menuItems[0]);
  const [activeCat, setActiveCat] = useState(1);
  const catRef = useRef([]);
  const [expanded, setExpanded] = useState(1);

  const executeScroll = (id) => {
    if (catRef?.current)
      catRef?.current[id]?.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Handle model selection on slide change
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setSelectedModel(menuItems[activeIndex]);
  };

  const applyItemCountAnimation = (type) => {
    const itemCount = document.getElementById("itemCount");
    let offerTxt = null;
    if (noOfProduct <= 3) offerTxt = document.getElementById("offerTxt");
    const animationClass = type === 1 ? "slideUp" : "slideDown";
    itemCount?.classList?.add(animationClass);
    if (noOfProduct <= 3) offerTxt?.classList?.add(animationClass);
    setTimeout(() => {
      itemCount?.classList?.remove(animationClass);
      if (noOfProduct <= 3) offerTxt?.classList?.remove(animationClass);
    }, 1000);
  };

  return (
    <>
      <Box
        sx={{
          height: '100dvh',
          backgroundColor: '#4d4d4d',
          position: 'relative',
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
        }}>

        {/* Header Buttons */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 2,
            px: '0.5rem',
            pt: '0.5rem'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <IconButton
              onClick={() => navigate(RouteName.HOME)}
              sx={{
                p: 1,
                fontSize: '1.3rem',
                color: ColorsHelper.whiteClr,
                bgcolor: ColorsHelper.textColorLight,
              }}
            >
              <Box component={CIcon} icon={"mingcute:arrow-left-line"} />
            </IconButton>
            <Typography
              sx={{ fontSize: '1.1rem', fontWeight: 600, color: ColorsHelper.whiteClr }}
            >
              The Restro Beast
            </Typography>
          </Box>
          <IconButton
            sx={{
              borderRadius: '8px',
              bgcolor: ColorsHelper.textColorLight,
              p: 1,
              color: ColorsHelper.whiteClr,
              fontSize: '0.9rem',
            }}
          >
            <Box
              component={CIcon}
              icon={"uil:upload"}
            />
          </IconButton>
        </Box>


        <Box sx={{ height: 'calc(100dvh - 20rem)' }}>
          <Canvas>
            <color attach="background" args={['#4d4d4d']} />
            <ambientLight intensity={3} />
            <pointLight position={[10, 10, 10]} />
            <OrbitControls
              enablePan={false}
              enableRotate={true}
              enableZoom={true}
            />
            <Suspense fallback={null}>
              <AnimatedModel modelPath={selectedModel.modelPath} />
            </Suspense>
          </Canvas>
        </Box>


        <Box sx={{ width: '-webkit-fill-available' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 1 }}>
            <Typography sx={{ fontSize: '1.1rem', color: ColorsHelper.white6, fontWeight: 600 }}>Menu Online</Typography>
            <Box>
              <Button
                sx={{ bgcolor: ColorsHelper.grayDark, color: ColorsHelper.whiteClr, borderRadius: '8px' }}
                onClick={(e) => setAnchorElMenu(e.currentTarget)}
                startIcon={<Box component={CIcon} icon={"akar-icons:three-line-horizontal"} />}
              >
                3D Menu
              </Button>
            </Box>
          </Box>

          {/* Horizontal swiper slider for model selection */}
          <Box sx={{ m: 1 }}>
            <Swiper
              spaceBetween={10} // Space between slides
              grabCursor={true} // Change cursor to a grab hand
              breakpoints={{
                0: { slidesPerView: 1 },
                568: { slidesPerView: 1 },
                660: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                915: { slidesPerView: 1.2 }
              }}
              onSlideChange={handleSlideChange} // Listen for slide change
            >
              {menuItems.map((item, index) => (
                <SwiperSlide key={index}>
                  <Box
                    sx={{
                      height: '7rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '1rem',
                      borderRadius: '20px',
                      bgcolor: ColorsHelper.whiteClr,
                      p: '1rem'
                    }}>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Box
                          component={CIcon}
                          icon={"material-symbols-light:square-dot-rounded"}
                          sx={{ color: item.dietry === 'veg' ? "green" : "red" }} />
                        <Typography sx={{ fontWeight: 500, fontSize: '0.9rem' }}>
                          Recommended
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2, // Limits text to 2 lines
                          textOverflow: 'ellipsis', // Adds ellipsis if text overflows
                        }}>
                        {item.title}
                      </Typography>
                      <Typography sx={{ fontSize: '0.8rem', color: ColorsHelper.gray36 }}>
                        {item.description}
                      </Typography>
                      <Typography sx={{ fontSize: '0.9rem', color: ColorsHelper.blue3 }}>
                        {item.price}
                      </Typography>
                    </Box>
                    <Box>
                      {selectedProduct.hasOwnProperty(item?.id) &&
                        selectedProduct[item?.id]?.qty > 0 ? (
                        <Box
                          sx={[
                            RWMStyles.addBtnWCount,
                            {
                              backgroundColor:
                                ColorsHelper.mainColor,

                              "&:hover": {
                                backgroundColor:
                                  ColorsHelper.mainColor,
                              },
                            },
                          ]}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if (item?.customisation?.length > 0) {
                                setOpenCustmPro({
                                  open: true,
                                  data: item,
                                });
                              } else {
                                setNoOfProduct((prevValue) => prevValue - 1 < 0 ? 0 : prevValue - 1);
                                applyItemCountAnimation(2);
                                if (selectedProduct[item?.id]?.qty === 1) {
                                  let newSelectedProduct = selectedProduct;
                                  delete newSelectedProduct[item?.id];
                                  setSelectedProduct(newSelectedProduct);
                                } else {
                                  setSelectedProduct((prevValue) => ({
                                    ...prevValue,
                                    [item?.id]:
                                      { ...item, qty: prevValue[item?.id]?.qty - 1, },
                                  })
                                  );
                                }
                              }
                            }}
                          >
                            <RemoveIcon
                              sx={{ fontSize: 22 }}
                            />
                          </IconButton>
                          <span>
                            {
                              selectedProduct[item?.id]?.qty
                            }
                          </span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if (item?.customisation?.length > 0) {
                                setOpenCustmPro({
                                  open: true,
                                  data: item,
                                });
                              } else {
                                setNoOfProduct((prevValue) => prevValue + 1);
                                applyItemCountAnimation(1);
                                setSelectedProduct((prevValue) => ({
                                  ...prevValue,
                                  [item?.id]: {
                                    ...item,
                                    qty: prevValue[item?.id]?.qty + 1,
                                  },
                                })
                                );
                              }
                            }}
                          >
                            <AddIcon sx={{ fontSize: 22 }} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Button
                          sx={[RWMStyles.addBtn,
                          {
                            border: `1px solid ${ColorsHelper.mainColor} !important`,
                            color: ColorsHelper.mainColor,
                            svg: {
                              color: ColorsHelper.mainColor,
                            },
                          },
                          ]}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item?.customisation?.length > 0) {
                              setOpenProductView({
                                open: true,
                                isDetailedView: false,
                                data: item,
                              });
                              setInView(false);
                              let otherDetails =
                                otherProdDetails;
                              if (item?.customisation?.length > 0) {
                                otherDetails["customisation"] = item?.customisation[0];
                              }
                              setOtherProductDetails(
                                otherDetails
                              );
                            } else {
                              setNoOfProduct(
                                (prevValue) =>
                                  prevValue + 1
                              );
                              applyItemCountAnimation(
                                1
                              );
                              setSelectedProduct(
                                (prevValue) => ({
                                  ...prevValue,
                                  [item?.id]: {
                                    ...item,
                                    qty: 1,
                                  },
                                })
                              );
                            }
                          }}
                        >
                          Add
                          <AddIcon sx={{ height: "0.9rem", }} />
                        </Button>
                      )}
                      <Typography sx={{ fontSize: '0.7rem', color: ColorsHelper.gray36, textAlign: 'center', mt: 1 }}>customizable</Typography>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          {/* Cart Button */}
          <Collapse in={noOfProduct > 0} timeout="auto">
            <Box
              sx={{
                backgroundColor: ColorsHelper.whiteClr,
                boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
                mt: 1
              }}
            >
              <Button
                onClick={() => navigate(RouteName.CART)}
                sx={[
                  RWMStyles.selItemCon,
                  {
                    boxShadow: '1px -1px 4px 0px #00000033',
                    "&:hover": { backgroundColor: ColorsHelper.mainColor },
                  },
                ]}
              >
                <Box>
                  <span id="itemCount" className="itemsTxt animateTxt">
                    <span className="itemCountTxt">{noOfProduct}</span>
                    {`${noOfProduct > 1 ? "items" : "item"} added`}
                  </span>
                  <ArrowCircleRightIcon />
                </Box>
              </Button>
            </Box>
          </Collapse>

        </Box>
      </Box>

      <Menu
        anchorEl={anchorElMenu}
        open={Boolean(anchorElMenu)}
        sx={{ ...RWMStyles.menuPopup, zIndex: 1305 }}
        anchorOrigin={{
          vertical: -50,
          horizontal: 70,
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={() => {
          setAnchorElMenu(null);
        }}
      >
        {/* Default Heading */}
        <MenuItem
          sx={[
            RWMStyles.menuItem,{
            fontWeight: 'bold',
            fontSize: '1rem',
            color: ColorsHelper.mainColor,
            "&.Mui-disabled": {
              color: ColorsHelper.mainColor, // Override disabled state color
            },
          }
          ]}
        >
          <span>3d Menu</span>
          <Box component={CIcon} icon={"ic:round-close"} onClick={() => setAnchorElMenu(null)} sx={{color:ColorsHelper.blackClr}} />
        </MenuItem>
        {category?.length > 0 &&
          category.map((cat, cIndex) => (
            <MenuItem
              key={cIndex}
              onClick={() => {
                setActiveCat(Number(cat?.id));
                executeScroll(Number(cat?.id));
                setAnchorElMenu(null);
                setTimeout(() => {
                  setExpanded(Number(cat?.id));
                }, 500);
              }}
              sx={[
                RWMStyles.menuItem,
                activeCat === Number(cat?.id) && {
                  color: ColorsHelper.mainColor,
                  fontWeight: 700,
                },
              ]}
            >
              <span sx={RWMStyles.menuNameT}>{cat?.name}</span>
              <span>20</span>
            </MenuItem>
          ))}

        {/* Close Button */}
        {/* <Box
                    sx={{
                      position: 'sticky',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      // padding: '10px',
                      bottom: 0,
                      background: ColorsHelper.whiteClr,
                    }}
                  >
                    <Button
                      sx={{
                        position: 'fixed',
                        bottom: '1.2rem',
                        right: '1.2rem',
                        bgcolor: ColorsHelper.grayDark,
                        color: ColorsHelper.whiteClr,
                        borderRadius: '8px',
                        zIndex: 1310, // Ensures it appears above most elements
                      }}
                      onClick={() => setAnchorElMenu(null)}
                      startIcon={<Box component={CIcon} icon={'akar-icons:cross'} />}
                    >
                      Close
                    </Button>
                  </Box> */}
      </Menu>
    </>
  );
};

export default ThreeDViewer;
