import { createSlice } from "@reduxjs/toolkit";
import { sendOtp, updateUser, verifyOtp } from "./AuthAction";

interface InitialState {
    loading: boolean;
    error: any;
    message: string | null;
    userDetails: any; // Changed userDetails to userDetails
}

const initialState: InitialState = {
    loading: false,
    error: null,
    message: null,
    userDetails: {}, // Initialize userDetails as an empty object
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.userDetails = {}; // Reset userDetails on logout
            localStorage.removeItem('AUTH_TOKEN');
        },
        setUserMobileNumber: (state, action) => {
            state.userDetails = { ...state.userDetails, mobile_no: action.payload }; // Update mobile_no
        },
        setUserdetail: (state, action) => {
            state.userDetails = { ...state.userDetails, ...action.payload }; // Update userDetails with payload data

            console.log("State after setting user details:::::::::::", state.userDetails);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendOtp.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(sendOtp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(verifyOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.loading = false;
                localStorage.setItem("AUTH_TOKEN", action?.payload?.authorization as string);
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = false;  
                // console.log("State after setting user details:::::::::::", state.userDetails);
                // state.userDetails = { ...state.userDetails, ...action.payload };
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
    },
});

export const { logout, setUserMobileNumber, setUserdetail } = authSlice.actions;
export default authSlice.reducer;
