import { useState, useEffect, useRef } from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import ColorsHelper from '../../shared/ColorHelper';
import CIcon from '../CIcon/CIcon';
import { useNavigate } from 'react-router-dom';
import { RouteName } from '../../constants/routeNameConstant';

interface Props {
  content?: any;
  media?: any;
  title?: any
}

const ResizableDiv = ( props : Props) => {

  const { content, media, title } = props;

  const navigate = useNavigate()

  console.log("Media ==>", media)

  const [isSticky, setIsSticky] = useState(false); // State to track if header is sticky
  const headerRef = useRef<any>(null); // Ref to the header

  const handleClose = () => {
    navigate(RouteName.HOME)
  }

  const getMediaType = (media : any) => {
    const extension = media.split('.').pop().toLowerCase();
    return ['mp4', 'webm', 'ogg'].includes(extension) ? 'video' : 'image';
  };
  
  const mediaType = getMediaType(media);

  // Effect to track scroll position and update sticky state
  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerPosition = headerRef.current.getBoundingClientRect().top;
        // Set sticky state based on the header's position
        setIsSticky(headerPosition <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative', // Ensure relative positioning for child sticky behavior
        height: '100vh', // Make sure the parent has full screen height
        backgroundAttachment: 'fixed', // Fixed background of the parent
        overflow: 'hidden', // Hide overflow from the parent
        pt: !media ? "" : 35,
      }}
    >
      {
        media && 
        <Box
        sx={{
          position: 'fixed', // This makes the image fixed on the screen
          maxWidth:'442px',
          height: '300px', // Adjust the height of the image
          margin: 'auto',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 0, // Ensure the image is behind content and sticky header
        }}
      >
        {mediaType === 'image' ? (
    <img
      src={media}
      alt="Fixed"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the image covers the area nicely
      }}
    />
  ) : (
    <video
      src={media}
      autoPlay
      loop
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the video covers the area nicely
      }}
    />
  )}


         {/* Back Button and Options Button on Image/Video */}
         <Box
                        sx={{
                            position: "absolute",
                            top: 16,
                            left: 16,
                            right: 16,
                            display: "flex",
                            justifyContent: "space-between",
                            zIndex: 1000,
                        }}
                    >
                        <IconButton onClick={handleClose} sx={{
                            color: ColorsHelper.whiteClr,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            fontSize: '0.6rem',
                            padding: '0.3rem',
                        }}>
                            <Box component={CIcon} icon={"eva:arrow-back-outline"} />
                        </IconButton>
                        <IconButton sx={{
                            color: ColorsHelper.whiteClr,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            fontSize: '0.6rem',
                            padding: '0.3rem',
                        }}>
                            <Box component={CIcon} icon={"pepicons-pencil:dots-y"} />
                        </IconButton>
                    </Box>
      </Box>
      }

      {
        isSticky && title  
      }

      <Box
        sx={{
          width: '100%',
          bgcolor: 'white',
          height: '100%', // Full height of the parent box
          overflowY: 'auto', // Make child scrollable
          position: 'relative',
          borderTopLeftRadius: !media || isSticky ? "" : '20px',
          borderTopRightRadius: !media || isSticky ? "" : '20px',
        }}
      >
        {/* Sticky section */}
        <Box
          ref={headerRef}
          sx={{
            position: 'sticky',
            // Adjust sticky position based on the image height
            bgcolor: ColorsHelper.backColor,
            zIndex: 1, // Ensures it stays above the content when sticky
            p: 0.3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Divider sx={{ height: "0.3rem", width: '5rem', bgcolor: ColorsHelper.gray36, borderRadius: '1rem' }}></Divider>
        </Box>

        {/* Content that will scroll under the sticky header */}
        <Box sx={{ maxHeight: 'calc(100vh - 10px)', overflow: isSticky ? 'auto' : 'hidden', bgcolor: 'gray' }}>
          {content}
        </Box>
      </Box>

    </Box >
  );
};

export default ResizableDiv;
