import { ApolloClient, ApolloLink, InMemoryCache, Observable } from '@apollo/client';
import { getIpAddress } from '../shared/utils';
import { createUploadLink } from 'apollo-upload-client';

let serverUrl;
if (process.env.REACT_APP_APP_MODE === "LOCAL") {
    serverUrl = process.env.REACT_APP_LOCAL_URL;
} else if (process.env.REACT_APP_APP_MODE === "DEVELOPMENT") {
    serverUrl = process.env.REACT_APP_DEVELOPMENT_URL;
} else if (process.env.REACT_APP_APP_MODE === "PRODUCTION") {
    serverUrl = process.env.REACT_APP_PRODUCTION_URL;
}

const dynamicHeaderLink = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
        (async () => {
            try {
                const ip = await getIpAddress();
                const appName = 'menuonline-customer';
                const token = localStorage.getItem('AUTH_TOKEN');
                const { customeHeaders, excludeAuth, isMultiPartFormData } = operation.getContext();

                const headers = {
                    pip: ip,
                    plp: appName,
                    ...customeHeaders,
                };

                if (!excludeAuth) {
                    headers.Authorization = token ? `Bearer ${token}` : '';
                }

                if (isMultiPartFormData) {
                    headers['Content-Type'] = 'multipart/form-data';
                }

                operation.setContext({
                    headers,
                });

                const subscriber = forward(operation).subscribe({
                    next: result => observer.next(result),
                    error: error => observer.error(error),
                    complete: () => observer.complete(),
                });

                return () => {
                    if (subscriber) subscriber.unsubscribe();
                };
            } catch (error) {
                observer.error(error);
            }
        })();
    });
});

// Use createUploadLink to handle multipart/form-data
const uploadLink: any = createUploadLink({
    uri: `${serverUrl}/graphql`,
});

const apolloClient = new ApolloClient({
    link: ApolloLink.from([dynamicHeaderLink, uploadLink]), // Use uploadLink instead of HttpLink
    cache: new InMemoryCache(),
});


export default apolloClient;
