// React Imports
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Material UI Imports
import { CheckIcon, KeyboardArrowDownIcon, KeyboardArrowUpIcon, FavoriteIcon, FavoriteBorderIcon, ArrowCircleRightIcon, FormGroup, FormControl, TextField, Checkbox, Radio, RadioGroup, Divider, AccordionDetails, AccordionSummary, Accordion, MenuItem, Menu, IconButton, Button, LoadingButton, Box, Typography, Collapse, Rating, Grid, AddIcon, RemoveIcon } from '../../../shared/MuiImports'

//custom style
import staticIcons from "../../../shared/ImageHelper";
import RWMStyles from "./RestaurantWithMenu.style";

// Custom component
import CommanStyles from "../../../shared/CommonStyle";
import ColorsHelper from "../../../shared/ColorHelper";
import FontsHelper from "../../../shared/FontsHelper";

//Sample Image
import PizzaRectangle from '../../../Assets/images/PizzaRectangle.png'
import CIcon from "../../../UI/CIcon/CIcon";
import SearchRestaurantMenu from "../SearchRestaurantMenu/SearchRestaurantMenu";
import { RouteName } from "../../../constants/routeNameConstant";
import { MAIN_CATEGORIES } from "../../../constants/DefaultValues";
import ResizableDiv from "../../../UI/ResizableDiv/ResizableDiv";

// Lazy loading component
const SwipableDrawer = React.lazy(() => import("../../../UI/SwipableDrawer/SwipableDrawer"));
const RestaurantDetails = React.lazy(() => import("../RestaurantDetails/RestaurantDetails"))


const RestaurantWithMenu = (props: any) => {
  const defaultProdDet = {
    customisation: null,
    toppings: [],
    addOnProduct: [],
    crustOption: null,
    instruction: "",
  };
  const boxRef = React.useRef(null);

  const catLocation = useLocation()
  const navigate = useNavigate()

  // states
  const [inView, setInView] = React.useState(true);
  const [noOfProduct, setNoOfProduct] = React.useState(0);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLButtonElement | HTMLDivElement>(null);
  const [openProductView, setOpenProductView] = React.useState<{
    open: boolean;
    isDetailedView: boolean;
    data: any;
  }>({ open: false, isDetailedView: false, data: null });
  const [qty, setQty] = React.useState(1);
  const [otherProdDetails, setOtherProductDetails] = React.useState<any>(defaultProdDet);
  const [addOnProShow, setAddOnProShow] = React.useState(2);
  const [toppingsShow, setToppingsShow] = React.useState(2);
  const [openTopping, setOpenTopping] = React.useState(-1);
  const [openCustmPro, setOpenCustmPro] = React.useState<{
    open: boolean;
    data: any;
  }>({ open: false, data: null });
  const [open, setOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [readMoreIds, setReadMoreIds] = React.useState<any>([]);
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>(
    {}
  );
  const [isFilled, setIsFilled] = useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<any>({});

  const [activeSorting, setActiveSorting] = React.useState("");
  const [expanded, setExpanded] = React.useState<number>(2);
  const [activeCat, setActiveCat] = React.useState<number | string>(1);
  const [loading, setLoading] = React.useState(false);
  const [openMenuSearch, setOpenMenuSearch] = useState(false)

  const [category, setCategory] = React.useState(MAIN_CATEGORIES);

  const [filters, setFilters] = React.useState([
    { title: "Veg", isActive: false, icon: staticIcons.Veg },
    { title: "Non-Veg", isActive: false, icon: staticIcons.NonVeg },
    { title: "Bestseller", isActive: false, icon: staticIcons.BestSeller },
    { title: "Spicy", isActive: false, icon: staticIcons.Spicy },
    { title: "Top-rated", isActive: false, icon: staticIcons.TopRated },
  ]);

  const sortings = [
    { title: "Price - low to high" },
    { title: "Price - high to low" },
    { title: "Rating - high to low" },
  ];

  const paired = [
    {
      image:
        "https://www.kingarthurbaking.com/sites/default/files/styles/featured_image/public/2020-03/super-fast-thin-crust-pizza.jpg?itok=u3Xodz0C",
      name: "Thin crust pizza",
      price: 12,
    },
    {
      image:
        "https://www.yumcurry.com/wp-content/uploads/2020/06/pizza-margherita-recipe.jpg",
      name: "Margherita pizza",
      price: 16,
    },
    {
      image:
        "https://i.pinimg.com/originals/70/2a/e9/702ae99c22464810abaed6a920c8882a.jpg",
      name: "7 Cheesy",
      price: 20,
    },
    {
      image:
        "https://www.chefkunalkapur.com/wp-content/uploads/2022/02/choco-lava-cake-eggelss-2-scaled.jpg?v=1644716501",
      name: "Choco Lava Cake",
      price: 8,
    },
    {
      image:
        "https://spicecravings.com/wp-content/uploads/2021/09/Air-Fryer-Garlic-Bread-Featured.jpg",
      name: "Garlic Bread",
      price: 10,
    },
    {
      image:
        "https://images.themodernproper.com/billowy-turkey/production/posts/2022/Homemade-French-Fries_8.jpg?w=960&h=960&q=82&fm=jpg&fit=crop&dm=1662474181&s=f6b09b96f732330eca2aae43140b3ffa",
      name: "Salted French Fries",
      price: 5,
    },
  ];

  const [offers, setOffers] = React.useState([
    {
      id: 1,
      title: "Free Delivery",
      desc: "exclusively for you",
      icon: staticIcons.Delivery,
    },
    {
      id: 1,
      title: "10% OFF up to $15",
      desc: "Use code DIGISMART to avail this offer",
      icon: staticIcons.Offer,
    },
    {
      id: 1,
      title: "Up to $15 cashback",
      desc: "Use code AMAZONPAY150 | above $20",
      icon: staticIcons.Offer,
    },
    {
      id: 1,
      title: "50% OFF up to $10",
      desc: "Use code WELCOME50 | above $20",
      icon: staticIcons.Offer,
    },
  ]);

  // const [stores, setStores] = React.useState([
  //   {
  //     id: "1",
  //     name: "943 1st Ave., New York, NY 10022, United States",
  //     rating: "4.0",
  //     minute: "30-35 min",
  //     km: "2 Km",
  //     price: "200",
  //     offer: "Buy 2 Get 1",
  //     img: "https://lh3.googleusercontent.com/p/AF1QipPPB3Efaex4dilkn-GXMCMYYxW_T5f6YNDIKG2-=s680-w680-h510",
  //     is_active: true,
  //   },
  //   {
  //     id: "2",
  //     name: "1396 1st Ave., New York, NY 10021, United States",
  //     rating: "3.9",
  //     minute: "35-40 min",
  //     km: "5 Km",
  //     price: "200",
  //     offer: "Buy 2 Get 1",
  //     img: "https://lh3.googleusercontent.com/p/AF1QipODGzIFL9w-2BBMsP4--9c3NxqqywMLy_Ph1MQB=s680-w680-h510",
  //     is_active: true,
  //   },
  //   {
  //     id: "3",
  //     name: "45 Catherine St, New York, NY 10038, United States",
  //     rating: "3.9",
  //     minute: "35-40 min",
  //     km: "7 Km",
  //     price: "200",
  //     offer: "Buy 2 Get 1",
  //     img: "https://lh3.googleusercontent.com/p/AF1QipPtyuiE5bBMhyTnSoex0Y8aZVUXjdlft8Frlpex=s680-w680-h510",
  //     is_active: true,
  //   },
  //   {
  //     id: "1",
  //     name: "181 Church St, New York, NY 10013, United States",
  //     rating: "4.0",
  //     minute: "45-50 min",
  //     km: "8 Km",
  //     price: "200",
  //     offer: "Buy 2 Get 1",
  //     img: "https://lh3.googleusercontent.com/p/AF1QipOssftz6PY35Etx-GP9xVPC7_UDppFqaApZPT5I=s680-w680-h510",
  //     is_active: true,
  //   },
  // ]);



  // Function to handle "Read more" and "Read less"
  const handleReadToggle = (pIndex: number, event: React.MouseEvent) => {
    event.stopPropagation();
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [pIndex]: !prevExpandedItems[pIndex],
    }));
  };

  const getDescriptionSliceLength = () => {
    return window.innerWidth >= 768 ? 90 : 55;
  };

  const descriptionSliceLength = getDescriptionSliceLength();

  const catRef = React.useRef<any>([]);

  const executeScroll = (id: number) => {
    if (catRef?.current)
      catRef?.current[id]?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const resetData = () => {
    setOpenProductView({ open: false, isDetailedView: false, data: null });
    setQty(1);
    setOtherProductDetails(defaultProdDet);
    setAddOnProShow(2);
    setToppingsShow(2);
    setOpenTopping(-1);
  };

  React.useEffect(() => {
    if (category && category?.length > 0) {
      catRef.current = category?.map((cat: any) => {
        return (catRef.current[Number(cat?.id)] = React.createRef<any>());
      });
    }
  }, [category]);

  const handleScroll = () => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0, // Trigger when 0% of the box is in view
    };

    const handleIntersection: any = (entries: any) => {
      entries.forEach((entry: any) => {
        setInView(entry.isIntersecting);
      });
    };

    const observer: any = new IntersectionObserver(handleIntersection, options);

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  };

  React.useEffect(() => {
    let products = 0;
    Object.keys(selectedProduct).forEach((key) => {
      products += selectedProduct[key]?.qty;
    });
    if (products !== noOfProduct) {
      setNoOfProduct(products);
    }
  }, []);

  const getPrice = (isAddNew = false, isCustom = false) => {
    let price = 0;
    if (isCustom) {
      price += otherProdDetails?.customisation?.value ?? 0;
      price += otherProdDetails?.crustOption?.value ?? 0;
      // toppings: [],
      // addOnProduct: [],
      // crustOption: null,
      // instruction: "",
    }
    if (isAddNew) {
    } else {
    }
    return price;
  };

  const applyItemCountAnimation = (type: number) => {
    const itemCount = document.getElementById("itemCount");
    let offerTxt: any = null;
    if (noOfProduct <= 3) offerTxt = document.getElementById("offerTxt");
    const animationClass = type === 1 ? "slideUp" : "slideDown";
    itemCount?.classList?.add(animationClass);
    if (noOfProduct <= 3) offerTxt?.classList?.add(animationClass);
    setTimeout(() => {
      itemCount?.classList?.remove(animationClass);
      if (noOfProduct <= 3) offerTxt?.classList?.remove(animationClass);
    }, 1000);
  };

  const handleIconClick = () => {
    setIsFilled(!isFilled);
  };

  const handleSearchClick = () => {
    setOpenMenuSearch(true)
  }

  return (
    <Box sx={{ position: 'relative', bgcolor: ColorsHelper.backColor }}>

      {/* Restaurant Details */}
      <RestaurantDetails
        {...props}
        {...{ offers }}
      />

      {/* {catLocation.pathname === '/home' && ( */}
      <>
        <Box sx={{
          backgroundColor: ColorsHelper.whiteClr,
          height: "3rem",
          overflowX: "auto",
          display: 'flex',
          alignItems: "center",
          px: "0.25rem",
          mb: "0.1rem",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}>
          <IconButton
            sx={{
              p: "0.3rem 0.5rem",
              mx: "0.25rem",
              border: "1px solid #e2dede",
              borderRadius: "0.5rem",
              whiteSpace: "nowrap",
              display: "flex",
              img: {
                width: "1rem",
                height: "1rem",
                marginRight: "0.4rem",
              },
              "& .downArrow": {
                width: "0.65rem",
                height: "0.65rem",
                marginLeft: "0.4rem",
                marginRight: "0rem",
              },
              "& .filterIcon": {
                width: "0.8rem",
                height: "0.65rem",
                marginRight: "0.4rem",
              },
            }}
            onClick={() => {
              setFilterOpen(true);
            }}
          >
            <img
              src={staticIcons.Filter}
              alt={"filter"}
              className={"filterIcon"}
            />
            <Typography sx={{
              ...FontsHelper.font400,
              fontSize: "0.75rem",
              color: ColorsHelper.blackClr,
            }}>Filters</Typography>
            <img
              src={staticIcons.DownFillArrow}
              alt={"arrow"}
              className={"downArrow"}
            />
          </IconButton>
          {filters?.map((filter: any, index: number) => (
            <IconButton
              key={index}
              sx={[
                {
                  p: "0.3rem 0.5rem",
                  mx: "0.25rem",
                  border: "1px solid #e2dede",
                  borderRadius: "0.5rem",
                  whiteSpace: "nowrap",
                  display: "flex",
                  img: {
                    width: "1rem",
                    height: "1rem",
                    marginRight: "0.4rem",
                  },
                  "& .downArrow": {
                    width: "0.65rem",
                    height: "0.65rem",
                    marginLeft: "0.4rem",
                    marginRight: "0rem",
                  },
                  "& .filterIcon": {
                    width: "0.8rem",
                    height: "0.65rem",
                    marginRight: "0.4rem",
                  },
                },
                filter?.isActive && {
                  backgroundColor: `${ColorsHelper.mainColor}12 !important`,
                  border: `1px solid ${ColorsHelper.mainColor}`,
                },
              ]}
              onClick={() => {
                setFilters((prevData: any) =>
                  prevData.map((item: any) =>
                    item?.title === filter?.title
                      ? { ...item, isActive: !item?.isActive }
                      : item
                  )
                );
              }}
            >
              <img src={filter?.icon} alt={"filter"} />
              <Typography
                sx={{
                  ...FontsHelper.font400,
                  fontSize: filter?.isActive ? "0.75rem" : "0.8rem",
                  color: ColorsHelper.blackClr,
                }}
              >
                {filter?.title}
              </Typography>
            </IconButton>
          ))}
        </Box>

        {/* Filter And Sorting Drawer */}
        <React.Suspense fallback={<></>}>
          <SwipableDrawer
            open={filterOpen}
            setOpen={setFilterOpen}
            bgColor={"#ffffff"}
            drawerHeight={"80%"}
            content={
              <>
                <Box sx={{
                  // width: "100%",
                  background: ColorsHelper.whiteClr,
                  padding: "0.75rem 0.5rem 0.75rem 0.5rem",
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}>
                  <Typography sx={{
                    ...FontsHelper.font500,
                    fontSize: "1.2rem",
                    wordBreak: "break-word",
                  }}>
                    {"Filters and Sorting"}
                  </Typography>
                </Box>
                <Box sx={{
                  background: ColorsHelper.grey30,
                  padding: "0.5rem",
                  overflow: "auto",
                  height: "calc(100% - 7rem)",
                }}>
                  <Box sx={{
                    borderRadius: "0.5rem",
                    background: ColorsHelper.whiteClr,
                    padding: "0.75rem 0.5rem",
                  }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography sx={{
                          ...FontsHelper.font500,
                          fontSize: "1rem",
                          wordBreak: "break-word",
                          mb: "0.5rem",
                        }}>
                          {"Filter by"}
                        </Typography>
                      </Grid>
                      {filters?.map((filter: any, index: number) => (
                        <Grid item key={index}>
                          <IconButton
                            sx={[
                              {
                                p: "0.3rem 0.5rem",
                                mx: "0.25rem",
                                border: "1px solid #e2dede",
                                borderRadius: "0.5rem",
                                whiteSpace: "nowrap",
                                display: "flex",
                                img: {
                                  width: "1rem",
                                  height: "1rem",
                                  marginRight: "0.4rem",
                                },
                                "& .downArrow": {
                                  width: "0.65rem",
                                  height: "0.65rem",
                                  marginLeft: "0.4rem",
                                  marginRight: "0rem",
                                },
                                "& .filterIcon": {
                                  width: "0.8rem",
                                  height: "0.65rem",
                                  marginRight: "0.4rem",
                                },
                              },
                              filter?.isActive && {
                                backgroundColor: `${ColorsHelper.mainColor}12 !important`,
                                border: `1px solid ${ColorsHelper.mainColor}`,
                              },
                            ]}
                            onClick={() => {
                              setFilters((prevData: any) =>
                                prevData.map((item: any) =>
                                  item?.title === filter?.title
                                    ? { ...item, isActive: !item?.isActive }
                                    : item
                                )
                              );
                            }}
                          >
                            <Typography
                              sx={{
                                ...FontsHelper.font400,
                                fontSize: filter?.isActive ? "0.75rem" : "0.8rem",
                                color: ColorsHelper.blackClr,
                              }}
                            >
                              {filter?.title}
                            </Typography>
                          </IconButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box sx={{
                    borderRadius: "0.5rem",
                    background: ColorsHelper.whiteClr,
                    padding: "0.75rem 0.5rem", mt: "0.5rem"
                  }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography sx={{
                          ...FontsHelper.font500,
                          fontSize: "1rem",
                          wordBreak: "break-word",
                          mb: "0.5rem",
                        }}>
                          {"Sort by"}
                        </Typography>
                      </Grid>
                      {sortings?.map((sort: any, index: number) => (
                        <Grid item key={index}>
                          <IconButton
                            sx={[
                              {
                                p: "0.3rem 0.5rem",
                                mx: "0.25rem",
                                border: "1px solid #e2dede",
                                borderRadius: "0.5rem",
                                whiteSpace: "nowrap",
                                display: "flex",
                                img: {
                                  width: "1rem",
                                  height: "1rem",
                                  marginRight: "0.4rem",
                                },
                                "& .downArrow": {
                                  width: "0.65rem",
                                  height: "0.65rem",
                                  marginLeft: "0.4rem",
                                  marginRight: "0rem",
                                },
                                "& .filterIcon": {
                                  width: "0.8rem",
                                  height: "0.65rem",
                                  marginRight: "0.4rem",
                                },
                              },
                              activeSorting === sort?.title && {
                                backgroundColor: `${ColorsHelper.mainColor}12 !important`,
                                border: `1px solid ${ColorsHelper.mainColor}`,
                              },
                            ]}
                            onClick={() => {
                              setActiveSorting(sort?.title);
                            }}
                          >
                            <Typography
                              sx={[{
                                ...FontsHelper.font400,
                                fontSize: "0.75rem",
                                color: ColorsHelper.blackClr
                              },
                              activeSorting === sort?.title && {
                                ...FontsHelper.font500,
                                fontSize: "0.8rem",
                              }
                              ]}
                            >
                              {sort?.title}
                            </Typography>
                          </IconButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
                <Grid container sx={{
                  width: "100%",
                  background: ColorsHelper.whiteClr,
                  height: "3.5rem",
                  position: 'sticky',
                  bottom: 0
                }}>
                  <Grid item xs={6} sx={{
                    ...CommanStyles.centerIt,
                    px: "0.5rem",
                  }}>
                    <Button
                      variant="text"
                      sx={[{
                        ...FontsHelper.font500,
                        fontSize: "1rem",
                        borderRadius: "0.625rem",
                        ...CommanStyles.centerIt,
                        color: ColorsHelper.mainColor,
                        width: "100%",
                        textTransform: "capitalize",
                        maxWidth: "9rem",
                      }, { color: ColorsHelper.mainColor }]}
                      onClick={() => {
                        setFilterOpen(false);
                        setActiveSorting("");
                        setFilters((prevData: any) =>
                          prevData.map((item: any) => ({
                            ...item,
                            isActive: false,
                          }))
                        );
                      }}
                    >
                      Clear All
                    </Button>
                  </Grid>
                  <Grid item xs={6} sx={{
                    ...CommanStyles.centerIt,
                    px: "0.5rem",
                  }}>
                    <Button
                      sx={{
                        ...FontsHelper.font500,
                        fontSize: "1rem",
                        borderRadius: "0.5rem",
                        ...CommanStyles.centerIt,
                        color: ColorsHelper.whiteClr,
                        backgroundColor: ColorsHelper.mainColor,
                        "&:hover": {
                          backgroundColor: ColorsHelper.mainColor,
                        },
                        width: "100%",
                        textTransform: "capitalize",
                        maxWidth: "9rem",
                      }}
                      onClick={() => {
                        setFilterOpen(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </>
            }
          />
        </React.Suspense>
        {!loading && category?.length !== 0 && (
          <>
            <Box sx={{ backgroundColor: ColorsHelper.backColor, pt: "0.2rem" }} >
              {category.map((cat: any, index: number) => (
                <span key={index}>
                  <Accordion sx={RWMStyles.collapseBox} expanded={expanded === Number(cat?.id)}
                    onChange={(
                      event: React.SyntheticEvent,
                      isExpanded: boolean
                    ) => {
                      setExpanded(isExpanded ? Number(cat?.id) : -1);
                    }}
                  >
                    <AccordionSummary expandIcon={<img style={RWMStyles.collapseIcon} src={staticIcons.DownFillArrow} alt={"arrow"} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={RWMStyles.catTitleBx}
                    >
                      <Typography ref={catRef.current[Number(cat?.id)]} sx={{ ...RWMStyles.catTitle, pr: "0.5rem", }}>
                        {cat?.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 1 }}>
                      <>
                        {cat?.items?.map(
                          (product: any, pIndex: number) => (
                            <React.Fragment key={pIndex}>
                              {
                                <>
                                  <Box key={pIndex} sx={[RWMStyles.productListBox]}
                                    onClick={() => {
                                      setOpenProductView({
                                        open: true,
                                        isDetailedView: true,
                                        data: product,
                                      });
                                      setInView(true);
                                      let otherDetails: any = otherProdDetails;
                                      if (
                                        product?.customisation?.length > 0
                                      ) {
                                        otherDetails["customisation"] = product?.customisation[0];
                                      }
                                      setOtherProductDetails(otherDetails);
                                      product?.has_customization && setInView(true);
                                    }}
                                  >
                                    <Grid container sx={[
                                      RWMStyles.prodGridCon,
                                      pIndex === 0 && { pt: "0rem" },
                                      cat?.items?.length - 1 ===
                                      pIndex && {
                                        border: "none",
                                      },
                                    ]}
                                    >
                                      <Grid item sx={RWMStyles.prodDetailsBox} >
                                        <Box sx={RWMStyles.typeCon}>
                                          <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: pIndex % 2 === 0 ? "green" : "red" }} />
                                          {pIndex % 2 === 0 ? (
                                            <Box>Hot spicy</Box>
                                          ) : (
                                            <Box>Best seller</Box>
                                          )}
                                        </Box>
                                        <Typography sx={RWMStyles.prodTitle}>
                                          {product?.name}
                                        </Typography>

                                        <Typography variant="body1" sx={{ ...RWMStyles.prodPrice, pb: 0, }}>
                                          {`$${product?.formatted_price}`}
                                        </Typography>
                                        <Typography variant="body1" sx={{ ...FontsHelper.font400, fontSize: "0.85rem", color: "blue", pb: '0.3rem' }}>
                                          On Buy 2 Get 1
                                        </Typography>
                                        <Typography sx={RWMStyles.prodDesc} >
                                          {`${product?.description &&
                                            product?.description?.length > 0
                                            ? expandedItems[pIndex]
                                              ? product?.description
                                              : product?.description?.slice(0, descriptionSliceLength)
                                            : ""
                                            }`}
                                          {product?.description && product?.description?.length > descriptionSliceLength && (
                                            <>
                                              <span onClick={(e) => handleReadToggle(pIndex, e)}
                                                style={{ color: ColorsHelper.grey31, fontWeight: 700, cursor: "pointer", }} >
                                                {expandedItems[pIndex] ? " read less" : "...read more"}
                                              </span>
                                            </>
                                          )}
                                        </Typography>
                                        <LoadingButton onClick={() => { navigate(RouteName.VIEW_IN_3D) }} variant="contained" size="small" startIcon={<Box component={CIcon} icon={"mage:box-3d-scan"} />} sx={{ fontSize: '0.6rem', textTransform: 'none', borderRadius: '8px', backgroundColor: 'black', color: 'white', mt: 1.5 }} >
                                          View in 3D
                                        </LoadingButton>
                                      </Grid>
                                      <Grid item sx={RWMStyles.imgBox}>
                                        <img style={{ ...RWMStyles.prodImg, objectFit: 'cover' }} src={product?.image} alt="product"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              staticIcons.NoImage;
                                          }}
                                          loading="lazy"
                                        />
                                        <Box sx={RWMStyles.addBtnCon}>
                                          {selectedProduct.hasOwnProperty(product?.id) &&
                                            selectedProduct[product?.id]?.qty > 0 ? (
                                            <Box
                                              sx={[
                                                RWMStyles.addBtnWCount,
                                                {
                                                  backgroundColor:
                                                    ColorsHelper.mainColor,
                                                  "&:hover": {
                                                    backgroundColor:
                                                      ColorsHelper.mainColor,
                                                  },
                                                },
                                              ]}
                                            >
                                              <IconButton
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (product?.customisation?.length > 0) {
                                                    setOpenCustmPro({
                                                      open: true,
                                                      data: product,
                                                    });
                                                  } else {
                                                    setNoOfProduct((prevValue) => prevValue - 1 < 0 ? 0 : prevValue - 1);
                                                    applyItemCountAnimation(2);
                                                    if (selectedProduct[product?.id]?.qty === 1) {
                                                      let newSelectedProduct = selectedProduct;
                                                      delete newSelectedProduct[product?.id];
                                                      setSelectedProduct(newSelectedProduct);
                                                    } else {
                                                      setSelectedProduct((prevValue: any) => ({
                                                        ...prevValue,
                                                        [product?.id]:
                                                          { ...product, qty: prevValue[product?.id]?.qty - 1, },
                                                      })
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                <RemoveIcon
                                                  style={{ fontSize: 22 }}
                                                />
                                              </IconButton>
                                              <span>
                                                {
                                                  selectedProduct[product?.id]?.qty
                                                }
                                              </span>
                                              <IconButton
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (product?.customisation?.length > 0) {
                                                    setOpenCustmPro({
                                                      open: true,
                                                      data: product,
                                                    });
                                                  } else {
                                                    setNoOfProduct((prevValue: any) => prevValue + 1);
                                                    applyItemCountAnimation(1);
                                                    setSelectedProduct((prevValue: any) => ({
                                                      ...prevValue,
                                                      [product?.id]: {
                                                        ...product,
                                                        qty: prevValue[product?.id]?.qty + 1,
                                                      },
                                                    })
                                                    );
                                                  }
                                                }}
                                              >
                                                <AddIcon style={{ fontSize: 22 }} />
                                              </IconButton>
                                            </Box>
                                          ) : (
                                            <Button
                                              sx={[RWMStyles.addBtn,
                                              {
                                                border: `1px solid ${ColorsHelper.mainColor} !important`,
                                                color: ColorsHelper.mainColor,
                                                svg: {
                                                  color: ColorsHelper.mainColor,
                                                },
                                              },
                                              ]}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                if (product?.customisation?.length > 0) {
                                                  setOpenProductView({
                                                    open: true,
                                                    isDetailedView: false,
                                                    data: product,
                                                  });
                                                  setInView(false);
                                                  let otherDetails: any =
                                                    otherProdDetails;
                                                  if (product?.customisation?.length > 0) {
                                                    otherDetails["customisation"] = product?.customisation[0];
                                                  }
                                                  setOtherProductDetails(
                                                    otherDetails
                                                  );
                                                } else {
                                                  setNoOfProduct(
                                                    (prevValue: any) =>
                                                      prevValue + 1
                                                  );
                                                  applyItemCountAnimation(
                                                    1
                                                  );
                                                  setSelectedProduct(
                                                    (prevValue: any) => ({
                                                      ...prevValue,
                                                      [product?.id]: {
                                                        ...product,
                                                        qty: 1,
                                                      },
                                                    })
                                                  );
                                                }
                                              }}
                                            >
                                              Add
                                              <AddIcon style={{ height: "0.9rem", }} />
                                            </Button>
                                          )}
                                          {product?.has_customization && (
                                            <Typography
                                              sx={RWMStyles.custmText}
                                            >
                                              {"customisable"}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  {
                                    selectedProduct.hasOwnProperty(product?.id) && selectedProduct[product?.id]?.qty > 0 && (<Box></Box>
                                      // <Box sx={{ mt: "0.625rem", p: ".6rem", bgcolor:ColorsHelper.bgWhiteClrO95, position: "relative",}}>
                                      //   <Box
                                      //     sx={{
                                      //       position: "absolute",
                                      //       content: "''",
                                      //       width: "20px",
                                      //       height: "20px",
                                      //       backgroundColor:
                                      //         ColorsHelper.bgWhiteClrO95,
                                      //       top: "-20px",
                                      //       right: "4.375rem",
                                      //       clipPath:
                                      //         "polygon(50% 0%, 0% 100%, 100% 100%)",
                                      //     }}
                                      //   />
                                      //   <Typography sx={{ ...RWMStyles.prodTitle, mb: 1, fontSize: "1.2rem !important",}} >
                                      //     Best paired with
                                      //   </Typography>
                                      //   <Box
                                      //     sx={{
                                      //       ...CommanStyles.dflex,
                                      //       overflowX: "auto",
                                      //       "&::-webkit-scrollbar": {
                                      //         display: "none",
                                      //       },
                                      //     }}
                                      //   >
                                      //     {paired.map(
                                      //       (item: any, i: number) => (
                                      //         <Card
                                      //           key={i}
                                      //           sx={{
                                      //             minWidth: 180,
                                      //             pb: 0,
                                      //             borderRadius:
                                      //               "0.625rem",
                                      //             ...CommanStyles.flexColunmContainer,
                                      //             justifyContent:
                                      //               "space-between",
                                      //             m: "0.5rem 0.30rem",
                                      //           }}
                                      //         >
                                      //           <CardContent
                                      //             sx={{ p: 0.6 }}
                                      //           >
                                      //             <Box
                                      //               component={"img"}
                                      //               src={item?.image}
                                      //               sx={{
                                      //                 borderRadius:
                                      //                   ".5rem",
                                      //                 height: "7rem",
                                      //                 width: "100%",
                                      //               }}
                                      //             />
                                      //             <Typography
                                      //               sx={
                                      //                 RWMStyles.pairedTitle
                                      //               }
                                      //             >
                                      //               {item?.name}
                                      //             </Typography>
                                      //           </CardContent>
                                      //           <CardActions>
                                      //             <Box
                                      //               sx={{
                                      //                 width: "100%",
                                      //                 display: "flex",
                                      //                 justifyContent:
                                      //                   "space-between",
                                      //               }}
                                      //             >
                                      //               <Typography
                                      //                 sx={
                                      //                   RWMStyles.pairedTitle
                                      //                 }
                                      //               >
                                      //                 ${item?.price}
                                      //               </Typography>
                                      //               <Box
                                      //                 sx={{
                                      //                   display: "flex",
                                      //                   flexDirection:
                                      //                     "column",
                                      //                   alignItems:
                                      //                     "center",
                                      //                 }}
                                      //               >
                                      //                 <Button
                                      //                   sx={[
                                      //                     RWMStyles.addBtn,
                                      //                     {
                                      //                       border: `1px solid ${ColorsHelper.mainColor} !important`,
                                      //                       color:
                                      //                         ColorsHelper.mainColor,
                                      //                       svg: {
                                      //                         color:
                                      //                           ColorsHelper.mainColor,
                                      //                       },
                                      //                       width: "5rem",
                                      //                       minWidth:
                                      //                         "1rem !important",
                                      //                       height:
                                      //                         "1.8rem",
                                      //                       p: 0,
                                      //                       fontSize:
                                      //                         "0.8rem",
                                      //                     },
                                      //                   ]}
                                      //                 >
                                      //                   Add <AddIcon />
                                      //                 </Button>

                                      //                 {/* <Typography
                                      //             sx={{
                                      //               ...RWMStyles.custmText,
                                      //               fontSize: "0.6rem",
                                      //             }}
                                      //           >
                                      //             {"Customisable"}
                                      //           </Typography> */}
                                      //               </Box>
                                      //             </Box>
                                      //           </CardActions>
                                      //         </Card>
                                      //       )
                                      //     )}
                                      //   </Box>
                                      // </Box>
                                    )
                                  }
                                </>
                              }
                            </React.Fragment>
                          )
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </span>
              ))}
            </Box>
          </>
        )}
      </>
      {/* )} */}


      {/* {catLocation.pathname === '/home' && ( */}
      {/* Search Section */}
      <Box
        sx={{
          maxWidth: '438px',
          width: '100dvw',
          bgcolor: ColorsHelper.whiteClr,
          position: "fixed",
          bottom: 0,
          py: 2,
          zIndex: 999,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          boxShadow: '0px 0px 24px 0px #00000026',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, px: 1 }}>
            {/* Search Box */}
            <Box sx={{ width: '100%' }}>
              <Box onClick={handleSearchClick} sx={{ height: '2rem', width: '100%', borderRadius: '8px', display: 'flex', alignItems: 'center', gap: 2, bgcolor: ColorsHelper.backColor, pl: 1, cursor: 'pointer', userSelect: 'none' }}>
                <Box component={CIcon} icon={"gg:search"} sx={{ color: ColorsHelper.mainColor }} />
                <Typography sx={{ color: ColorsHelper.textColorLighter, fontSize: '0.9rem' }}>Search in Restro Beast</Typography>
              </Box>
            </Box>
            <Box>
              <Button
                sx={{ bgcolor: ColorsHelper.grayDark, color: ColorsHelper.whiteClr, borderRadius: '8px' }}
                onClick={(e) => setAnchorElMenu(e.currentTarget)}
                startIcon={<Box component={CIcon} icon={"akar-icons:three-line-horizontal"} />}
              >
                Menu
              </Button>
            </Box>
            <Menu
              anchorEl={anchorElMenu}
              open={Boolean(anchorElMenu)}
              sx={{ ...RWMStyles.menuPopup, zIndex: 1305 }}
              anchorOrigin={{
                vertical: -50,
                horizontal: 70,
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              onClose={() => {
                setAnchorElMenu(null);
              }}
            >
              {/* Default Heading */}
              
              <MenuItem
                sx={[
                  RWMStyles.menuItem, {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: ColorsHelper.mainColor,
                    "&.Mui-disabled": {
                      color: ColorsHelper.mainColor, // Override disabled state color
                    },
                  }
                ]}
              >
                <span>Your Orders and Collections</span>
                <Box component={CIcon} icon={"ic:round-close"} onClick={() => setAnchorElMenu(null)} sx={{ color: ColorsHelper.blackClr }} />
              </MenuItem>
              {category?.length > 0 &&
                category.map((cat: any, cIndex: number) => (
                  <MenuItem
                    key={cIndex}
                    onClick={() => {
                      setActiveCat(Number(cat?.id));
                      executeScroll(Number(cat?.id));
                      setAnchorElMenu(null);
                      setTimeout(() => {
                        setExpanded(Number(cat?.id));
                      }, 500);
                    }}
                    sx={[
                      RWMStyles.menuItem,
                      activeCat === Number(cat?.id) && {
                        color: ColorsHelper.mainColor,
                        fontWeight: 700,
                      },
                    ]}
                  >
                    <span style={RWMStyles.menuNameT}>{cat?.name}</span>
                    <span>20</span>
                  </MenuItem>
                ))}

              {/* Close Button */}
              {/* <Box
                    sx={{
                      position: 'sticky',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      // padding: '10px',
                      bottom: 0,
                      background: ColorsHelper.whiteClr,
                    }}
                  >
                    <Button
                      sx={{
                        position: 'fixed',
                        bottom: '1.2rem',
                        right: '1.2rem',
                        bgcolor: ColorsHelper.grayDark,
                        color: ColorsHelper.whiteClr,
                        borderRadius: '8px',
                        zIndex: 1310, // Ensures it appears above most elements
                      }}
                      onClick={() => setAnchorElMenu(null)}
                      startIcon={<Box component={CIcon} icon={'akar-icons:cross'} />}
                    >
                      Close
                    </Button>
                  </Box> */}
            </Menu>
          </Box>

          {/* restaurant Search Drawer */}
          <SearchRestaurantMenu openMenuSearch={openMenuSearch} setOpenMenuSearch={setOpenMenuSearch} />

          <Collapse in={noOfProduct > 0} timeout="auto">
            <Button
              onClick={() => {
                navigate(RouteName.CART)
              }}
              sx={[
                RWMStyles.selItemCon,
                {
                  boxShadow: '1px -1px 4px 0px #00000033',
                  "&:hover": { backgroundColor: ColorsHelper.mainColor },
                  pb: "1rem",
                },
              ]}
            >
              <Box>
                <span id="itemCount" className="itemsTxt animateTxt">
                  <span className="itemCountTxt">{noOfProduct}</span>
                  {`${noOfProduct > 1 ? "items" : "item"} added`}
                </span>
                <ArrowCircleRightIcon />
              </Box>
              {/* <span id="offerTxt" className="itemsTxt offerTxtC animateTxt">
                    <span className="congTxt">
                      {noOfProduct === 1
                        ? "Add items worth $20 more to get Flat $5 OFF"
                        : noOfProduct === 2
                          ? "Add items worth $30 more to get Flat $15 OFF"
                          : noOfProduct === 3
                            ? "You can apply SPECIAL125 to get flat $25 OFF"
                            : "Congratulations! Your delivery is free."}
                    </span>
                  </span> */}
            </Button>
          </Collapse>
        </Box>
      </Box>

      <React.Suspense fallback={<></>}>
        <Box sx={{ mb: 9 }}>
          <SwipableDrawer
            open={openProductView?.open}
            setOpen={(value: boolean) => { resetData(); }}
            onClose={() => { }}
            drawerHeight={"80%"}
            content={
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                  }}
                >
                  {openProductView?.data?.has_customization === true &&
                    inView === false && (
                      <Collapse in={!inView}>
                        <Box
                          sx={{
                            height: "5rem",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <Box sx={RWMStyles.custmProdViewHead}>
                            <Grid container>
                                  <Grid
                                    item
                                    sx={{ width: "4rem", pr: "0.5rem" }}
                                  >
                                <img
                                  style={RWMStyles.smViewProdImg}
                                  src={openProductView?.data?.image}
                                  alt="product"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                        currentTarget.src =
                                          staticIcons.NoImage;
                                  }}
                                  loading="lazy"
                                />
                              </Grid>

                              <Grid
                                item
                                sx={{
                                  ...RWMStyles.viewPCustLeft,
                                  justifyContent: "center",
                                }}
                              >
                                <Typography sx={RWMStyles.prodTitle}>
                                  {openProductView?.data?.name}
                                </Typography>
                                <Box sx={RWMStyles.typeCon}>
                                  <img src={staticIcons.Veg} alt={"veg"} />
                                  {/* <Box>Hot spicy</Box> */}
                                  <Box>Bestseller</Box>
                                </Box>
                              </Grid>
                              <Grid item sx={RWMStyles.viewPRight}>
                                {/* <IconButton
                                  sx={RWMStyles.iconBtn}
                                  onClick={handleIconClick}
                                >
                                  {!isFilled ? (
                                    <FavoriteBorderIcon
                                      sx={{
                                        color: ColorsHelper.mainColor,
                                        fontSize: "1.2rem",
                                      }}
                                    />
                                  ) : (
                                    <FavoriteIcon
                                      sx={{
                                        color: ColorsHelper.mainColor,
                                        fontSize: "1.2rem",
                                      }}
                                    />
                                  )}
                                </IconButton> */}
                                <IconButton sx={RWMStyles.iconBtn}>
                                  <Box component={CIcon} icon={"uil:share"} sx={{ color: ColorsHelper.mainColor, fontSize: '1.2rem' }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Collapse>
                    )}
                  <Box
                    sx={{
                      flex: 1,
                      overflowY: "auto",
                      pb: { xs: "12rem", sm: "8rem" },
                    }}
                    onScroll={handleScroll}
                  >
                    <Box sx={[RWMStyles.contentCon]}>
                      {openProductView?.isDetailedView && (
                        <Box
                          ref={boxRef}
                          sx={[
                            RWMStyles.contentBox,
                            { padding: "0.5rem" },
                          ]}
                        >
                          <Box sx={{ position: 'relative' }}>
                            <img
                              style={RWMStyles.viewProdImg}
                              src={openProductView?.data?.image}
                              alt="product"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = staticIcons.NoImage;
                              }}
                              loading="lazy"
                            />
                            <IconButton
                              sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                zIndex: 10,
                                bgcolor: ColorsHelper.blackClr,

                              }}
                              onClick={() => { /* handle close logic here */ }}
                            >
                              <Box component={CIcon} icon={"mage:box-3d-scan"} sx={{ color: ColorsHelper.whiteClr, fontSize: '0.9rem' }} />
                            </IconButton>
                          </Box>
                          <Box sx={RWMStyles.typeCon}>
                            <img src={staticIcons.Veg} alt={"veg"} />
                            {/* <Box>Hot spicy</Box> */}
                            <Box>Bestseller</Box>
                          </Box>
                          <Grid
                            container
                            sx={{ alignItems: "flex-start" }}
                          >
                            <Grid item sx={RWMStyles.viewPLeft}>
                              <Typography
                                sx={[
                                  RWMStyles.prodTitle,
                                  { fontSize: "1rem" },
                                ]}
                              >
                                {openProductView?.data?.name}
                              </Typography>

                            </Grid>
                            <Grid item sx={RWMStyles.viewPRight}>
                              {/* <IconButton
                                onClick={handleIconClick}
                                sx={{
                                  border: `1px solid ${ColorsHelper.grey12}`,
                                  mx: "0.25rem",
                                  height: "2rem",
                                  width: "2rem",
                                }}
                              >
                                {!isFilled ? (
                                  <FavoriteBorderIcon
                                    sx={{
                                      color: ColorsHelper.mainColor,
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                ) : (
                                  <FavoriteIcon
                                    sx={{
                                      color: ColorsHelper.mainColor,
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                )}
                              </IconButton> */}
                              <IconButton
                                sx={{
                                  border: `1px solid ${ColorsHelper.grey12}`,
                                  mx: "0.25rem",
                                  height: "2rem",
                                  width: "2rem",
                                  p: "0.1rem",
                                  color: ColorsHelper.mainColor
                                }}
                              >
                                <Box component={CIcon} icon={"uil:share"} sx={{ color: ColorsHelper.mainColor, fontSize: '1.2rem' }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Typography
                            sx={[
                              RWMStyles.descText,
                              { mt: "0.625rem", mb: "1rem" },
                            ]}
                          >
                            {openProductView?.data?.description}
                          </Typography>

                          {/* 3D View Button */}
                          <LoadingButton onClick={() => { navigate(RouteName.VIEW_IN_3D) }} variant="contained" size="small" startIcon={<Box component={CIcon} icon={"mage:box-3d-scan"} />} sx={{ fontSize: '0.6rem', textTransform: 'none', borderRadius: '8px', backgroundColor: 'black', color: 'white' }} >
                            View in 3D
                          </LoadingButton>
                        </Box>
                      )}
                      {openProductView?.data?.customisation?.length >
                        0 && (
                          <Box
                            sx={{
                              ...RWMStyles.contentBox,
                              my: "1rem !important",
                            }}
                          >
                            <Box
                              sx={{
                                p: "0.8rem 0.6rem",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...FontsHelper.font600,
                                    fontSize: "0.9rem",
                                    mb: ".2rem",
                                  }}
                                >
                                  Customisation
                                </Typography>
                                <Typography sx={RWMStyles.descText}>
                                  Select any 1 option
                                </Typography>
                              </Box>
                              {/* <Box sx={{ mt: "0.5rem" }}>
                                <Typography sx={RWMStyles.requiredBx}>
                                  REQUIRED
                                </Typography>
                              </Box> */}
                            </Box>
                            <Divider sx={{ width: "100%" }} />
                            <FormGroup>
                              <FormControl>
                                <RadioGroup
                                  sx={[
                                    RWMStyles.radioBtns,
                                    {
                                      "& .Mui-checked": {
                                        color: ColorsHelper.mainColor + " !important",
                                      },
                                    },
                                  ]}
                                  value={
                                    otherProdDetails["customisation"]?.id
                                  }
                                  onChange={(event) => {
                                    setOtherProductDetails({
                                      ...otherProdDetails,
                                      customisation:
                                        openProductView?.data?.customisation?.filter(
                                          (item: any) =>
                                            item?.id === event.target.value
                                        )[0],
                                    });
                                  }}
                                >
                                  <Grid container>
                                    {openProductView?.data?.customisation?.map(
                                      (cust: any, pvIndex: number) => (
                                        <React.Fragment key={pvIndex}>
                                          <Grid
                                            item
                                            sx={[RWMStyles.viewCustLeft]}
                                          >
                                            <Typography
                                              sx={[
                                                RWMStyles.subTitle,
                                                {
                                                  fontWeight:
                                                    otherProdDetails[
                                                      "customisation"
                                                    ]?.id === cust?.id
                                                      ? 700
                                                      : 500,
                                                  fontSize:
                                                    "0.8rem !important",
                                                },
                                              ]}
                                            >
                                              {cust?.label}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            sx={RWMStyles.viewPRight}
                                          >
                                            <Typography
                                              sx={[
                                                RWMStyles.subTitle,
                                                {
                                                  fontWeight:
                                                    otherProdDetails[
                                                      "customisation"
                                                    ]?.id === cust?.id
                                                      ? 700
                                                      : 500,
                                                },
                                              ]}
                                            >{`$${cust?.value}`}</Typography>
                                            <Radio
                                              value={cust?.id}
                                              sx={{
                                                pl: "0.2rem !important",
                                                color: ColorsHelper.gray36,
                                              }}
                                            />
                                          </Grid>
                                        </React.Fragment>
                                      )
                                    )}
                                  </Grid>
                                </RadioGroup>
                              </FormControl>
                            </FormGroup>
                          </Box>
                        )}
                      {openProductView?.data?.toppings?.length > 0 && (
                        <Box sx={RWMStyles.contentBox}>
                          <Box sx={{ p: "0.5rem" }}>
                            <Typography
                              sx={{
                                ...FontsHelper.font500,
                                fontSize: "0.85rem",
                                mb: "0.2rem",
                              }}
                            >{`Toppings - Veg (Giant Slice),(Olivers)`}</Typography>
                            <Typography sx={RWMStyles.descText}>
                              Select up to 1 option
                            </Typography>
                          </Box>
                          <Divider sx={{ width: "100%" }} />
                          <Box
                            sx={[
                              RWMStyles.radioBtns,
                              {
                                "& .Mui-checked": {
                                  color: ColorsHelper.mainColor + " !important",
                                },
                              },
                            ]}
                          >
                            {openProductView?.data?.toppings
                              .slice(0, toppingsShow)
                              ?.map((topping: any, tIndex: number) => (
                                <Grid
                                  key={tIndex}
                                  container
                                  sx={RWMStyles.toppingsCon}
                                >
                                  <Grid item sx={[RWMStyles.toppingLeft]}>
                                    <Typography
                                      sx={[
                                        RWMStyles.subTitle,
                                        {
                                          fontWeight: otherProdDetails[
                                            "toppings"
                                          ].includes(topping?.id)
                                            ? 600
                                            : 500,
                                        },
                                      ]}
                                    >
                                      {topping?.label}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    sx={[
                                      RWMStyles.toppingRight,
                                      { pr: 0 },
                                    ]}
                                  >
                                    <Typography
                                      sx={[
                                        RWMStyles.subTitle,
                                        {
                                          fontWeight: otherProdDetails[
                                            "toppings"
                                          ].includes(topping?.id)
                                            ? 600
                                            : 500,
                                        },
                                      ]}
                                    >{`+ $${topping?.value}`}</Typography>
                                    <Checkbox
                                      value={otherProdDetails[
                                        "toppings"
                                      ].includes(topping?.id)}
                                      onChange={(
                                        e: any,
                                        checked: boolean
                                      ) => {
                                        if (checked) {
                                          setOtherProductDetails({
                                            ...otherProdDetails,
                                            toppings: [
                                              ...otherProdDetails[
                                              "toppings"
                                              ],
                                              topping?.id,
                                            ],
                                            [`topping_${topping?.id}`]:
                                              topping?.options[0],
                                          });
                                        } else {
                                          setOtherProductDetails({
                                            ...otherProdDetails,
                                            toppings: otherProdDetails[
                                              "toppings"
                                            ]?.filter(
                                              (item: any) =>
                                                item !== topping?.id
                                            ),
                                            [`topping_${topping?.id}`]:
                                              null,
                                          });
                                          if (
                                            openTopping === topping?.id
                                          ) {
                                            setOpenTopping(-1);
                                          }
                                        }
                                      }}
                                      sx={{
                                        pl: "0.2rem !important",
                                        color: ColorsHelper.gray36,
                                      }}
                                    />
                                  </Grid>
                                  {otherProdDetails["toppings"].includes(
                                    topping?.id
                                  ) && (
                                      <>
                                        <Divider sx={{ width: "100%" }} />
                                        <Grid
                                          item
                                          sx={[RWMStyles.toppingLeft]}
                                        >
                                          <Typography
                                            sx={[
                                              RWMStyles.topText,
                                              RWMStyles.topLightText,
                                            ]}
                                          >
                                            {
                                              otherProdDetails[
                                                `topping_${topping?.id}`
                                              ]?.label
                                            }
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          sx={RWMStyles.toppingRight}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              setOpenTopping(
                                                openTopping === topping?.id
                                                  ? -1
                                                  : topping?.id
                                              );
                                            }}
                                          >
                                            {openTopping === topping?.id ? (
                                              <KeyboardArrowUpIcon
                                                sx={RWMStyles.dudIcon}
                                              />
                                            ) : (
                                              <KeyboardArrowDownIcon
                                                sx={RWMStyles.dudIcon}
                                              />
                                            )}
                                          </IconButton>
                                        </Grid>
                                        {openTopping === topping?.id && (
                                          <>
                                            <Divider
                                              sx={{ width: "100%" }}
                                            />
                                            {topping?.options?.map(
                                              (
                                                topOption: any,
                                                i: number
                                              ) => (
                                                <Grid
                                                  item
                                                  sx={RWMStyles.toppingCon}
                                                  key={i}
                                                >
                                                  <IconButton
                                                    sx={RWMStyles.toppingBtn}
                                                    onClick={() => {
                                                      setOtherProductDetails(
                                                        {
                                                          ...otherProdDetails,
                                                          [`topping_${topping?.id}`]:
                                                            topOption,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={[
                                                        RWMStyles.topText,
                                                      ]}
                                                    >
                                                      {topOption?.label}
                                                    </Typography>
                                                    {otherProdDetails[
                                                      `topping_${topping?.id}`
                                                    ]?.id ===
                                                      topOption?.id && (
                                                        <CheckIcon
                                                          sx={[
                                                            RWMStyles.checkIcon,
                                                            {
                                                              color:
                                                                ColorsHelper.mainColor,
                                                            },
                                                          ]}
                                                        />
                                                      )}
                                                  </IconButton>
                                                </Grid>
                                              )
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                </Grid>
                              ))}
                            {openProductView?.data?.toppings?.length >
                              2 && (
                                <IconButton
                                  onClick={() => {
                                    if (toppingsShow === 2) {
                                      setToppingsShow(
                                        openProductView?.data?.toppings
                                          ?.length
                                      );
                                    } else {
                                      setToppingsShow(2);
                                    }
                                  }}
                                  sx={RWMStyles.showMoreBtn}
                                >
                                  <Typography>
                                    {toppingsShow === 2
                                      ? `+${openProductView?.data?.toppings
                                        ?.length - toppingsShow
                                      } more`
                                      : "see less"}
                                  </Typography>
                                  {toppingsShow === 2 ? (
                                    <KeyboardArrowDownIcon />
                                  ) : (
                                    <KeyboardArrowUpIcon />
                                  )}
                                </IconButton>
                              )}
                          </Box>
                        </Box>
                      )}
                      {openProductView?.data?.addOnProduct?.length >
                        0 && (
                          <Box sx={RWMStyles.contentBox}>
                            <Box sx={{ p: "0.5rem" }}>
                              <Typography
                                sx={{
                                  ...FontsHelper.font500,
                                  fontSize: "0.85rem",
                                  mb: "0.2rem",
                                }}
                              >
                                Add On Select Product
                              </Typography>
                              <Typography sx={RWMStyles.descText}>
                                Select up to 1 option
                              </Typography>
                            </Box>
                            <Divider sx={{ width: "100%" }} />
                            <Box
                              sx={[
                                RWMStyles.radioBtns,
                                {
                                  "& .Mui-checked": {
                                    color: ColorsHelper.mainColor + " !important",
                                  },
                                },
                              ]}
                            >
                              <Grid container>
                                {openProductView?.data?.addOnProduct
                                  .slice(0, addOnProShow)
                                  ?.map((prod: any, aIndex: number) => (
                                    <React.Fragment key={aIndex}>
                                      <Grid
                                        item
                                        sx={[RWMStyles.viewCustLeft]}
                                      >
                                        <Typography
                                          sx={[
                                            RWMStyles.subTitle,
                                            {
                                              fontWeight: otherProdDetails[
                                                "addOnProduct"
                                              ].includes(prod?.id)
                                                ? 600
                                                : 500,
                                            },
                                          ]}
                                        >
                                          {prod?.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item sx={RWMStyles.viewPRight}>
                                        <Typography
                                          sx={[
                                            RWMStyles.subTitle,
                                            {
                                              fontWeight: otherProdDetails[
                                                "addOnProduct"
                                              ].includes(prod?.id)
                                                ? 600
                                                : 500,
                                            },
                                          ]}
                                        >{`$${prod?.value}`}</Typography>
                                        <Checkbox
                                          value={otherProdDetails[
                                            "addOnProduct"
                                          ].includes(prod?.id)}
                                          onChange={(
                                            e: any,
                                            checked: boolean
                                          ) => {
                                            if (checked)
                                              setOtherProductDetails({
                                                ...otherProdDetails,
                                                addOnProduct: [
                                                  ...otherProdDetails[
                                                  "addOnProduct"
                                                  ],
                                                  prod?.id,
                                                ],
                                              });
                                            else
                                              setOtherProductDetails({
                                                ...otherProdDetails,
                                                addOnProduct:
                                                  otherProdDetails[
                                                    "addOnProduct"
                                                  ]?.filter(
                                                    (item: any) =>
                                                      item?.id !== prod?.id
                                                  ),
                                              });
                                          }}
                                          sx={{
                                            pl: "0.2rem !important",
                                            color: ColorsHelper.gray36,
                                          }}
                                        />
                                      </Grid>
                                    </React.Fragment>
                                  ))}
                              </Grid>
                              {openProductView?.data?.addOnProduct?.length >
                                2 && (
                                  <IconButton
                                    onClick={() => {
                                      if (addOnProShow === 2) {
                                        setAddOnProShow(
                                          openProductView?.data?.addOnProduct
                                            ?.length
                                        );
                                      } else {
                                        setAddOnProShow(2);
                                      }
                                    }}
                                    sx={RWMStyles.showMoreBtn}
                                  >
                                    <Typography>
                                      {addOnProShow === 2
                                        ? `+${openProductView?.data
                                          ?.addOnProduct?.length -
                                        addOnProShow
                                        } more`
                                        : "see less"}
                                    </Typography>
                                    {addOnProShow === 2 ? (
                                      <KeyboardArrowDownIcon />
                                    ) : (
                                      <KeyboardArrowUpIcon />
                                    )}
                                  </IconButton>
                                )}
                            </Box>
                          </Box>
                        )}
                      {openProductView?.data?.crustOption?.length > 0 && (
                        <Box sx={RWMStyles.contentBox}>
                          <Box sx={{ p: "0.5rem" }}>
                            <Typography
                              sx={{
                                ...FontsHelper.font500,
                                fontSize: "0.85rem",
                                mb: ".2rem",
                              }}
                            >
                              Crust Options
                            </Typography>
                            <Typography sx={RWMStyles.descText}>
                              Select any 1 option
                            </Typography>
                          </Box>
                          <Divider sx={{ width: "100%" }} />
                          <RadioGroup
                            sx={[
                              RWMStyles.radioBtns,
                              {
                                "& .Mui-checked": {
                                  color: ColorsHelper.mainColor + " !important",
                                },
                              },
                            ]}
                            value={otherProdDetails["crustOption"]?.id}
                            onChange={(event) => {
                              setOtherProductDetails({
                                ...otherProdDetails,
                                crustOption:
                                  openProductView?.data?.crustOption?.filter(
                                    (item: any) =>
                                      item?.id === event.target.value
                                  )[0],
                              });
                            }}
                          >
                            <Grid container>
                              {openProductView?.data?.crustOption?.map(
                                (cust: any, coIndex: number) => (
                                  <React.Fragment key={coIndex}>
                                    <Grid
                                      item
                                      sx={[RWMStyles.viewCustLeft]}
                                    >
                                      <Typography
                                        sx={[
                                          RWMStyles.subTitle,
                                          {
                                            fontWeight:
                                              otherProdDetails[
                                                "crustOption"
                                              ]?.id === cust?.id
                                                ? 600
                                                : 500,
                                          },
                                        ]}
                                      >
                                        {cust?.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item sx={RWMStyles.viewPRight}>
                                      <Typography
                                        sx={[
                                          RWMStyles.subTitle,
                                          {
                                            fontWeight:
                                              otherProdDetails[
                                                "crustOption"
                                              ]?.id === cust?.id
                                                ? 600
                                                : 500,
                                          },
                                        ]}
                                      >{`$${cust?.value}`}</Typography>
                                      <Radio
                                        value={cust?.id}
                                        sx={{
                                          pl: "0.2rem !important",
                                          color: ColorsHelper.gray36,
                                        }}
                                      />
                                    </Grid>
                                  </React.Fragment>
                                )
                              )}
                            </Grid>
                          </RadioGroup>
                        </Box>
                      )}

                      {openProductView?.data?.hasSpecialInstructions && (
                        <Box sx={[RWMStyles.contentBox, { mb: "2rem" }]}>
                          <Box sx={{ p: "0.5rem" }}>
                            <Typography sx={RWMStyles.subTitle}>
                              Special Instructions
                            </Typography>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              sx={RWMStyles.speInstTextF}
                              value={otherProdDetails["instruction"]}
                              placeholder="Do you want to add any instuctions..."
                              onChange={(e) => {
                                setOtherProductDetails({
                                  ...otherProdDetails,
                                  instruction: e.target.value,
                                });
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "4rem",
                      position: "sticky",
                      bottom: 0,
                      zIndex: 1,
                    }}
                  >
                    <Grid container sx={RWMStyles.btnCon}>
                      <Grid
                        item
                        sx={[RWMStyles.btnBox, { maxWidth: "8rem" }]}
                      >
                        <Box
                          sx={[
                            RWMStyles.addBtnTCount,
                            {
                              border: `1px solid ${ColorsHelper.mainColor} !important`,
                              color: ColorsHelper.mainColor,
                              background: ColorsHelper.mainColor + "17",
                              svg: { color: ColorsHelper.mainColor },
                            },
                          ]}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if (qty === 1) {
                                resetData();
                              } else {
                                setQty((prevValue: any) => prevValue - 1);
                              }
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <span style={{ color: ColorsHelper.blackClr }}>
                            {qty}
                          </span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setQty((prevValue: any) => prevValue + 1);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sx={[
                          RWMStyles.btnBox,
                          { maxWidth: "calc(100% - 8rem)" },
                        ]}
                      >
                        <Button
                          sx={[
                            RWMStyles.applyBtn,
                            {
                              backgroundColor: ColorsHelper.mainColor,
                              "&:hover": { backgroundColor: ColorsHelper.mainColor },
                            },
                          ]}
                          onClick={(e) => {
                            if (
                              openProductView?.data?.customisation
                                ?.length > 0
                            ) {
                              console.log("HRTEEEE IF");
                              setNoOfProduct(
                                (prevValue: any) => prevValue + 1
                              );
                              applyItemCountAnimation(1);
                              setSelectedProduct((prevValue: any) => ({
                                ...prevValue,
                                [openProductView?.data?.id]: {
                                  ...openProductView?.data,
                                  qty:
                                    prevValue.hasOwnProperty(
                                      openProductView?.data?.id
                                    ) &&
                                      prevValue[openProductView?.data?.id]
                                        ?.qty > 0
                                      ? prevValue[
                                        openProductView?.data?.id
                                      ]?.qty + qty
                                      : qty,
                                },
                              }));
                              resetData();
                            } else {
                              console.log("HRTEEEE Else");

                              setNoOfProduct(
                                (prevValue: any) => prevValue + 1
                              );
                              applyItemCountAnimation(1);
                              setSelectedProduct((prevValue: any) => ({
                                ...prevValue,
                                [openProductView?.data?.id]: {
                                  ...openProductView?.data,
                                  qty:
                                    prevValue.hasOwnProperty(
                                      openProductView?.data?.id
                                    ) &&
                                      prevValue[openProductView?.data?.id]
                                        ?.qty > 0
                                      ? prevValue[
                                        openProductView?.data?.id
                                      ]?.qty + qty
                                      : qty,
                                },
                              }));
                              resetData();
                            }
                          }}
                        >
                          {/* {`Add item ${qty * openProductView?.data?.formatted_price}`} */}
                          {`Add item $${qty *
                            getPrice(
                              true,
                              openProductView?.data?.toppings?.length
                            )
                            }`}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            }
          />
        </Box>
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <SwipableDrawer
          open={openCustmPro?.open}
          setOpen={(value: boolean) => {
            setOpenCustmPro({ open: false, data: null });
          }}
          drawerHeight={"51%"}
          content={
            <>
              <Typography sx={[RWMStyles.proName, { p: "1rem 0.5rem" }]}>
                Repeat your last customisation?
              </Typography>
              <Box sx={RWMStyles.custmPCon}>
                <Box sx={RWMStyles.orderDataCon}>
                  {Object.entries(selectedProduct).map(
                    ([key, value]: any) => (
                      <React.Fragment key={key}>
                        <Grid container sx={{ mb: "1rem" }}>
                          <Grid item sx={RWMStyles.pdBox}>
                            <Typography sx={RWMStyles.prodNameT}>
                              {value?.name}
                            </Typography>
                            <Typography sx={RWMStyles.prodDesc}>
                              {value?.description}
                            </Typography>
                          </Grid>
                          <Grid item sx={RWMStyles.qpBox}>
                            <Box
                              sx={[
                                RWMStyles.addBtnWCountT,
                                {
                                  border: `1px solid ${ColorsHelper.mainColor} !important`,
                                  color: ColorsHelper.mainColor,
                                  background: ColorsHelper.mainColor + "17",
                                  svg: { color: ColorsHelper.mainColor },
                                },
                              ]}
                            >
                              <IconButton
                                onClick={() => {
                                  if (value?.qty === 1) {
                                    let newSelectedProduct =
                                      selectedProduct;
                                    setSelectedProduct({});
                                    delete newSelectedProduct[key];
                                    if (
                                      Object.entries(newSelectedProduct)
                                        ?.length === 0
                                    ) {
                                      // setActivePage(2);
                                    }
                                    setSelectedProduct(
                                      (prevValue: any) => ({
                                        ...prevValue,
                                        ...newSelectedProduct,
                                      })
                                    );
                                  } else {
                                    setSelectedProduct(
                                      (prevValue: any) => ({
                                        ...prevValue,
                                        [value?.id]: {
                                          ...value,
                                          qty:
                                            prevValue[value?.id]?.qty - 1,
                                        },
                                      })
                                    );
                                  }
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <span>{value?.qty}</span>
                              <IconButton
                                onClick={() => {
                                  setSelectedProduct(
                                    (prevValue: any) => ({
                                      ...prevValue,
                                      [value?.id]: {
                                        ...value,
                                        qty:
                                          prevValue[value?.id]?.qty + 1,
                                      },
                                    })
                                  );
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                            <Typography
                              sx={[
                                RWMStyles.priceTxt,
                                { mt: "0.25rem", mr: "0.25rem" },
                              ]}
                            >{`${Number(selectedProduct[value?.id]?.qty) *
                              Number(value?.formatted_price)
                              } ${value?.currency}`}</Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  )}
                </Box>
              </Box>
              <Box sx={RWMStyles.saveBtnCon}>
                <IconButton
                  sx={RWMStyles.addNewCustBtn}
                  onClick={() => {
                    const productData = openCustmPro?.data;
                    setOpenCustmPro({ open: false, data: null });
                    setOpenProductView({
                      open: true,
                      isDetailedView: false,
                      data: productData,
                    });
                    setInView(false);
                  }}
                >
                  <AddIcon />
                  <Typography sx={RWMStyles.addNewCustTxt}>
                    Add new customisation
                  </Typography>
                </IconButton>
                <Button
                  onClick={() => { }}
                  disabled={true}
                  sx={[
                    RWMStyles.saveBtn,
                    {
                      backgroundColor: ColorsHelper.mainColor,
                      "&:hover": { backgroundColor: ColorsHelper.mainColor },
                    },
                    true && RWMStyles.disbledBtn,
                  ]}
                >
                  save
                </Button>
              </Box>
            </>
          }
        />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <SwipableDrawer
          open={open}
          setOpen={setOpen}
          bgColor={"#ffffff"}
          drawerHeight={"85%"}
          content={
            <>
              <Box sx={RWMStyles.headCon}>
                <Typography sx={RWMStyles.fsTitle}>
                  {"Domino's Pizza"}
                </Typography>
                <Typography sx={RWMStyles.fsDesc}>
                  Veg, Non-Veg, Pizza
                </Typography>
                <Divider sx={{ width: "100%", my: 1 }} />
                <Divider
                  sx={{
                    my: "0.6rem",
                    width: "100%",
                    span: {
                      ...FontsHelper.font400,
                      color: ColorsHelper.grey16,
                      fontSize: "0.9rem",
                    },
                    color: ColorsHelper.grey1,
                    letterSpacing: 1.5,
                    mt: 3,
                  }}
                >
                  DELIVERY OUTLETS NEAR YOU
                </Divider>
              </Box>
            </>
          }
        />
      </React.Suspense>

      {/* )} */}

    </Box>
  );
};

export default RestaurantWithMenu;
