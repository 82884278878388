export const validateMobileNumberInput = (event: KeyboardEvent) => {
    const charCode = event.which ? event.which : event.keyCode;

    // Array of valid key codes for numbers and control keys
    const validKeyCodes = [
        // Numbers: ASCII codes for digits 0 to 9
        ...Array.from({ length: 10 }, (_, i) => i + 48), // Key codes for '0' to '9'
        ...Array.from({ length: 10 }, (_, i) => i + 96), // Numpad keys for '0' to '9'
        // Control keys
        8,  // Backspace
        9,  // Tab
        13, // Enter
        27, // Escape
        37, // Left arrow
        38, // Up arrow
        39, // Right arrow
        40, // Down arrow
        46, // Delete
    ];

    // Prevent default if the key code is not valid
    if (!validKeyCodes.includes(charCode)) {
        event.preventDefault();
    }
};

export const validateMobileNumberPaste = (event: ClipboardEvent) => {
    const pastedData = event.clipboardData?.getData("text/plain");

    // Allow only numeric values
    if (!pastedData || !/^\d+$/.test(pastedData)) {
        event.preventDefault();
    }
};

// check any value
export const checkValue = (field: any) => {
    let isData = false

    if (typeof field !== 'undefined' && field !== null) {
        if (typeof field === 'string' && field?.trim() !== '') {
            isData = true
        } else if (typeof field === 'boolean') {
            isData = true
        } else if (typeof field === 'number') {
            isData = true
        } else if (typeof field === 'object') {
            isData = true
        }
    }

    return isData
}

// Function to fetch the user's IP address using fetch API
export const getIpAddress = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;  // Return the user's public IP address
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return 'default_ip';  // Return a default value in case of error
    }
}

// Function to get a value from localStorage
export const getFromLocalStorage = (key:string, defaultValue = null) => {
    const value = localStorage.getItem(key);
    return value !== null ? JSON.parse(value) : defaultValue;
};