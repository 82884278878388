import { gql } from "@apollo/client";

// export const SendOrResendUserOTP = gql`
//     mutation SendOtp($mobileNumber: String!){
//         sendOtp(mobileNumber: $mobileNumber){
//             message
//         }
//     }
// `;

// export const VERIFY_OTP_MUTATION = gql`
//     mutation VarifyOtp($mobileNumber: String!, $otp: String!){
//         varifyOtp(mobileNumber: $mobileNumber, otp: $otp){
//            token 
//         }
//     }
// `

export const SendOrResendUserOTP = gql`
    mutation SendOrResendUserOTP($mobile_no: String!, $country_code: Int!) {
      sendOrResendUserOTP(mobile_no: $mobile_no, country_code: $country_code) {
      status
      message
      error {
        country_code
        mobile_no
      }
    }
  }
`

export const ValidateUserOTP = gql`
    mutation ValidateUserOTP($mobile_no: String!, $otp_code: String!) {
      validateUserOTP(mobile_no: $mobile_no, otp_code: $otp_code) {
      status
      message
      authorization
      userData {
        id
        customer_id
        country_code
        mobile_no
        user_type
        email_id
      }
    }
  }
`

export const getCustomerById = gql`
  query GetCustomerById($getCustomerByIdId: Int!) {
    getCustomerById(id: $getCustomerByIdId) {
      status
      message
      data {
        id
        first_name
        last_name
        email_id
        mobile_no
        address
        dob
        country_code
        profile_image
      }
    }
  }
`

export const UpdateUser = gql`
 mutation UpdateUser($updateUserId: Int!, $emailId: String, $mobileNo: String, $firstName: String, $countryCode: Int, $address: String, $gender: String, $latitude: String, $longitude: String, $dob: String, $anniversary: String, $file: Upload) {
  updateUser(id: $updateUserId, email_id: $emailId, mobile_no: $mobileNo, first_name: $firstName, country_code: $countryCode, address: $address, gender: $gender, latitude: $latitude, longitude: $longitude, dob: $dob, anniversary: $anniversary, file: $file) {
    status
    message
    error {
      first_name
      mobile_no
      email_id
    }
  }
}
`