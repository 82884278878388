import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import apolloClient from "../../Configuration/apolloClient";
import { getCustomerById, SendOrResendUserOTP, UpdateUser, ValidateUserOTP } from "../../api/authQueries";

interface SendOtp { 
  mobile_no: String; 
  country_code: number;
}

//Async thunk to send OTP
export const sendOtp = createAsyncThunk(
  'auth/sendOtp',
  async ({mobile_no, country_code} : SendOtp, {rejectWithValue}) => {
    try{
      
      const client: ApolloClient<NormalizedCacheObject> = apolloClient
      const response = await client.mutate({
        mutation : SendOrResendUserOTP,
        variables: { mobile_no, country_code },
        context : {
          excludeAuth : true,
        }
      })

       return response?.data?.sendOrResendUserOTP
      }catch (error : any) {
      return rejectWithValue(error.message || "Failed to send OTP")
    }
  }
)

//Async thunk to Verify OTP
export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async ({mobile_no, otp_code} : {mobile_no:string; otp_code: string}, {rejectWithValue}) => {
    try{
      const client: ApolloClient<NormalizedCacheObject> = apolloClient
      const response =  await client.mutate({
        mutation: ValidateUserOTP,
        variables: {mobile_no, otp_code},
        context : {
          excludeAuth : true,
        }
      })
      return response?.data?.validateUserOTP
    }catch (error:any) {
      return rejectWithValue(error.message || "Invalid OTP")
    }
  }
)

//Async thunk to get user by ID
export const getCustomer = createAsyncThunk(
  'auth/getCustomer',
  async ({id} : {id:number}, {rejectWithValue}) => {
    try{
      const client: ApolloClient<NormalizedCacheObject> = apolloClient
      const response =  await client.query({
        query: getCustomerById,
        variables: {getCustomerById}
      })
      return response
    }catch (error:any) {
      return rejectWithValue(error.message || "Invalid Id")
    }
  }
)

//Async thunk to Update User
export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({updateUserId, emailId, mobileNo, firstName, countryCode, address, gender, latitude, longitude, dob, anniversary, file} : {updateUserId: Number, emailId?: String, mobileNo?: String, firstName?: String, countryCode?: Number, address?: String, gender?: String, latitude?: String, longitude?: String, dob?: String, anniversary?: String, file?: File}, {rejectWithValue}) => {
    try{
      const client: ApolloClient<NormalizedCacheObject> = apolloClient
      const response =  await client.mutate({
        mutation: UpdateUser,
        variables : {updateUserId, emailId, mobileNo, firstName, countryCode, address, gender, latitude, longitude, dob, anniversary, file},
        context: {
          isMultiPartFormData: !!file,
        },
      })
      return response?.data?.updateUser
    }catch (error:any) {
      return rejectWithValue(error.message || "Invalid User")
    }
  }
)