import { Box, CircularProgress, Typography } from '../../shared/MuiImports'

const CircleProgress = () => {
  const styles = {
    wrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection:'column',
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: semi-transparent background
      zIndex: 9999, // Ensure it overlays other elements
    },
  };

  return (
    <Box sx={styles.wrapper}>
      <CircularProgress />
      <Typography sx={{ fontWeight: 600, mt: 2 }}>Loading...</Typography>
    </Box>
  );
};

export default CircleProgress;
