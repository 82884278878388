import { configureStore } from "@reduxjs/toolkit";
import authSlice from '../store/Auth/AuthReducer';

export const store = configureStore({
    reducer: {
        auth: authSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disables the serializable state invariant check
            thunk: true, // Ensure redux-thunk is enabled
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
