// Helper Functions
import ColorsHelper from "../../shared/ColorHelper";
import FontsHelper from "../../shared/FontsHelper";
import CommanStyles from "../../shared/CommonStyle";

const fsdStyles = {
  dialog: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      maxWidth: "30rem",
      background: ColorsHelper.grey30,
    },
    "& .MuiBackdrop-root": {
      background: "none",
    },
  },
  container: {
    py: "0.5rem",
    ...CommanStyles.centerItColumn,
    height: "3rem",
  },
  iconCon: {
    ...CommanStyles.flexRowsBetween,
    width: "100%",
  },
  icon: {
    color: ColorsHelper.blackClr,
    fontSize: "1.3rem",
    pl: "0.3rem",
  },
  restTitle: {
    ...FontsHelper.font500,
    fontSize: "1rem",
  },
  titleCon: {
    ...CommanStyles.dflex,
    alignItems: "center",
  },
};
export default fsdStyles;
