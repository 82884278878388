//React Imports
import { useEffect } from 'react';

const DisablePullToRefresh = () => {
  useEffect(() => {
    let lastY = 0;

    const preventPullToRefresh = (event:any) => {
      const currentScroll = window.scrollY;
      const isAtTop = currentScroll === 0;
      const isPullingDown = event.touches[0].clientY > lastY;

      if (isAtTop && isPullingDown) {
        event.preventDefault();
      }

      lastY = event.touches[0].clientY;
    };

    document.addEventListener('touchstart', (e) => {
      lastY = e.touches[0].clientY;
    });

    document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

    return () => {
      document.removeEventListener('touchstart', (e) => {
        lastY = e.touches[0].clientY;
      });
      document.removeEventListener('touchmove', preventPullToRefresh);
    };
  }, []);

  return null;
};

export default DisablePullToRefresh;
