// Images Imports
import PizzaImage from '../Assets/images/categories/pizza.png'
import Burger from '../Assets/images/categories/burger.png'
import Sandwich from '../Assets/images/categories/sandwich.png'
import Chicken from '../Assets/images/categories/chicken.png'
import Cake from '../Assets/images/categories/cake.png'
import Noodles from '../Assets/images/categories/noodles.png'
import Fries from '../Assets/images/categories/fries.png'
import Milkshake from '../Assets/images/categories/milkshake.png'
import PizzaRectangle from '../Assets/images/PizzaRectangle.png'
import image1 from "../Assets/images/recomdation/image1.jpg"
import image2 from "../Assets/images/recomdation/image2.jpg"
import image3 from "../Assets/images/recomdation/image3.jpg"
import image4 from "../Assets/images/recomdation/image4.jpg"
import image5 from "../Assets/images/recomdation/image5.jpg"
import image6 from "../Assets/images/recomdation/image6.jpg"
import Tag from '../Assets/Icons/Tag.svg'
import Pizza from '../Assets/Icons/pizza.svg'

//Models
import pizzaModel from "../Assets/3d/pizza.glb";

//Restaurants
export const RESTAURANTS_LIST = [
    {
        id: 1,
        name: "Atlantic Eats",
        cuisine: "Cowboy Cookout",
        price: "$6.90 for one",
        rating: 4.5,
        deliveryTime: "25-30 min to reach",
        distance: "4 km away",
        image: PizzaRectangle
    },
    {
        id: 2,
        name: "Ocean Bites",
        cuisine: "Seafood Delight",
        price: "$8.50 for one",
        rating: 4.7,
        deliveryTime: "30-40 min to reach",
        distance: "5 km away",
        image: PizzaRectangle
    },
    {
        id: 3,
        name: "Veggie Treats",
        cuisine: "Healthy Bowls",
        price: "$5.00 for one",
        rating: 4.2,
        deliveryTime: "20-25 min to reach",
        distance: "3 km away",
        image: PizzaRectangle
    },
    {
        id: 4,
        name: "La Pino’z Pizza",
        cuisine: "Healthy Bowls",
        price: "$5.00 for one",
        rating: 4.2,
        deliveryTime: "20-25 min to reach",
        distance: "3 km away",
        image: PizzaRectangle
    },
    {
        id: 5,
        name: "La Milano Pizzeria",
        cuisine: "Healthy Bowls",
        price: "$5.00 for one",
        rating: 4.2,
        deliveryTime: "20-25 min to reach",
        distance: "3 km away",
        image: PizzaRectangle
    },
];

//Main Categories
export const MAIN_CATEGORIES = [
    // {
    //   id: "1",
    //   name: "Your Previous Picks",
    //   menu_id: 1,
    //   position: 0,
    //   is_active: true,
    //   is_previous_pick: true,
    //   items: [
    //     {
    //       name: "Italian Pizza [ 8 Inches ] ",
    //       id: "2",
    //       position: 1,
    //       image: PizzaRectangle,
    //       description: "You orderd 10 days ago",
    //       formatted_price: 10,
    //       currency: "USD",
    //       has_customization: false,
    //       has_addon_or_upsell_addon: false,
    //       has_nutritional_and_allergen_info: false,
    //       has_special_instruction: false,
    //       has_promotion: false,
    //       has_visible: true,
    //       visibility_status: 1,
    //       is_out_of_stock: false,
    //       hasSpecialInstructions: true,
    //       __typename: "categoryItemsResponse",
    //     },

    //     {
    //       name: "Margherita Pizza [ 8 Inches ]",
    //       id: "5",
    //       position: 4,
    //       image: PizzaRectangle,
    //       description: "You order 1 month ago",
    //       formatted_price: 13.99,
    //       currency: "USD",
    //       has_customization: false,
    //       has_addon_or_upsell_addon: false,
    //       has_nutritional_and_allergen_info: false,
    //       has_special_instruction: false,
    //       has_promotion: false,
    //       has_visible: true,
    //       visibility_status: 1,
    //       is_out_of_stock: false,
    //       __typename: "categoryItemsResponse",
    //     },
    //   ],
    //   __typename: "Personalized",
    // },
    {
      id: "2",
      name: "Specialty Pizzas",
      menu_id: 1,
      position: 1,
      is_active: true,
      items: [
        {
          name: "Philly Cheese Steak Pizza",
          id: "2",
          position: 1,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Tender slices of steak, fresh onions, fresh green peppers and fresh mushrooms with provolone and American cheese on a cheesy provolone crust. ",
          formatted_price: 13.99,
          currency: "USD",
          has_customization: true,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          customisation: [
            {
              value: 13.99,
              label: "Regular (Serves 2.Inch CM)",
              id: 1,
            },
            {
              value: 19.99,
              label: "Medium (Serves 4.Inch CM)",
              id: 2,
            },
            {
              value: 24.99,
              label: "Large (Serves 7.Inch CM)",
              id: 3,
            },
          ],
          toppings: [
            {
              value: 5,
              label: "Marinara Sauce Dipping",
              id: 1,
              options: [
                {
                  label: "Light Dipping",
                  id: 1,
                },
                {
                  label: "Medium Dipping",
                  id: 2,
                },
                {
                  label: "Extra Dipping",
                  id: 3,
                },
              ],
            },
          ],
          addOnProduct: [
            {
              value: 5,
              label: "Finger Foods",
              id: 1,
            },
            {
              value: 10,
              label: "Dips and Spreads",
              id: 2,
            },
            {
              value: 15,
              label: "Burger",
              id: 3,
            },
            {
              value: 20,
              label: "Coca cola",
              id: 4,
            },
          ],
          crustOption: [
            {
              value: 5,
              label: "Thick Metro Crust",
              id: 1,
            },
            {
              value: 5,
              label: "Choclate Syrup",
              id: 2,
            },
          ],
          hasSpecialInstructions: true,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Cali Chicken Bacon Ranch Pizza",
          id: "3",
          position: 2,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Grilled chicken breast, white sauce, smoked bacon, tomatoes, cheese made with mozzarella and provolone on a cheese crust. ",
          formatted_price: 13.99,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Pacific Veggie Pizza",
          id: "5",
          position: 4,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Roasted red peppers, fresh baby spinach, fresh onions, fresh mushrooms, tomatoes, black olives, cheeses made with mozzarella, feta and provolone, sprinkled with garlic herb seasoned on a cheesy Parmesan-Asiago crust. Vegetarian.",
          formatted_price: 13.99,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
    {
      id: "3",
      name: "Sides",
      menu_id: 1,
      position: 0,
      is_active: true,
      items: [
        {
          name: "Stuffed Cheesy Bread",
          id: "16",
          position: 0,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Our oven-baked breadsticks are generously stuffed and covered with a blend of 100% real mozzarella and cheddar cheeses then seasoned with a touch of garlic. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Stuffed Cheesy Bread with Spinach and Feta",
          id: "17",
          position: 1,
          image: PizzaRectangle,
          description:
            "Our oven-baked breadsticks are stuffed with cheese, fresh spinach and Feta cheese - covered in a blend of cheese made with 100% real mozzarella and cheddar. Seasoned with a touch of garlic and Parmesan. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Stuffed Cheesy Bread with Bacon and Jalepano",
          id: "18",
          position: 2,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Our oven-baked breadsticks are stuffed with cheese, smoked bacon & jalapeno peppers - covered in a blend of cheeses; made with 100% real mozzarella and cheddar. Seasoned with a touch of garlic and Parmesan. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Stuffed Cheesy Bread with Pepperoni",
          id: "19",
          position: 3,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Our oven-baked breadsticks are stuffed with cheese, fresh spinach and Feta cheese - covered in a blend of cheese made with 100% real mozzarella and cheddar. Seasoned with a touch of garlic and Parmesan. \nChoose your favorite dipping sauce cup.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
    {
      id: "4",
      name: "Pasta",
      menu_id: 1,
      position: 0,
      is_active: true,
      items: [
        {
          name: "Chicken Alfredo",
          id: "46",
          position: 0,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Try our savory Chicken Alfredo Pasta. Grilled chicken breast and creamy Alfredo sauce is mixed with penne pasta and baked to creamy perfection.",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Chicken Alfredo Breadbowl",
          id: "47",
          position: 1,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Try our savory Chicken Alfredo Pasta baked into a Breadbowl. Grilled chicken breast and creamy Alfredo sauce is mixed with penne pasta and baked to creamy perfection.",
          formatted_price: 9.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
    {
      id: "5",
      name: "Oven-Baked Sandwiches",
      menu_id: 1,
      position: 0,
      is_active: true,
      items: [
        {
          name: "Chicken Bacon Ranch Sandwich",
          id: "56",
          position: 0,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Grilled chicken breast, smoked bacon, creamy ranch and provolone cheese, on artisan bread and baked to a golden brown. ",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
        {
          name: "Chicken Parm Sandwich",
          id: "57",
          position: 1,
          image: PizzaRectangle,
          modelPath:"",
          description:
            "Grilled chicken breast, tomatoes basil marinara, Parmesan asiago and provolone cheese,on artisan bread and baked to a golden brown. ",
          formatted_price: 8.49,
          currency: "USD",
          has_customization: false,
          has_addon_or_upsell_addon: false,
          has_nutritional_and_allergen_info: false,
          has_special_instruction: false,
          has_promotion: false,
          has_visible: true,
          visibility_status: 1,
          is_out_of_stock: false,
          __typename: "categoryItemsResponse",
        },
      ],
      __typename: "Categories",
    },
  ];


// Categories for Home page
export const CATEGORIES_VIEW = [
    { title: 'Pizza', src: PizzaImage },
    { title: 'Burger', src: Burger },
    { title: 'Sandwich', src: Sandwich },
    { title: 'Chicken', src: Chicken },
    { title: 'Cake', src: Cake },
    { title: 'Noodles', src: Noodles },
    { title: 'Fries', src: Fries },
    { title: 'Milkshake', src: Milkshake },
    { title: 'Pasta', src: PizzaImage },
    { title: 'Tacos', src: Burger },
    { title: 'Wrap', src: Sandwich },
    { title: 'Salad', src: Chicken },
    { title: 'Sushi', src: Cake },
    { title: 'Steak', src: Noodles },
    { title: 'Waffles', src: Fries },
    { title: 'Pancake', src: Milkshake },
    { title: 'Quiche', src: PizzaImage },
    { title: 'Biryani', src: Burger },
    { title: 'Ramen', src: Sandwich },
    { title: 'Falafel', src: Chicken },
    { title: 'Curry', src: Cake },
    { title: 'Risotto', src: Noodles },
    { title: 'Samosa', src: Fries },
    { title: 'Dosa', src: Milkshake },
    { title: 'Baklava', src: PizzaImage },
    { title: 'Churros', src: Burger },
    { title: 'Muffins', src: Sandwich },
    { title: 'Donuts', src: Chicken },
    { title: 'Tiramisu', src: Cake },
    { title: 'DimSum', src: Noodles },
    { title: 'Fajitas', src: Fries },
    { title: 'Ceviche', src: Milkshake },
    { title: 'Chili', src: PizzaImage },
    { title: 'Gravy', src: Burger },
    { title: 'Chowmein', src: Sandwich },
    { title: 'Sausage', src: Chicken },
    { title: 'Pudding', src: Cake },
    { title: 'Hotdog', src: Noodles },
    { title: 'Stew', src: Fries },
    { title: 'Poke', src: Milkshake },
    { title: 'Shawarma', src: PizzaImage },
    { title: 'Kebab', src: Burger },
    { title: 'Gyros', src: Sandwich },
    { title: 'Moussaka', src: Chicken },
    { title: 'Jalebi', src: Cake },
    { title: 'Casserole', src: Noodles },
    { title: 'FriedRice', src: Fries },
    { title: 'Baguette', src: Milkshake },
    { title: 'Rissole', src: PizzaImage },
    { title: 'Poutine', src: Burger },
    { title: 'Crepes', src: Sandwich },
    { title: 'BokChoy', src: Chicken },
    { title: 'Tart', src: Cake },
    { title: 'Bagel', src: Noodles },
    { title: 'Mole', src: Fries },
    { title: 'Goulash', src: Milkshake },
    { title: 'Pavlova', src: PizzaImage },
    { title: 'Bun', src: Burger },
];

//Recomandations for Categories view page
export const RECOMANDATIONS = [
    {
        restaurantName :"La pino'z Pizza",
        offer : "50% off",
        offerUpTo : "Upto $2.99",
        image:image1,
        timeToReach:'23 min',
        awayKm :'1.0 km'
    },
    {
        restaurantName :"UFO - Fries & Corn",
        offer : "Buy 1Get 1",
        offerUpTo : "",
        image:image2,
        timeToReach:'18 min',
        awayKm :'3.5 km'
    },
    {
        restaurantName :"San Frenzy’s Pizza",
        offer : "50% OFF",
        offerUpTo : "up to $2.99",
        image:image3,
        timeToReach:'36 min',
        awayKm :'6 km'
    },
    {
        restaurantName :"Spedito Pizzeria",
        offer : "50% OFF",
        offerUpTo : "up to $2.99",
        image:image4,
        timeToReach:'26 min',
        awayKm :'4.5 km'
    },
    {
        restaurantName :"Beat & Bite Restro",
        offer : "20% OFF",
        offerUpTo : "up to $1.50",
        image:image5,
        timeToReach:'23 min',
        awayKm :'3 km'
    },
    {
        restaurantName :"Cheelizza",
        offer : "50% OFF",
        offerUpTo : "up to $2.99",
        image:image6,
        timeToReach:'18 min',
        awayKm :'3.5 km'
    }
    ,
    {
        restaurantName :"Beat & Bite Restro",
        offer : "20% OFF",
        offerUpTo : "up to $1.50",
        image:image5,
        timeToReach:'23 min',
        awayKm :'3 km'
    },
    {
        restaurantName :"Cheelizza",
        offer : "50% OFF",
        offerUpTo : "up to $2.99",
        image:image6,
        timeToReach:'18 min',
        awayKm :'3.5 km'
    }
]

export const FILTERS = [
    { title: "Sort", isSelected: false },
    { title: "Nearest", isSelected: false },
    { title: "Great Offers", isSelected: false },
    { title: "Rating 4.0+", isSelected: false },
    { title: "Non-veg", isSelected: false },
    { title: "Pure Veg", isSelected: false },
    { title: "Fast Delivery", isSelected: false }
]

export const SORT_BY = [
    { title: "Relevance", isSelected: false },
    { title: "Rating: High To Low", isSelected: false },
    { title: "Cost: Low To High", isSelected: false },
    { title: "Cost: High To Low", isSelected: false },
]

export const EXPLORES = [
    {
        title: 'Starting at $2.19',
        tagline: 'Flat Discounts',
        src: Tag
    },
    {
        title: 'Flat 50% OFF',
        tagline: 'Burgers & more',
        src: Pizza
    },
    {
        title: 'Starting at $2.19',
        tagline: 'Flat Discounts',
        src: Tag
    },
    {
        title: 'Flat 50% OFF',
        tagline: 'Burgers & more',
        src: Pizza
    },
    {
        title: 'Starting at $2.19',
        tagline: 'Flat Discounts',
        src: Tag
    },
    {
        title: 'Flat 50% OFF',
        tagline: 'Burgers & more',
        src: Pizza
    },
]

