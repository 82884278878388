import MycircleLoginLogo from "../Assets/Icons/MycircleLoginLogo.svg";
import MyCircleLogo from "../Assets/Icons/MyCircleLogo.svg";
import AutoResponders from "../Assets/Icons/AutoResponders.svg";
import AutoRespondersFill from "../Assets/Icons/AutoRespondersFill.svg";
import BlastCampaign from "../Assets/Icons/BlastCampaign.svg";
import BlastCampaignFill from "../Assets/Icons/BlastCampaignFill.svg";
import TestSMS from "../Assets/Icons/TestSMS.svg";
import SendMsg from "../Assets/Icons/SendMsg.svg";
import PendingStatus from "../Assets/Icons/PendingStatus.svg";
import MarkAsCompleted from "../Assets/Icons/MarkAsCompleted.svg";
import ReservationMblRgtArt from "../Assets/Icons/ReservationMblRgtArt.svg";
import ReservationMblLftArt from "../Assets/Icons/ReservationMblLftArt.svg";

// No data img
import NoDataImg from "../Assets/Icons/NoDataImg.svg";

// Error Page
import Page302 from "../Assets/Icons/Page302.svg";
import Page404 from "../Assets/Icons/Page404.svg";
import Page500 from "../Assets/Icons/Page500.svg";

import Close from "../Assets/Icons/Close.svg";
import Client from "../Assets/Icons/Client.svg";
import SubClient from "../Assets/Icons/SubClient.svg";
import SubClientFill from "../Assets/Icons/SubClientFill.svg";
import ClientFill from "../Assets/Icons/ClientFill.svg";
import Dashboard from "../Assets/Icons/Dashboard.svg";
import DashboardFill from "../Assets/Icons/DashboardFill.svg";
import Dot from "../Assets/Icons/Dot.svg";
import Tools from "../Assets/Icons/Tools.svg";
import ToolsFill from "../Assets/Icons/ToolsFill.svg";
import Search from "../Assets/Icons/Search.svg";
import Chat from "../Assets/Icons/Chat.svg";
import Notification from "../Assets/Icons/Notification.svg";
import Switch from "../Assets/Icons/Switch.svg";
import User from "../Assets/Icons/User.svg";
import Settings from "../Assets/Icons/Settings.svg";
import BusinessSettings from "../Assets/Icons/BusinessSettings.svg";
import HelpCenter from "../Assets/Icons/HelpCenter.svg";
import Logout from "../Assets/Icons/Logout.svg";
import Envelope from "../Assets/Icons/Envelope.svg";
import Comment from "../Assets/Icons/Comment.svg";
import Whatsapp from "../Assets/Icons/Whatsapp.svg";
import CloseRound from "../Assets/Icons/CloseRound.svg";
import RequiredStar from "../Assets/Icons/RequiredStar.svg";
import CFCheck from "../Assets/Icons/CF-Check.svg";
import CFCopy from "../Assets/Icons/CF-Copy.svg";
import CFCross from "../Assets/Icons/CF-Cross.svg";
import CFDown from "../Assets/Icons/CF-Down.svg";
import CFUp from "../Assets/Icons/CF-Up.svg";
import CustomForms from "../Assets/Icons/CustomForms.svg";
import CustomFormsFill from "../Assets/Icons/CustomFormsFill.svg";
import Groups from "../Assets/Icons/Groups.svg";
import GroupsFill from "../Assets/Icons/GroupsFill.svg";
import CreditAmount from "../Assets/Icons/CreditAmount.svg";
import DebitAmount from "../Assets/Icons/DebitAmount.svg";
import PayLocations from "../Assets/Icons/PayLocations.svg";
import Drop from "../Assets/Icons/Mask.svg";
import MenuonlineIcon from "../Assets/Icons/Icon-Menuonline.svg";
import DigitalSignageIcon from "../Assets/Icons/Icon-DigitalSignage.svg";
import ScheduleDowncon from "../Assets/Icons/Icon-ScheduleDown.svg";
import SmartWifiIcon from "../Assets/Icons/Icon-Smart-Wifi.svg";
import MarketingIcon from "../Assets/Icons/Icon-Marketing.svg";
import LoyaltyProgramIcon from "../Assets/Icons/Icon-LoyaltyProram.svg";
import ReputationIcon from "../Assets/Icons/Icon-Reputation.svg";
import Edit from "../Assets/Icons/Edit.svg";
import Delete from "../Assets/Icons/Delete.svg";
import More from "../Assets/Icons/More.svg";
import Arrow from "../Assets/Icons/Arrow.svg";
import Rocket from "../Assets/Icons/Rocket.svg";
import IIcon from "../Assets/Icons/IIcon.svg";
import IIconBlack from "../Assets/Icons/IIconBlack.svg";
import Emoji from "../Assets/Icons/Emoji.svg";
import Image from "../Assets/Icons/Image.svg";
import Attachment from "../Assets/Icons/Attachment.svg";
import Template from "../Assets/Icons/Template.svg";
import CustomField from "../Assets/Icons/CustomField.svg";
import Link from "../Assets/Icons/Link.svg";
import LinkActive from "../Assets/Icons/LinkActive.svg";
import Refresh from "../Assets/Icons/Refresh.svg";
import TimeFast from "../Assets/Icons/TimeFast.svg";
import TimeQuarterpast from "../Assets/Icons/TimeQuarterpast.svg";
import CardMask from "../Assets/Icons/CardMask.svg";
import CCVisaIcon from "../Assets/Icons/CC-visa.svg";
import CCMasterIcon from "../Assets/Icons/CC-mastercard.svg";
import CCAmericaExpIcon from "../Assets/Icons/CC-american-express.svg";
import CCDiscoverIcon from "../Assets/Icons/CC-discover.svg";
import CCJcbIcon from "../Assets/Icons/CC-jcb.svg";
import CCOtherIcon from "../Assets/Icons/CC-other.svg";
import Plus from "../Assets/Icons/Plus.svg";
import GreenCheck from "../Assets/Icons/GreenCheck.svg";
import CustPlanBadge from "../Assets/Icons/CustPlansBadge.svg";
import Gift from "../Assets/Icons/Gift.svg";
import GiftWhite from "../Assets/Icons/GiftWhite.svg";
import UserBlack from "../Assets/Icons/UserBlack.svg";
import Earnings from "../Assets/Icons/Earnings.svg";
import PlusBlue from "../Assets/Icons/PlusBlue.svg";
import UnsubscribeIcon from "../Assets/Icons/UnsubscribeIcon.svg";
import SubscribeIcon from "../Assets/Icons/SubscribeIcon.svg";
import Bulb from "../Assets/Icons/Bulb.svg";
import CloseRed from "../Assets/Icons/CloseRed.svg";
import DummyLocation from "../Assets/Icons/DummyLocation.svg";
import Lock from "../Assets/Icons/Lock.svg";
import Compliance from "../Assets/Icons/Compliance.svg";
import Ellipse from "../Assets/Icons/Ellipse.svg";
import LeftArrow from "../Assets/Icons/LeftArrow.svg";
import QRIcon from "../Assets/Icons/QRIcon.svg";
import List from "../Assets/Icons/List.svg";
import DrageIcon from "../Assets/Icons/DrageIcon.svg";
import timeQuarterPastWhite from "../Assets/Icons/timeQuarterPastWhite.svg";
import timeFastWhite from "../Assets/Icons/timeFastWhite.svg";
import notificationWhite from "../Assets/Icons/notificationWhite.svg";
import CompailanceWh from "../Assets/Icons/CompailanceWh.svg";
import settingsWhite from "../Assets/Icons/settingsWhite.svg";
import CheckMarkOr from "../Assets/Icons/CheckMarkOr.svg";
import CrossCheck from "../Assets/Icons/crossCheck.svg";
import AngleDown from "../Assets/Icons/AngleDown.svg";
import AngleUp from "../Assets/Icons/AngleUp.svg";
import Scan from "../Assets/Icons/Scan.svg";
import LeftRoundArrow from "../Assets/Icons/LeftRoundArrow.svg";
import Congratulation from "../Assets/Icons/Congratulation.svg";
import GiftLoyalty from "../Assets/Icons/GiftLoyalty.svg";
import GiftGrey from "../Assets/Icons/GiftGrey.svg";
import GiftLoyaltyWhite from "../Assets/Icons/GiftLoyaltyWhite.svg";
import DownloadWhite from "../Assets/Icons/DownloadWhite.svg";
import FetchIconBlack from "../Assets/Icons/FetchIconBlack.svg";
import LocationYellow from "../Assets/Icons/LocationYellow.svg";
import iIconBordered from "../Assets/Icons/iIconBordered.svg";

//side menu
import BackMenuArrow from "../Assets/Icons/BackMenuArrow.svg";
import WebWidgets from "../Assets/Icons/WebWidgets.svg";
import WebWidgetsFill from "../Assets/Icons/WebWidgetsFill.svg";
import QrWidgets from "../Assets/Icons/QrWidgets.svg";
import QrWidgetsFill from "../Assets/Icons/QrWidgetsFill.svg";
import ImageWidgets from "../Assets/Icons/ImageWidgets.svg";
import ImageWidgetsFill from "../Assets/Icons/ImageWidgetsFill.svg";
import Account from "../Assets/Icons/Account.svg";
import AccountFill from "../Assets/Icons/AccountFill.svg";
import Payment from "../Assets/Icons/Payment.svg";
import PaymentFill from "../Assets/Icons/PaymentFill.svg";
import Location from "../Assets/Icons/Location.svg";
import LocationFill from "../Assets/Icons/LocationFill.svg";
import SubUser from "../Assets/Icons/SubUser.svg";
import SubUserFill from "../Assets/Icons/SubUserFill.svg";
import ChangePass from "../Assets/Icons/ChangePass.svg";
import ChangePassFill from "../Assets/Icons/ChangePassFill.svg";
import SettingBlack from "../Assets/Icons/SettingBlack.svg";
import SwitchBlack from "../Assets/Icons/SwitchBlack.svg";
import SwitchWhite from "../Assets/Icons/SwitchWhite.svg";
import UserSwitchBlack from "../Assets/Icons/UserSwitchBlack.svg";
import UserSwitchWhite from "../Assets/Icons/UserSwitchWhite.svg";
import MenuBlack from "../Assets/Icons/MenuBlack.svg";
import ArrowDownWhite from "../Assets/Icons/ArrowDownWhite.svg";
import DragIconWhite from "../Assets/Icons/DragIconWhite.svg";
import DeleteWhite from "../Assets/Icons/DeleteWhite.svg";
import EditWhite from "../Assets/Icons/EditWhite.svg";
import CloseCircle from "../Assets/Icons/CloseCircle.svg";
import EditGrey from "../Assets/Icons/EditGrey.svg";
import MoreGrey from "../Assets/Icons/MoreGrey.svg";
import DeleteGrey from "../Assets/Icons/DeleteGrey.svg";

// clients icons
import CustForms from "../Assets/Icons/CustForms.svg";
import WIFI from "../Assets/Icons/WIFI.svg";
import QRGreyIcon from "../Assets/Icons/QRGreyIcon.svg";
import WebSignUp from "../Assets/Icons/WebSignUp.svg";
import DownloadIcon from "../Assets/Icons/DownloadIcon.svg";
import UserRemove from "../Assets/Icons/UserRemove.svg";

// sub user login
import Num0 from "../Assets/Icons/Num0.svg";
import Num1 from "../Assets/Icons/Num1.svg";
import Num2 from "../Assets/Icons/Num2.svg";
import Num3 from "../Assets/Icons/Num3.svg";
import Num4 from "../Assets/Icons/Num4.svg";
import Num5 from "../Assets/Icons/Num5.svg";
import Num6 from "../Assets/Icons/Num6.svg";
import Num7 from "../Assets/Icons/Num7.svg";
import Num8 from "../Assets/Icons/Num8.svg";
import Num9 from "../Assets/Icons/Num9.svg";
import NumBack from "../Assets/Icons/NumBack.svg";
import NumCancel from "../Assets/Icons/NumCancel.svg";
import Pin from "../Assets/Icons/Pin.svg";

// two - way chat
import LikeIcon from "../Assets/Icons/LikeIcon.svg";
import RefreshIcon from "../Assets/Icons/RefreshIcon.svg";
import FavouriteRed from "../Assets/Icons/FavouriteRed.svg";
import FavouriteWhite from "../Assets/Icons/FavouriteWhite.svg";
import DeleteUser from "../Assets/Icons/DeleteUser.svg";
import Trash from "../Assets/Icons/Trash.svg";
import UploadFile from "../Assets/Icons/UploadFile.svg";
import FileDownload from "../Assets/Icons/FileDownload.svg";

// Wifi module
import Hotspot from "../Assets/Icons/Hotspot.svg";
import HotspotFill from "../Assets/Icons/HotspotFill.svg";
import SplashPages from "../Assets/Icons/SplashPages.svg";
import SplashPagesFill from "../Assets/Icons/SplashPagesFill.svg";

// hotspots
import Info from "../Assets/Icons/Info.svg";

//wifi
import PhoneFrame from "../Assets/Icons/PhoneFrame.svg";
import Wifi_Icon from "../Assets/Icons/Wifi_Icon.svg";
import WifiLoginPicture from "../Assets/Icons/WifiLoginPicture.svg";
import GoogleIcon from "../Assets/Icons/GoogleIcon.svg";
import FbIcon from "../Assets/Icons/FbIcon.svg";
import MaleIcon from "../Assets/Icons/MaleIcon.svg";
import FemaleIcon from "../Assets/Icons/FemaleIcon.svg";
import OtherGen from "../Assets/Icons/OtherGen.svg";
import MaleIconActive from "../Assets/Icons/MaleIconActive.svg";
import FemaleIconActive from "../Assets/Icons/FemaleIconActive.svg";
import OtherGenActive from "../Assets/Icons/OtherGenActive.svg";
import Hashtag from "../Assets/Icons/Hashtag.svg";
import MailIcon from "../Assets/Icons/MailIcon.svg";
import UserIcon from "../Assets/Icons/UserIcon.svg";
import RefreshWhite from "../Assets/Icons/RefreshWhite.svg";
import AddUserBlack from "../Assets/Icons/AddUserBlack.svg";
import TimeLineIcon from "../Assets/Icons/TimeLineIcon.svg";
import RightSideArrow from "../Assets/Icons/RightSideArrow.svg";
import MailLargeIcon from "../Assets/Icons/MailLargeIcon.svg";
import PhoneLargeIcon from "../Assets/Icons/PhoneLargeIcon.svg";
import LocationLargeIcon from "../Assets/Icons/LocationLargeIcon.svg";

// Online order
import Guard from "../Assets/Icons/Guard.svg";
import Veg from "../Assets/Icons/Veg.svg";
import Spicy from "../Assets/Icons/Spicy.svg";
import Home from "../Assets/Icons/Home.svg";
import Delivery from "../Assets/Icons/Delivery.svg";
import LocationPerm from "../Assets/Icons/LocationPerm.svg";
import Scooter from "../Assets/Icons/Scooter.svg";
import Pickup from "../Assets/Icons/Pickup.svg";
import Direction from "../Assets/Icons/Direction.svg";
import Call from "../Assets/Icons/Call.svg";
import Leaf from "../Assets/Icons/Leaf.svg";
import DotGrey from "../Assets/Icons/DotGrey.svg";
import Clockgreen from "../Assets/Icons/Clockgreen.svg";
import Clockblack from "../Assets/Icons/Clockblack.svg";
import ClockOutlined from "../Assets/Icons/ClockOutlined.svg";
import SearchRed from "../Assets/Icons/SearchRed.svg";
import forward from "../Assets/Icons/forward.svg";
import TopRated from "../Assets/images/TopRated.png";
import Offer from "../Assets/images/Offer.png";
import NonVeg from "../Assets/images/NonVeg.png";
import BestSeller from "../Assets/images/BestSeller.png";
import Dog from "../Assets/images/Dog.png";
import ShoppingBag from "../Assets/images/ShoppingBag.png";
import Filter from "../Assets/images/Filter.png";
import ReservationM from "../Assets/images/Reservation.png";
import NoImage from "../Assets/images/NoImage.avif";
import KitchenOne from "../Assets/images/kitchenOne.jpg";
import KitchenTwo from "../Assets/images/kitchenTwo.jpg";

// After Order Icons
import ShareArrow from "../Assets/Icons/ShareArrow.svg";
import ShareArrowRed from "../Assets/Icons/ShareArrowRed.svg";
import OfficeBag from "../Assets/images/OfficeBag.png";
import PaymentHand from "../Assets/images/Payment.png";
import CookingPan from "../Assets/images/CookingPan.png";
import CustomerCare from "../Assets/images/CustomerCare.png";
import DeliveryMan from "../Assets/images/DeliveryMan.png";
import RateUs from "../Assets/images/RateUs.png";
import Crown from "../Assets/Icons/Crown.svg";
import Report from "../Assets/Icons/Report.svg";
import InvoiceDownloadRed from "../Assets/Icons/InvoiceDownloadRed.svg";
import DownloadSummaryRed from "../Assets/Icons/DownloadSummaryRed.svg";
import InvoiceDownloadBlack from "../Assets/Icons/InvoiceDownloadBlack.svg";
import DownloadSummaryBlack from "../Assets/Icons/DownloadSummaryBlack.svg";
import Support from "../Assets/Icons/Support.svg";
import HomeOutline from "../Assets/Icons/HomeOutline.svg";
import WhatsappGreen from "../Assets/Icons/WhatsappGreen.svg";
import LocationBlackOutline from "../Assets/Icons/LocationBlackOutline.svg";
import ChatWithUs from "../Assets/Icons/ChatWithUs.svg";
import OutlineHome from "../Assets/Icons/OutlineHome.svg";
import CookingPot from "../Assets/gifs/CookingPot.gif";
import CardBg from "../Assets/images/CardBg.png";
import CrownImg from "../Assets/images/Crown.png";
import Item1 from "../Assets/images/Item1.png";
import Item2 from "../Assets/images/Item2.png";
import Favourite from "../Assets/images/Favourite.png";
import R1 from "../Assets/images/R1.png";
import R2 from "../Assets/images/R2.png";
import R3 from "../Assets/images/R3.png";
import R4 from "../Assets/images/R4.png";
import LocationImage from "../Assets/images/location.jpg";
import UAE from "../Assets/images/UAE.png";
import England from "../Assets/images/England.png";
import italy from "../Assets/images/italy.png";
import USA from "../Assets/images/USA.png";
import france from "../Assets/images/france.png";
import Feed from "../Assets/images/Feed.png";
import ProfileCurve from "../Assets/Icons/ProfileCurve.svg";
import DownFillArrow from "../Assets/Icons/DownFillArrow.svg";
import VegFood from "../Assets/Icons/vegfood.svg";
import FavouriteWhiteLine from "../Assets/Icons/FavouriteWhiteLine.svg";

const staticIcons = {
  MycircleLoginLogo,
  MyCircleLogo,
  AutoResponders,
  AutoRespondersFill,
  BlastCampaign,
  BlastCampaignFill,
  Client,
  ClientFill,
  Dashboard,
  DashboardFill,
  Dot,
  ReservationMblRgtArt,
  ReservationMblLftArt,
  LocationLargeIcon,
  Tools,
  ToolsFill,
  TimeLineIcon,
  Search,
  Chat,
  Notification,
  Switch,
  User,
  MailLargeIcon,
  PhoneLargeIcon,
  Settings,
  BusinessSettings,
  HelpCenter,
  Logout,
  Envelope,
  CloseRound,
  RequiredStar,
  CFCheck,
  CFCopy,
  CFCross,
  CFDown,
  CFUp,
  Comment,
  Whatsapp,
  Drop,
  MenuonlineIcon,
  DigitalSignageIcon,
  ScheduleDowncon,
  SmartWifiIcon,
  MarketingIcon,
  LoyaltyProgramIcon,
  ReputationIcon,
  Link,
  LinkActive,
  Close,
  IIcon,
  IIconBlack,
  Emoji,
  DrageIcon,
  Image,
  Attachment,
  Template,
  CustomField,
  CustomForms,
  RightSideArrow,
  CustomFormsFill,
  Groups,
  GroupsFill,
  SubClient,
  SubClientFill,
  CreditAmount,
  DebitAmount,
  PayLocations,
  Edit,
  Delete,
  More,
  Refresh,
  TimeFast,
  TimeQuarterpast,
  CardMask,
  CCVisaIcon,
  CCMasterIcon,
  CCAmericaExpIcon,
  CCDiscoverIcon,
  CCJcbIcon,
  CCOtherIcon,
  Plus,
  GreenCheck,
  CustPlanBadge,
  Gift,
  GiftWhite,
  UserBlack,
  Earnings,
  PlusBlue,
  Bulb,
  CloseRed,
  DummyLocation,
  Lock,
  Compliance,
  UnsubscribeIcon,
  SubscribeIcon,
  LeftArrow,
  Ellipse,
  TestSMS,
  SendMsg,
  QRIcon,
  List,
  timeQuarterPastWhite,
  timeFastWhite,
  notificationWhite,
  CompailanceWh,
  settingsWhite,
  Arrow,
  PendingStatus,
  MarkAsCompleted,
  Rocket,
  CheckMarkOr,
  CrossCheck,
  AngleUp,
  AngleDown,
  Scan,
  LeftRoundArrow,
  Congratulation,
  GiftLoyalty,
  GiftGrey,
  GiftLoyaltyWhite,
  DownloadWhite,
  FetchIconBlack,
  LocationYellow,
  iIconBordered,

  // No data img
  NoDataImg,

  //Error Page
  Page302,
  Page404,
  Page500,

  // side menu
  BackMenuArrow,
  WebWidgets,
  WebWidgetsFill,
  QrWidgets,
  QrWidgetsFill,
  ImageWidgets,
  ImageWidgetsFill,
  Account,
  AccountFill,
  Payment,
  PaymentFill,
  Location,
  LocationFill,
  SubUser,
  SubUserFill,
  ChangePass,
  ChangePassFill,
  SettingBlack,
  SwitchBlack,
  SwitchWhite,
  UserSwitchBlack,
  UserSwitchWhite,
  MenuBlack,
  ArrowDownWhite,
  DragIconWhite,
  EditWhite,
  DeleteWhite,
  CloseCircle,
  DeleteGrey,
  EditGrey,
  MoreGrey,

  //client icons
  CustForms,
  WIFI,
  QRGreyIcon,
  WebSignUp,
  DownloadIcon,
  UserRemove,

  // sub user login
  Num0,
  Num1,
  Num2,
  Num3,
  Num4,
  Num5,
  Num6,
  Num7,
  Num8,
  Num9,
  NumBack,
  NumCancel,
  Pin,

  // two - way chat
  RefreshIcon,
  LikeIcon,
  FavouriteRed,
  FavouriteWhite,
  DeleteUser,
  Trash,
  UploadFile,
  FileDownload,

  // Wifi module
  Hotspot,
  HotspotFill,
  SplashPages,
  SplashPagesFill,

  // hotspots
  Info,

  // wifi
  PhoneFrame,
  Wifi_Icon,
  WifiLoginPicture,
  GoogleIcon,
  FbIcon,
  MaleIcon,
  FemaleIcon,
  OtherGen,
  MaleIconActive,
  FemaleIconActive,
  OtherGenActive,
  Hashtag,
  MailIcon,
  UserIcon,
  RefreshWhite,
  AddUserBlack,

  // Online order
  Dog,
  Guard,
  Spicy,
  Veg,
  ShoppingBag,
  BestSeller,
  Home,
  TopRated,
  Offer,
  Delivery,
  NonVeg,
  Filter,
  NoImage,
  LocationPerm,
  Scooter,
  Pickup,
  ReservationM,
  Direction,
  Call,
  Leaf,
  DotGrey,
  Clockgreen,
  Clockblack,
  ClockOutlined,
  SearchRed,
  forward,
  KitchenOne,
  KitchenTwo,

  // After Order
  ShareArrow,
  OfficeBag,
  PaymentHand,
  Crown,
  Report,
  CookingPot,
  CookingPan,
  CardBg,
  CrownImg,
  HomeOutline,
  WhatsappGreen,
  LocationBlackOutline,
  CustomerCare,
  DeliveryMan,
  RateUs,
  InvoiceDownloadRed,
  DownloadSummaryRed,
  InvoiceDownloadBlack,
  DownloadSummaryBlack,
  Support,
  Item1,
  Item2,
  ChatWithUs,
  ShareArrowRed,
  ProfileCurve,
  OutlineHome,
  DownFillArrow,
  Favourite,
  R1,
  R2,
  R3,
  R4,
  Feed,
  VegFood,
  LocationImage,
  UAE,
  FavouriteWhiteLine,
  England,
  france,
  italy,
  USA,
};

export default staticIcons;
