//React Imports
import React from "react";

// Materail UI Imports
import { Slide, IconButton, Dialog, Box, Typography } from '../../shared/MuiImports'
import { TransitionProps } from "@mui/material/transitions";

// Custom styles
import fsdStyles from "./FullSizeDialog.style";

//Custom Component
import CIcon from "../CIcon/CIcon";

const TransitionUp = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} timeout={200} />;
});

const TransitionLeft = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} timeout={200} />;
});

const FullSizeDialog = (props: any) => {
  const { open, onClose, title, content, titleSx, isWhiteIcon, icon, bgcolor } = props;
  return (
    <Dialog
      fullScreen
      open={open}
      keepMounted
      TransitionComponent={props?.isLeftTransition ? TransitionLeft : TransitionUp}
      sx={fsdStyles.dialog}
    >
      {props?.title && (
        <Box sx={[fsdStyles.container, { bgcolor: bgcolor ? bgcolor : '#fff', position:'sticky', top:0, zIndex:100 }]}>
          <Box sx={fsdStyles.iconCon}>
            <Box sx={{ ...fsdStyles.titleCon, ...titleSx, }}>
              <IconButton
                onClick={() => onClose()}
                sx={[
                  isWhiteIcon && {
                    bgcolor: "rgba(0, 0, 0, 0.5) !important",
                    p: "0.3rem",
                  },
                ]}
              >
                {isWhiteIcon ? (
                  <CIcon icon={icon} color={isWhiteIcon && "white"} />
                ) : (
                  <CIcon icon={icon} color="black" />
                )}
              </IconButton>
              {title && (
                <Typography sx={{ ...fsdStyles.restTitle, ...titleSx }}>
                  {title}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {content}
    </Dialog>
  );
};

export default FullSizeDialog;
