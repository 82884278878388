//React Imports
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

//Material UI Imports
import { ArrowCircleRightIcon, AddIcon, LoadingButton, RemoveIcon, Box, Button, Collapse, Grid, IconButton, TextField, Typography } from '../../../shared/MuiImports'

//Helper Imports
import ColorsHelper from "../../../shared/ColorHelper"
import FontsHelper from "../../../shared/FontsHelper"
import staticIcons from "../../../shared/ImageHelper"

//Custom Components
import FullSizeDialog from "../../../UI/FullSizeDialog/FullSizeDialog"
import CIcon from "../../../UI/CIcon/CIcon"

//Styles Imports
import RWMStyles from "../RestaurantWithMenu/RestaurantWithMenu.style"

//Sample Images
import PizzaRectangle from '../../../Assets/images/PizzaRectangle.png'
import { RouteName } from "../../../constants/routeNameConstant"

const items = [
    {
        name: "Philly Cheese Steak Pizza",
        id: "2",
        position: 1,
        image: PizzaRectangle,
        description:
            "Tender slices of steak, fresh onions, fresh green peppers and fresh mushrooms with provolone and American cheese on a cheesy provolone crust. ",
        formatted_price: 13.99,
        currency: "USD",
        has_customization: true,
        has_addon_or_upsell_addon: false,
        has_nutritional_and_allergen_info: false,
        has_special_instruction: false,
        has_promotion: false,
        has_visible: true,
        visibility_status: 1,
        is_out_of_stock: false,
        customisation: [
            {
                value: 13.99,
                label: "Regular (Serves 2.Inch CM)",
                id: 1,
            },
            {
                value: 19.99,
                label: "Medium (Serves 4.Inch CM)",
                id: 2,
            },
            {
                value: 24.99,
                label: "Large (Serves 7.Inch CM)",
                id: 3,
            },
        ],
        toppings: [
            {
                value: 5,
                label: "Marinara Sauce Dipping",
                id: 1,
                options: [
                    {
                        label: "Light Dipping",
                        id: 1,
                    },
                    {
                        label: "Medium Dipping",
                        id: 2,
                    },
                    {
                        label: "Extra Dipping",
                        id: 3,
                    },
                ],
            },
        ],
        addOnProduct: [
            {
                value: 5,
                label: "Finger Foods",
                id: 1,
            },
            {
                value: 10,
                label: "Dips and Spreads",
                id: 2,
            },
            {
                value: 15,
                label: "Burger",
                id: 3,
            },
            {
                value: 20,
                label: "Coca cola",
                id: 4,
            },
        ],
        crustOption: [
            {
                value: 5,
                label: "Thick Metro Crust",
                id: 1,
            },
            {
                value: 5,
                label: "Choclate Syrup",
                id: 2,
            },
        ],
        hasSpecialInstructions: true,
        __typename: "categoryItemsResponse",
    },
    {
        name: "Cali Chicken Bacon Ranch Pizza",
        id: "3",
        position: 2,
        image: PizzaRectangle,
        description:
            "Grilled chicken breast, white sauce, smoked bacon, tomatoes, cheese made with mozzarella and provolone on a cheese crust. ",
        formatted_price: 13.99,
        currency: "USD",
        has_customization: false,
        has_addon_or_upsell_addon: false,
        has_nutritional_and_allergen_info: false,
        has_special_instruction: false,
        has_promotion: false,
        has_visible: true,
        visibility_status: 1,
        is_out_of_stock: false,
        __typename: "categoryItemsResponse",
    },
    {
        name: "Pacific Veggie Pizza",
        id: "5",
        position: 4,
        image: PizzaRectangle,
        description:
            "Roasted red peppers, fresh baby spinach, fresh onions, fresh mushrooms, tomatoes, black olives, cheeses made with mozzarella, feta and provolone, sprinkled with garlic herb seasoned on a cheesy Parmesan-Asiago crust. Vegetarian.",
        formatted_price: 13.99,
        currency: "USD",
        has_customization: false,
        has_addon_or_upsell_addon: false,
        has_nutritional_and_allergen_info: false,
        has_special_instruction: false,
        has_promotion: false,
        has_visible: true,
        visibility_status: 1,
        is_out_of_stock: false,
        __typename: "categoryItemsResponse",
    },
    {
        name: "Philly Cheese Steak Pizza",
        id: "2",
        position: 1,
        image: PizzaRectangle,
        description:
            "Tender slices of steak, fresh onions, fresh green peppers and fresh mushrooms with provolone and American cheese on a cheesy provolone crust. ",
        formatted_price: 13.99,
        currency: "USD",
        has_customization: true,
        has_addon_or_upsell_addon: false,
        has_nutritional_and_allergen_info: false,
        has_special_instruction: false,
        has_promotion: false,
        has_visible: true,
        visibility_status: 1,
        is_out_of_stock: false,
        customisation: [
            {
                value: 13.99,
                label: "Regular (Serves 2.Inch CM)",
                id: 1,
            },
            {
                value: 19.99,
                label: "Medium (Serves 4.Inch CM)",
                id: 2,
            },
            {
                value: 24.99,
                label: "Large (Serves 7.Inch CM)",
                id: 3,
            },
        ],
        toppings: [
            {
                value: 5,
                label: "Marinara Sauce Dipping",
                id: 1,
                options: [
                    {
                        label: "Light Dipping",
                        id: 1,
                    },
                    {
                        label: "Medium Dipping",
                        id: 2,
                    },
                    {
                        label: "Extra Dipping",
                        id: 3,
                    },
                ],
            },
        ],
        addOnProduct: [
            {
                value: 5,
                label: "Finger Foods",
                id: 1,
            },
            {
                value: 10,
                label: "Dips and Spreads",
                id: 2,
            },
            {
                value: 15,
                label: "Burger",
                id: 3,
            },
            {
                value: 20,
                label: "Coca cola",
                id: 4,
            },
        ],
        crustOption: [
            {
                value: 5,
                label: "Thick Metro Crust",
                id: 1,
            },
            {
                value: 5,
                label: "Choclate Syrup",
                id: 2,
            },
        ],
        hasSpecialInstructions: true,
        __typename: "categoryItemsResponse",
    },
    {
        name: "Cali Chicken Bacon Ranch Pizza",
        id: "3",
        position: 2,
        image: PizzaRectangle,
        description:
            "Grilled chicken breast, white sauce, smoked bacon, tomatoes, cheese made with mozzarella and provolone on a cheese crust. ",
        formatted_price: 13.99,
        currency: "USD",
        has_customization: false,
        has_addon_or_upsell_addon: false,
        has_nutritional_and_allergen_info: false,
        has_special_instruction: false,
        has_promotion: false,
        has_visible: true,
        visibility_status: 1,
        is_out_of_stock: false,
        __typename: "categoryItemsResponse",
    },
    {
        name: "Pacific Veggie Pizza",
        id: "5",
        position: 4,
        image: PizzaRectangle,
        description:
            "Roasted red peppers, fresh baby spinach, fresh onions, fresh mushrooms, tomatoes, black olives, cheeses made with mozzarella, feta and provolone, sprinkled with garlic herb seasoned on a cheesy Parmesan-Asiago crust. Vegetarian.",
        formatted_price: 13.99,
        currency: "USD",
        has_customization: false,
        has_addon_or_upsell_addon: false,
        has_nutritional_and_allergen_info: false,
        has_special_instruction: false,
        has_promotion: false,
        has_visible: true,
        visibility_status: 1,
        is_out_of_stock: false,
        __typename: "categoryItemsResponse",
    },
]

const SearchRestaurantMenu = (props: any) => {
    const { openMenuSearch, setOpenMenuSearch } = props;

    const navigate = useNavigate()

    const defaultProdDet = {
        customisation: null,
        toppings: [],
        addOnProduct: [],
        crustOption: null,
        instruction: "",
    };

    const [searchText, setSearchText] = useState<any>("")
    const [openProductView, setOpenProductView] = React.useState<{
        open: boolean;
        isDetailedView: boolean;
        data: any;
    }>({ open: false, isDetailedView: false, data: null });
    const [inView, setInView] = React.useState(true);
    const [otherProdDetails, setOtherProductDetails] = React.useState<any>(defaultProdDet);
    const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>({});
    const [selectedProduct, setSelectedProduct] = React.useState<any>({});
    const [openCustmPro, setOpenCustmPro] = React.useState<{
        open: boolean;
        data: any;
    }>({ open: false, data: null });
    const [noOfProduct, setNoOfProduct] = React.useState(0);


    const handleReadToggle = (pIndex: number, event: React.MouseEvent) => {
        event.stopPropagation();
        setExpandedItems((prevExpandedItems) => ({
            ...prevExpandedItems,
            [pIndex]: !prevExpandedItems[pIndex],
        }));
    };

    const getDescriptionSliceLength = () => {
        return window.innerWidth >= 768 ? 90 : 55;
    };
    const descriptionSliceLength = getDescriptionSliceLength();

    const applyItemCountAnimation = (type: number) => {
        const itemCount = document.getElementById("itemCount");
        let offerTxt: any = null;
        if (noOfProduct <= 3) offerTxt = document.getElementById("offerTxt");
        const animationClass = type === 1 ? "slideUp" : "slideDown";
        itemCount?.classList?.add(animationClass);
        if (noOfProduct <= 3) offerTxt?.classList?.add(animationClass);
        setTimeout(() => {
            itemCount?.classList?.remove(animationClass);
            if (noOfProduct <= 3) offerTxt?.classList?.remove(animationClass);
        }, 1000);
    };

    const handleSearch = () => {

    }
    return (
        <React.Suspense fallback={<></>}>
            <FullSizeDialog
                open={openMenuSearch}
                onClose={() => {
                    setOpenMenuSearch(false)
                }}
                icon={"uiw:down"}
                bgcolor={ColorsHelper.backColor}
                title={
                    <Box sx={{ display: "flex", justifyContent: "space-between", aligndatats: "center", px: 1, zIndex: 100 }}>
                        <TextField fullWidth size='small' placeholder='Search in Restro Beast'
                            value={searchText}
                            onChange={handleSearch}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    border: `1px solid ${ColorsHelper.grey31}`, // Add border to the root
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none', // Remove default outline
                                }
                            }}
                        />
                    </Box>
                }
                titleSx={{ width: "100%", textAlign: 'center' }}
                content={
                    <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ fontSize: '0.9rem', mx: 2, my: 1 }}>Items</Typography>
                            <Box sx={{ px: 2 }}>
                                {items?.map(
                                    (product: any, pIndex: number) => (
                                        <React.Fragment key={pIndex}>
                                            {
                                                <>
                                                    <Box key={pIndex} sx={[RWMStyles.productListBox]}
                                                        onClick={() => {
                                                            setOpenProductView({
                                                                open: true,
                                                                isDetailedView: true,
                                                                data: product,
                                                            });
                                                            setInView(true);
                                                            let otherDetails: any = otherProdDetails;
                                                            if (
                                                                product?.customisation?.length > 0
                                                            ) {
                                                                otherDetails["customisation"] = product?.customisation[0];
                                                            }
                                                            setOtherProductDetails(otherDetails);
                                                            product?.has_customization && setInView(true);
                                                        }}
                                                    >
                                                        <Grid container sx={[
                                                            RWMStyles.prodGridCon,
                                                            pIndex === 0 && { pt: "0rem" },
                                                            items?.length - 1 ===
                                                            pIndex && {
                                                                border: "none",
                                                            },
                                                        ]}
                                                        >
                                                            <Grid item sx={RWMStyles.prodDetailsBox} >
                                                                <Box sx={RWMStyles.typeCon}>
                                                                    <Box component={CIcon} icon={"material-symbols-light:square-dot-rounded"} sx={{ color: pIndex % 2 === 0 ? "green" : "red" }} />
                                                                    {pIndex % 2 === 0 ? (
                                                                        <Box>Hot spicy</Box>
                                                                    ) : (
                                                                        <Box>Best seller</Box>
                                                                    )}
                                                                </Box>
                                                                <Typography sx={RWMStyles.prodTitle}>
                                                                    {product?.name}
                                                                </Typography>

                                                                <Typography variant="body1" sx={{ ...RWMStyles.prodPrice, pb: 0, }}>
                                                                    {`$${product?.formatted_price}`}
                                                                </Typography>
                                                                <Typography variant="body1" sx={{ ...FontsHelper.font400, fontSize: "0.85rem", color: "blue", py: "0.2rem", }}>
                                                                    On Buy 2 Get 1
                                                                </Typography>
                                                                <Typography sx={RWMStyles.prodDesc} >
                                                                    {`${product?.description &&
                                                                        product?.description?.length > 0
                                                                        ? expandedItems[pIndex]
                                                                            ? product?.description
                                                                            : product?.description?.slice(0, descriptionSliceLength)
                                                                        : ""
                                                                        }`}
                                                                    {product?.description && product?.description?.length > descriptionSliceLength && (
                                                                        <>
                                                                            <span onClick={(e) => handleReadToggle(pIndex, e)}
                                                                                style={{ color: ColorsHelper.grey31, fontWeight: 700, cursor: "pointer", }} >
                                                                                {expandedItems[pIndex] ? " read less" : "...read more"}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                                <LoadingButton onClick={() => { navigate(RouteName.VIEW_IN_3D) }} variant="contained" size="small" startIcon={<Box component={CIcon} icon={"mage:box-3d-scan"} />} sx={{ fontSize: '0.6rem', textTransform: 'none', borderRadius: '8px', backgroundColor: 'black', color: 'white', mt: 1.5 }} >
                                                                    View in 3D
                                                                </LoadingButton>
                                                            </Grid>
                                                            <Grid item sx={RWMStyles.imgBox}>
                                                                <img style={{ ...RWMStyles.prodImg, objectFit: 'cover' }} src={product?.image} alt="product"
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src =
                                                                            staticIcons.NoImage;
                                                                    }}
                                                                    loading="lazy"
                                                                />
                                                                <Box sx={RWMStyles.addBtnCon}>
                                                                    {selectedProduct.hasOwnProperty(product?.id) &&
                                                                        selectedProduct[product?.id]?.qty > 0 ? (
                                                                        <Box
                                                                            sx={[
                                                                                RWMStyles.addBtnWCount,
                                                                                {
                                                                                    backgroundColor:
                                                                                        ColorsHelper.mainColor,
                                                                                    "&:hover": {
                                                                                        backgroundColor:
                                                                                            ColorsHelper.mainColor,
                                                                                    },
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <IconButton
                                                                                onClick={(e: any) => {
                                                                                    e.stopPropagation();
                                                                                    if (product?.customisation?.length > 0) {
                                                                                        setOpenCustmPro({
                                                                                            open: true,
                                                                                            data: product,
                                                                                        });
                                                                                    } else {
                                                                                        setNoOfProduct((prevValue) => prevValue - 1 < 0 ? 0 : prevValue - 1);
                                                                                        applyItemCountAnimation(2);
                                                                                        if (selectedProduct[product?.id]?.qty === 1) {
                                                                                            let newSelectedProduct = selectedProduct;
                                                                                            delete newSelectedProduct[product?.id];
                                                                                            setSelectedProduct(newSelectedProduct);
                                                                                        } else {
                                                                                            setSelectedProduct((prevValue: any) => ({
                                                                                                ...prevValue,
                                                                                                [product?.id]:
                                                                                                    { ...product, qty: prevValue[product?.id]?.qty - 1, },
                                                                                            })
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <RemoveIcon
                                                                                    style={{ fontSize: 22 }}
                                                                                />
                                                                            </IconButton>
                                                                            <span>
                                                                                {
                                                                                    selectedProduct[product?.id]?.qty
                                                                                }
                                                                            </span>
                                                                            <IconButton
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    if (product?.customisation?.length > 0) {
                                                                                        setOpenCustmPro({
                                                                                            open: true,
                                                                                            data: product,
                                                                                        });
                                                                                    } else {
                                                                                        setNoOfProduct((prevValue: any) => prevValue + 1);
                                                                                        applyItemCountAnimation(1);
                                                                                        setSelectedProduct((prevValue: any) => ({
                                                                                            ...prevValue,
                                                                                            [product?.id]: {
                                                                                                ...product,
                                                                                                qty: prevValue[product?.id]?.qty + 1,
                                                                                            },
                                                                                        })
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <AddIcon style={{ fontSize: 22 }} />
                                                                            </IconButton>
                                                                        </Box>
                                                                    ) : (
                                                                        <Button
                                                                            sx={[RWMStyles.addBtn,
                                                                            {
                                                                                border: `1px solid ${ColorsHelper.mainColor} !important`,
                                                                                color: ColorsHelper.mainColor,
                                                                                svg: {
                                                                                    color: ColorsHelper.mainColor,
                                                                                },
                                                                            },
                                                                            ]}
                                                                            onClick={(e: any) => {
                                                                                e.stopPropagation();
                                                                                if (product?.customisation?.length > 0) {
                                                                                    setOpenProductView({
                                                                                        open: true,
                                                                                        isDetailedView: false,
                                                                                        data: product,
                                                                                    });
                                                                                    setInView(false);
                                                                                    let otherDetails: any =
                                                                                        otherProdDetails;
                                                                                    if (product?.customisation?.length > 0) {
                                                                                        otherDetails["customisation"] = product?.customisation[0];
                                                                                    }
                                                                                    setOtherProductDetails(
                                                                                        otherDetails
                                                                                    );
                                                                                } else {
                                                                                    setNoOfProduct(
                                                                                        (prevValue: any) =>
                                                                                            prevValue + 1
                                                                                    );
                                                                                    applyItemCountAnimation(
                                                                                        1
                                                                                    );
                                                                                    setSelectedProduct(
                                                                                        (prevValue: any) => ({
                                                                                            ...prevValue,
                                                                                            [product?.id]: {
                                                                                                ...product,
                                                                                                qty: 1,
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            Add
                                                                            <AddIcon style={{ height: "0.9rem", }} />
                                                                        </Button>
                                                                    )}
                                                                    {product?.has_customization && (
                                                                        <Typography
                                                                            sx={RWMStyles.custmText}
                                                                        >
                                                                            {"customisable"}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                        </React.Fragment>
                                    )
                                )}
                            </Box>
                        </Box>
                        <Box sx={{
                            position: 'sticky',
                            bottom: 0,
                            bgcolor: ColorsHelper.whiteClr,
                            zIndex: 10, // Ensures it stays above other content
                        }}>
                            <Collapse in={noOfProduct > 0} timeout="auto">
                                <Button
                                    onClick={() => {
                                        // navigate('/')
                                    }}
                                    sx={[
                                        RWMStyles.selItemCon,
                                        {
                                            backgroundColor: ColorsHelper.mainColor,
                                            "&:hover": { backgroundColor: ColorsHelper.mainColor },
                                        },
                                    ]}
                                >
                                    <Box>
                                        <span id="itemCount" className="itemsTxt animateTxt">
                                            <span className="itemCountTxt">{noOfProduct}</span>
                                            {`${noOfProduct > 1 ? "items" : "item"} added`}
                                        </span>
                                        <ArrowCircleRightIcon />
                                    </Box>
                                    {/* <span id="offerTxt" className="itemsTxt offerTxtC animateTxt">
                    <span className="congTxt">
                      {noOfProduct === 1
                        ? "Add items worth $20 more to get Flat $5 OFF"
                        : noOfProduct === 2
                          ? "Add items worth $30 more to get Flat $15 OFF"
                          : noOfProduct === 3
                            ? "You can apply SPECIAL125 to get flat $25 OFF"
                            : "Congratulations! Your delivery is free."}
                    </span>
                  </span> */}
                                </Button>
                            </Collapse>
                        </Box>
                    </Box>
                }
            />
        </React.Suspense>
    )
}

export default SearchRestaurantMenu
