
export const RouteName = {
    DEFAULT_ROUTE: '/',
    SIGN_IN_ROUTE: '/signin',
    OTP_ROUTE: '/signin/otp',
    USER_DETAILS_ROUTE: '/signin/userdetail',
    LOCATION_ROUTE: '/location',
    HOME: '/home',
    SEARCH_RESTAURANT:'/searchrestaurant',
    PROFILE_VIEW:'/profile',
    PROFILE_SETTINGS:'/profilesettings',
    CATEGORIES_VIEW:'/categoriesview',
    RESTAURANT:'/restaurant',
    RESTAURANTS_VIEW:'/restaurantview',
    VIEW_IN_3D:'/3dview',
    CART:'/cart',
}