// fonts Styling
const FontsHelper = {
    fonts: "Lexend",
    TitleFontFamily: "Lexend",
    pageTitle: "1.375rem",
    pageTitleWeight: 600,
    pageSubtitle: "1.125rem",
    pageSubtitleWeight: 500,
    buttonFontSize: 20,
    fs10: 10,
    fs12: 12,
    fs14: 14,
    fs16: 16,
    fs18: 18,
    fs20: 20,
    fs22: 22,
    fs24: 24,
    fs28: 28,
    fs35: 35,
    fs0_1vw: "0.1vw",
    fs0_2vw: "0.2vw",
    fs0_3vw: "0.3vw",
    fs0_4vw: "0.4vw",
    fs0_5vw: "0.5vw",
    fs0_6vw: "0.6vw",
    fs0_7vw: "0.7vw",
    fs0_8vw: "0.8vw",
    fs0_9vw: "0.9vw",
    fs1_0vw: "1vw",
    fs1_1vw: "1.1vw",
    fs1_2vw: "1.2vw",
    fs1_3vw: "1.3vw",
    fs1_4vw: "1.4vw",
    fs1_5vw: "1.5vw",
    fs1_6vw: "1.6vw",
    fs1_7vw: "1.7vw",
    fs1_8vw: "1.8vw",
    fs1_9vw: "1.9vw",
    fs2_0vw: "2vw",
    fs2_1vw: "2.1vw",
    fs2_2vw: "2.2vw",
    fs2_3vw: "2.3vw",
    fs2_4vw: "2.4vw",
    fs2_5vw: "2.5vw",
    fs2_6vw: "2.6vw",
    fs2_7vw: "2.7vw",
    fs2_8vw: "2.8vw",
    fs2_9vw: "2.9vw",
    fs3_0vw: "3vw",
    fw100: 100,
    fw200: 200,
    fw300: 300,
    fw400: 400,
    fw500: 500,
    fw600: 600,
    fw700: 700,
    fw800: 800,
    fwLighter: 100,
    fwLight: 200,
    fwMedium: 300,
    fwNormal: 400,
    fwBold: 500,
    fwBolder: 600,
    fwDark: 700,
    fwDarker: 800,
  
    font100: {
      fontFamily: "Lexend",
      fontWeight: 100,
    },
    font200: {
      fontFamily: "Lexend",
      fontWeight: 200,
    },
    font300: {
      fontFamily: "Lexend",
      fontWeight: 300,
    },
    font400: {
      fontFamily: "Lexend",
      fontWeight: 400,
    },
    font500: {
      fontFamily: "Lexend",
      fontWeight: 500,
    },
    font600: {
      fontFamily: "Lexend",
      fontWeight: 600,
    },
    font700: {
      fontFamily: "Lexend",
      fontWeight: 700,
    },
    font800: {
      fontFamily: "Lexend",
      fontWeight: 800,
    },
    font900: {
      fontFamily: "Lexend",
      fontWeight: 900,
    },
    fontBold: {
      fontFamily: "Lexend",
      fontWeight: "bold",
    },
    fontBolder: {
      fontFamily: "Lexend",
      fontWeight: "bolder",
    },
  };
  
  export default FontsHelper; 
  