import { Box, IconButton, Typography } from '@mui/material'
import ResizableDiv from '../../../UI/ResizableDiv/ResizableDiv'
import ColorsHelper from '../../../shared/ColorHelper'
import CIcon from '../../../UI/CIcon/CIcon'
import { RouteName } from '../../../constants/routeNameConstant'
import { useNavigate } from 'react-router-dom'
import RestaurantWithMenu from '../RestaurantWithMenu/RestaurantWithMenu'
import PizzaRectangle from '../../../Assets/images/PizzaRectangle.png'
import pizzaVideo from '../../../Assets/videos/pizza-video2.mp4'

const Restaurant = () => {

  const navigate = useNavigate()

  return (
    <Box>
      <ResizableDiv
        title={
          <Box sx={{ maxWidth: '428px', width: '100dvw', display: 'flex', alignItems: 'flex-start', gap: 2, bgcolor: ColorsHelper.whiteClr, position: 'fixed', top: 0, zIndex: 1000, p: 1, boxShadow: '0px 0px 24px 0px #00000026', }}>
            <IconButton
              onClick={() => navigate(RouteName.HOME)}
              sx={{
                color: ColorsHelper.blackClr,
                fontSize: '0.9rem',
                padding: '0.3rem',
              }}>
              <Box component={CIcon} icon={"eva:arrow-back-outline"} />
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', bgcolor: ColorsHelper.whiteClr }}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.9rem', color: ColorsHelper.textColor }}>
                Restro Beast
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '0.9rem', color: ColorsHelper.textColorLight }}>
                8300 Preston Rd, Dallas, T...
              </Typography>
            </Box>
            {/* <CIcon icon={'icon-park-outline:down'} color="#000" /> */}
          </Box>
        }
        media={pizzaVideo}
        content={<RestaurantWithMenu />}
      />
    </Box>
  )
}

export default Restaurant
